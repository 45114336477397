import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  InputControl,
  SelectControl,
  SubmitControl,
  TextEditor,
} from "../../forms";
import {
  createProductGroup,
  loadCategories,
  uploadImagetoS3,
} from "../../../store/actions/product-groups";
import AddAttributeComponent from "./add-attribute";
import Background from "../../../images/bg-image.png";
import Background1 from "../../../images/imagecurved.png";
import Carousel from "nuka-carousel";
import { styles } from "./styles";
import "./productGroup.css";
import MultiSelect from "react-select";
import "../common.css";
import {
  loadCountries,
  getVendorReturnPolicy,
  loadDeliveryModesAndPartners,
} from "../../../store/actions/vendors";
import * as currencyList from "../../../currency-codes.json";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { BASE_URL } from "../../../store/api.middleware";

var customStyles = {
  borderWidth: 0,
};
var buttonStyle = {
  border: "1px solid grey",
  backgroundColor: "transparent",
  borderWidth: 2,
  borderColor: "#979797",
  outline: "none",
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 4,
  marginRight: 10,
},
  selected = {
    borderColor: "#F5900C",
  },
  multiSelect = {
    borderWidth: 0,
    width: "100%",
    marginLeft: "-22px",
  },
  hover = {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    p: { display: "none" },
    "&::hover": {
      "&+p": {
        display: "block",
      },
    },
  },
  inputStyle = {
    border: "none",
    borderBottom: "1px solid #C7D1DB",
    outline: "none",
    marginBottom: 20,
  };
class ProductGroupCreateComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddAttrClicked = this.onAddAttrClicked.bind(this);
    this.onNumberFieldChange = this.onNumberFieldChange.bind(this);
  }
  state = {
    editorState: EditorState.createEmpty(),
    returnPeriod: "",
    countries: [],
    name: "",
    expected_delivery: "",
    shipment: "",
    description: "",
    cost: "",
    length: "",
    depth: "",
    breadth: "",
    dead_weight: "",
    category: "",
    other_attributes: [],
    bg_images: [],
    images: [],
    buttonStyle: { display: "none" },
    divStyle: { display: "none" },
    deliveryMode: "",
    buttonStyles: {
      backgroundColor: "transparent",
      borderWidth: 2,
      borderColor: "#979797",
      outline: "none",
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 4,
      marginRight: 10,
    },
    isGiftCard: false,
    giftCardFields: {
      minAmount: 0,
      maxAmount: 10000,
      validForYears: 2,
      acceptIntlCards: false
    }
  };
  onNumberFieldChange(key, val) {
    if (Number(val) >= 0) {
      this.setState({ [key]: val });
    }
  }
  onDataChanged = (key, value) => {
    this.setState({ [key]: value });
  };
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };
  handleSubmit() {
    if (this.state.shipment == "digital") {
      this.setState({
        length: "",
        breadth: "",
        depth: "",
        dead_weight: "",
        deliveryMode: "others",
      });
    }
    if (!this.state.returnPeriod) {
      this.setState({ returnPeriod: this.props.returnPeriod });
    }
    if (!this.state.name || !this.state.name.trim().length) {
      alert(`Name is required`);
      return;
    }
    if (!this.state.isGiftCard && !this.state.cost) {
      alert("Please mention product cost");
      return;
    }
    if (
      (!(Number(this.state.length) >= 0) || this.state.length === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Length in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(this.state.breadth) >= 0) || this.state.breadth === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Breadth in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(this.state.depth) >= 0) || this.state.depth === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Depth in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(this.state.dead_weight) >= 0) ||
        this.state.dead_weight === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Weight in kgs. If not applicable for product, then please enter value of 0 (zero)"
      );
      return;
    }
    if (!this.state.category) {
      alert("Please select product category");
      return;
    }
    if (!this.state.countries.length) {
      alert(`Atleast one country should be selected`);
      return;
    }

    if (!this.state.shipment) {
      alert("Please select Delivery Mode");
      return;
    }
    if (this.shipment == "door_delivery" && !this.state.deliveryPartner) {
      alert("Please select Delivery Partner");
      return;
    }
    if (this.state.other_attributes.some((val) => !val)) {
      alert("Please enter data for attributes or click the tick mark");
      return;
    }
    if (
      this.state.other_attributes.length !==
      Array.from(new Set(this.state.other_attributes)).length
    ) {
      alert(`Duplicate attributes found`);
      return;
    } else {
      this.props.createProductGroup({
        ...this.state,
        return_policy:
          !this.state.isGiftCard ? JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          ) : undefined,
      });
    }
  }
  onAddAttrClicked() {
    this.setState({
      other_attributes: this.state.other_attributes.concat([""]),
    });
  }
  async componentDidMount() {
    this.props.loadCountries();
    this.props.loadDeliveryModesAndPartners();
    this.props.loadCategories();
    const {
      body: { return_policy },
    } = await this.props.getVendorReturnPolicy();
    return_policy &&
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(return_policy))
        ),
      });
  }
  handleMultiSelect = (option) => {
    option = option.map((o) => o.value.toUpperCase().replace(/ /g, "_"));
    this.setState({ countries: option });
  };

  handleMultiShipment = (option) => {
    this.setState({ shipment: option.value });
    if (option.value === "door_delivery") {
      this.setState({
        deliveryPartner: this.state.deliveryPartner || "",
        banned_check: false,
      });
    }
  };

  handleMultiPartner = (option) => {
    this.setState({ deliveryPartner: option.value });
    if (option.value === "others") {
      this.setState({
        banned_check: false,
      });
    } else {
      this.setState({
        banned_check: true,
      });
    }
  };

  attributeEdited(index, newVal) {
    const updatedAttr = this.state.other_attributes;
    updatedAttr.splice(index, 1, newVal);
    this.setState({ other_attributes: updatedAttr });
  }
  attributeRemoved(index, newVal) {
    const updatedAttr = this.state.other_attributes;
    updatedAttr.splice(index, 1);
    this.setState({ other_attributes: updatedAttr });
  }
  componentWillReceiveProps(nextProps) {
    const { deliveryPartner, returnPeriod, deliveryMode } = nextProps;
    if (
      !this.state.deliveryPartner ||
      !this.state.returnPeriod ||
      !this.state.shipment ||
      deliveryMode !== this.props.deliveryMode ||
      deliveryPartner !== this.props.deliveryPartner ||
      returnPeriod !== this.props.returnPeriod
    ) {
      this.setState({
        deliveryPartner:
          deliveryPartner !== this.props.deliveryPartner
            ? deliveryPartner
            : !!this.state.deliveryPartner
              ? this.state.deliveryPartner
              : deliveryPartner,
        returnPeriod:
          returnPeriod !== this.props.returnPeriod
            ? returnPeriod
            : !!this.state.returnPeriod
              ? this.state.returnPeriod
              : returnPeriod,
        shipment:
          deliveryMode !== this.props.deliveryMode
            ? deliveryMode
            : !!this.state.shipment
              ? this.state.shipment
              : deliveryMode,
      });
    }
  }
  onAddMoreClicked() {
    this.setState({
      divStyle: {
        width: 148,
        height: 150,
        backgroundColor: "#000000",
        display: "inline-block",
        position: "relative",
        marginTop: 20,
      },
      buttonStyles: { display: "none" },
    });
  }
  handleFile(e, setToKey) {
    if (e.target.files[0]) {
      let uploadedImages = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedImages
        .then((res) => {
          this.setState({ [setToKey]: this.state[setToKey].concat(res) });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  handleBgImage(e) {
    if (e.target.files[0]) {
      let uploadedImages = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedImages
        .then((res) => {
          this.setState({ bg_images: res });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  onImageRemoved(value) {
    this.setState({
      images: this.state.images.filter((image) => image != value),
    });
  }
  render() {
    const options = [{ label: "Please Select", value: "" }].concat(
      this.props.categories.map((category) => ({
        label: category.name,
        value: category.id,
      }))
    );
    const deliveryModes = this.props.deliveryModes.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const deliveryPartners = this.props.deliveryPartners.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];

    const deliveryOptions = [];
    for (var i = 1; i <= 20; i++) {
      deliveryOptions.push({ label: i, value: i });
    }
    const selectedDeliveryMode = deliveryModes.find(
      (option) => option.value === this.state.shipment
    );
    const selectedDeliveryPartner = deliveryPartners.find(
      (option) => option.value === this.state.deliveryPartner
    );

    const {
      name,
      description,
      cost,
      category,
      expected_delivery,
      length,
      breadth,
      depth,
      dead_weight,
      shipment,
      deliveryPartner,
      returnPeriod,
      giftCardFields
    } = this.state;
    return (
      <div style={{ width: "70%", margin: "auto" }}>
        <div style={{ display: "flex" }}>
          {/* <div style={{ width: "40%", textAlign: "center" }}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                height: 500,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#979797",
                boxShadow: "0 0 10px #979797",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 390,
                  top: 145,
                  backgroundImage: "url(" + Background1 + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                {this.state.images.length < 2 ? (
                  <div>
                    <div
                      style={{
                        width: 148,
                        height: 150,
                        backgroundColor: "#000000",
                        display: "inline-block",
                        position: "relative",
                        top: -75,
                      }}
                    >
                      {this.state.images.length ? (
                        <span>
                          <img
                            src={this.state.images[0]}
                            style={{ width: 150, height: 150 }}
                          />
                          <img
                            src={require("../../../images/close.png")}
                            onClick={(event) =>
                              this.onImageRemoved(this.state.images[0])
                            }
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              width: 25,
                              height: 25,
                              color: "white",
                              padding: 5,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            fontSize: 12,
                            width: "100%",
                            bottom: 0,
                            paddingBottom: 20,
                          }}
                        >
                          <p>Product Images</p>
                          <input
                            type="file"
                            accept="image/*"
                            id="abc"
                            onChange={(event) =>
                              this.handleFile(event, "images")
                            }
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="abc"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Click Here to Upload
                          </label>
                          <p>(Max. 380 X 310 Pixels)</p>
                        </div>
                      )}
                    </div>
                    {this.state.images.length ? (
                      <div style={{ marginTop: -35 }}>
                        <button
                          onClick={(event) => this.onAddMoreClicked()}
                          style={this.state.buttonStyles}
                        >
                          ADD MORE IMAGES
                        </button>
                        <div style={this.state.divStyle}>
                          <div
                            style={{
                              position: "absolute",
                              color: "#FFFFFF",
                              fontSize: 12,
                              width: "100%",
                              bottom: 0,
                              paddingBottom: 20,
                            }}
                          >
                            <p>Product Images</p>
                            <input
                              type="file"
                              id="abc"
                              onChange={(event) =>
                                this.handleFile(event, "images")
                              }
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor="abc"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Click Here to Upload
                            </label>
                            <p>(Max. 380 X 310 Pixels)</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                ) : (
                  <div>
                    <Carousel dragging={true} style={{ top: -30 }}>
                      {this.state.images.map((image) => (
                        <span style={{ position: "relative" }}>
                          <img
                            src={image}
                            style={{
                              width: 150,
                              height: 150,
                              display: "inline-block",
                            }}
                          />
                          <img
                            src={require("../../../images/close.png")}
                            onClick={(event) => this.onImageRemoved(image)}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: -70,
                              width: 25,
                              height: 25,
                              color: "white",
                              padding: 5,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      ))}
                    </Carousel>
                    <div style={this.state.divStyle}>
                      <div
                        style={{
                          position: "absolute",
                          color: "#FFFFFF",
                          fontSize: 12,
                          width: "100%",
                          bottom: 0,
                          paddingBottom: 20,
                          marginTop: 20,
                        }}
                      >
                        <p>Product Images</p>
                        <input
                          type="file"
                          id="abc"
                          onChange={(event) => this.handleFile(event, "images")}
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="abc"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Click Here to Upload
                        </label>
                        <p>(Max. 380 X 310 Pixels)</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <p
                style={{
                  textAlign: "left",
                  position: "absolute",
                  paddingLeft: 10,
                  bottom: 0,
                }}
              >
                <a href={`${BASE_URL}/best_practice`} target="_blank">
                  Best Practices
                </a>
              </p>
            </div>
          </div> */}
          <div style={{ width: "40%", textAlign: "center" }}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                height: 500,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#979797",
                boxShadow: "0 0 10px #979797",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 390,
                  top: 85,
                  backgroundImage: "url(" + Background1 + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                {this.state.images.length > 2 ? (
                  <div>
                    <div>
                      <Carousel dragging={true}>
                        {this.state.images.map((item, index) => (
                          <div key={index}>
                            <span style={{ position: "relative" }}>
                              <img
                                src={item}
                                style={{
                                  width: 150,
                                  height: 150,
                                  display: "inline-block",
                                }}
                              />
                              <img
                                src={require("../../../images/close.png")}
                                alt=""
                                onClick={(event) => this.onImageRemoved(item)}
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: -70,
                                  color: "white",
                                  padding: 5,
                                  width: 25,
                                  height: 25,
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div style={{ marginTop: 45 }}>
                      <button
                        onClick={(event) => this.onAddMoreClicked()}
                        style={this.state.buttonStyles}
                      >
                        ADD MORE IMAGES
                      </button>
                      <div style={this.state.divStyle}>
                        <div
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            fontSize: 12,
                            width: "100%",
                            bottom: 0,
                            paddingBottom: 20,
                          }}
                        >
                          <p>Product Image</p>
                          <input
                            type="file"
                            id="abc"
                            onChange={(event) => this.handleFile(event)}
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="abc"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Click Here to Upload
                          </label>
                          <p>(Max. 380 X 310 Pixels)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        width: 148,
                        height: 150,
                        backgroundColor: "#000000",
                        display: "inline-block",
                        position: "relative",
                      }}
                    >
                      {this.state.images.length > 0 ? (
                        <span>
                          <img
                            src={this.state.images[0]}
                            style={{ width: 150, height: 150 }}
                          />
                          <img
                            src={require("../../../images/close.png")}
                            onClick={(event) =>
                              this.onImageRemoved(this.state.images[0])
                            }
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              width: 25,
                              height: 25,
                              color: "white",
                              padding: 5,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            fontSize: 12,
                            width: "100%",
                            bottom: 0,
                            paddingBottom: 20,
                          }}
                        >
                          <p>Product Images</p>
                          <input
                            type="file"
                            accept="image/*"
                            id="abc"
                            onChange={(event) =>
                              this.handleFile(event, "images")
                            }
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="abc"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Click Here to Upload
                          </label>
                          <p>(Max. 380 X 310 Pixels)</p>
                        </div>
                      )}
                    </div>
                    {this.state.images.length > 0 ? (
                      <div style={{ marginTop: 45 }}>
                        <button
                          onClick={(event) => this.onAddMoreClicked()}
                          style={this.state.buttonStyles}
                        >
                          ADD MORE IMAGES
                        </button>
                        <div style={this.state.divStyle}>
                          <div
                            style={{
                              position: "absolute",
                              color: "#FFFFFF",
                              fontSize: 12,
                              width: "100%",
                              bottom: 0,
                              paddingBottom: 20,
                            }}
                          >
                            <p>Product Images</p>
                            <input
                              type="file"
                              id="abc"
                              onChange={(event) =>
                                this.handleFile(event, "images")
                              }
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor="abc"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Click Here to Upload
                            </label>
                            <p>(Max. 380 X 310 Pixels)</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                )}
              </div>
              <p
                style={{
                  textAlign: "left",
                  position: "absolute",
                  paddingLeft: 10,
                  bottom: 0,
                }}
              >
                <a href={`${BASE_URL}/best_practice`} target="_blank">
                  Best Practices
                </a>
              </p>
            </div>
          </div>
          <div style={{ width: "55%" }}>
            <Form onSubmit={this.handleSubmit}>
              <InputControl
                label="Product Group Name"
                required
                value={name}
                onChange={(name) => this.setState({ name })}
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <SelectControl
                label="category"
                value={category}
                options={options}
                onChange={(category) => {
                  this.setState({
                    category,
                    isGiftCard: category == '64132601c629b824e508edef',
                    shipment: category == '64132601c629b824e508edef' ? 'digital' : this.state.shipment
                  })
                }
                }
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div className="form-group">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Description
                </label>
                <div className="col-sm-offset-3">
                  <textarea
                    rows="4"
                    cols="48"
                    style={{
                      resize: "none",
                      width: "100%",
                      marginBottom: "12px",
                      outline: "0",
                      borderWidth: "0 0 0.2px",
                    }}
                    label="Description"
                    required
                    value={description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    classNames={["form-control"]}
                    innerWrapperClassNames={["col-sm-offset-3"]}
                    labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                  />
                </div>
              </div>
              {
                !this.state.isGiftCard ?
                  <div>
                    {this.state.shipment != "digital" && (
                      <div>
                        <div className="Length">
                          {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                          <InputControl
                            label="Length/Height (cms)"
                            placeholder="Length/Height in cms"
                            value={length}
                            onChange={(length) =>
                              this.onNumberFieldChange("length", length)
                            }
                            classNames={["form-control"]}
                            innerWrapperClassNames={["col-sm-offset-3"]}
                            labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                          />
                        </div>
                        <div className="Breadth">
                          {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                          <InputControl
                            label="Breadth/Width (cms)"
                            placeholder="Breadth/Width in cms"
                            value={breadth}
                            onChange={(breadth) =>
                              this.onNumberFieldChange("breadth", breadth)
                            }
                            classNames={["form-control"]}
                            innerWrapperClassNames={["col-sm-offset-3"]}
                            labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                          />
                        </div>
                        <div className="Depth">
                          {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                          <InputControl
                            label="Depth (cms)"
                            placeholder="Depth in cms"
                            value={depth}
                            onChange={(depth) =>
                              this.onNumberFieldChange("depth", depth)
                            }
                            classNames={["form-control"]}
                            innerWrapperClassNames={["col-sm-offset-3"]}
                            labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                          />
                        </div>
                        <div className="Dead Weight">
                          {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                          <InputControl
                            label="Weight (kgs)"
                            placeholder="Weight in kgs"
                            value={dead_weight}
                            onChange={(dead_weight) =>
                              this.onNumberFieldChange("dead_weight", dead_weight)
                            }
                            classNames={["form-control"]}
                            innerWrapperClassNames={["col-sm-offset-3"]}
                            labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                          />
                        </div>
                      </div>
                    )}

                    <div className="selling-price">
                      <span className="currency">
                        {currencyList[this.props.currencyISO]}
                      </span>
                      <InputControl
                        label="Selling Price"
                        required
                        value={cost}
                        onChange={(cost) => this.onNumberFieldChange("cost", cost)}
                        classNames={["form-control"]}
                        innerWrapperClassNames={["col-sm-offset-3"]}
                        labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                      />
                    </div>
                    <SelectControl
                      options={deliveryOptions}
                      label="Expected Delivery in Days"
                      required
                      value={expected_delivery}
                      onChange={(expected_delivery) =>
                        this.setState({ expected_delivery })
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <InputControl
                      label="Max days for return"
                      required={true}
                      value={this.state.returnPeriod}
                      placeholder="Max days for return"
                      onChange={(returnPeriod) =>
                        this.onNumberFieldChange("returnPeriod", returnPeriod)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <div style={{ marginLeft: "142px", marginBottom: "15px" }}>
                      <label>Return Policy</label>
                      <TextEditor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                    <div className="form-group" style={multiSelect}>
                      <label
                        className="col-sm-9 col-sm-offset-3"
                        style={{
                          textAlign: "left",
                          padding: 0,
                          fontSize: 10,
                          fontWeight: 400,
                        }}
                      >
                        Delivery Mode
                      </label>
                      <MultiSelect
                        placeholder="Please Select Delivery Mode"
                        value={selectedDeliveryMode || ""}
                        options={deliveryModes}
                        isMulti={false}
                        isSearchable={true}
                        onChange={this.handleMultiShipment}
                        className="col-sm-10 col-sm-offset-3"
                        style={customStyles}
                      />
                    </div>
                    {this.state.shipment === "door_delivery" && (
                      <div className="form-group" style={multiSelect}>
                        <label
                          className="col-sm-9 col-sm-offset-3"
                          style={{
                            textAlign: "left",
                            padding: 0,
                            fontSize: 10,
                            fontWeight: 400,
                          }}
                        >
                          Delivery Partner
                        </label>
                        <MultiSelect
                          isDisabled={this.state.shipment !== "door_delivery"}
                          placeholder="Please Select Delivery Partner"
                          value={selectedDeliveryPartner}
                          options={deliveryPartners}
                          isMulti={false}
                          isSearchable={true}
                          onChange={this.handleMultiPartner}
                          className="col-sm-10 col-sm-offset-3"
                          style={customStyles}
                        />
                      </div>
                    )}
                    {this.state.shipment === "door_delivery" &&
                      this.state.deliveryPartner == "gifteffects_delivery" && (
                        <div className="chkbox-container">
                          <label
                            style={{ width: "24%" }}
                            className="lbl-mobile"
                          ></label>
                          <label
                            style={{
                              marginLeft: 12,
                              fontSize: 12,
                              fontWeight: 350,
                              opacity: 0.5,
                            }}
                          >
                            <a
                              href="https://uat.gifteffects.com/admin/cms/banned"
                              target="__blank"
                            >
                              View GiftEffects Banned Items For Delivery
                            </a>
                          </label>
                        </div>
                      )}
                    {this.state.shipment === "door_delivery" &&
                      this.state.deliveryPartner == "gifteffects_delivery" && (
                        <div className="chkbox-container" style={{ display: "flex" }}>
                          <label
                            style={{ width: "24%" }}
                            className="lbl-mobile"
                          ></label>
                          <input
                            type="checkbox"
                            required={true}
                            name="banned_check"
                            checked={this.state.banned_check}
                            onChange={(event) =>
                              this.onDataChanged("banned_check", event.target.checked)
                            }
                            className="chkbox"
                          />
                          <label
                            style={{
                              marginLeft: 12,
                              fontSize: 12,
                              fontWeight: 350,
                              opacity: 0.5,
                            }}
                          >
                            This product group doesn't belong to any of the
                            GiftEffects's banned-items{" "}
                            <a
                              href="https://uat.gifteffects.com/admin/cms/banned"
                              target="__blank"
                            >
                              More Info
                            </a>{" "}
                          </label>
                        </div>
                      )}
                  </div>
                  :
                  <div>
                    <InputControl
                      label="Min Amount"
                      required
                      value={giftCardFields.minAmount}
                      onChange={(minAmt) => this.setState({ giftCardFields: { ...giftCardFields, minAmount: minAmt } })}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <InputControl
                      label="Max Amount"
                      required
                      value={giftCardFields.maxAmount}
                      onChange={(maxAmt) => this.setState({ giftCardFields: { ...giftCardFields, maxAmount: maxAmt } })}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <InputControl
                      label="Valid for (in years)"
                      required
                      value={giftCardFields.validForYears}
                      onChange={(years) => this.setState({ giftCardFields: { ...giftCardFields, validForYears: years } })}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <div className="chkbox-container" style={{ marginBottom: '12px' }}>
                      <label style={{ width: "24%" }} className="lbl-mobile"></label>
                      <input type="checkbox" name="acceptIntlCards" checked={giftCardFields.acceptIntlCards} onChange={event => this.setState({ giftCardFields: { ...giftCardFields, acceptIntlCards: event.target.checked } })} className="chkbox" />
                      <label style={{ marginLeft: 12, fontSize: 12, fontWeight: 350, opacity: 0.5 }}>Accept international cards</label>
                    </div>
                    <div className="col-sm-offset-3" style={{ marginBottom: '12px' }}>
                      Delivery Mode: Digital
                    </div>
                  </div>
              }

              <div className="form-group multi-dropdown" style={multiSelect}>
                <MultiSelect
                  placeholder="Please Select Deliverable Countries"
                  options={this.props.countries.map((country) => ({
                    label: country,
                    value: country.toUpperCase(),
                    isDisabled: this.state.countries.some((c) =>
                      ["WORLD_WIDE", "WORLD WIDE"].includes(c)
                    ),
                  }))}
                  isMulti={true}
                  isSearchable={true}
                  onChange={this.handleMultiSelect}
                  className="col-sm-10 col-sm-offset-3"
                  style={{ borderWidth: 0 }}
                />
              </div>
              <div style={{ display: "flex", marginBottom: 15 }}>
                <div style={{ width: "23%" }}></div>
                <div style={{ cursor: "pointer" }}>
                  <p onClick={this.onAddAttrClicked} style={buttonStyle}>
                    + ADD MORE ATTRIBUTES
                  </p>
                </div>
              </div>
              {this.state.other_attributes.length ? (
                <div className="form-group">
                  <label className="col-sm-3"></label>
                  <label className="col-sm-9" style={{ padding: 0 }}>
                    Other Attributes
                  </label>
                </div>
              ) : (
                ``
              )}
              {this.state.other_attributes.map((otherAttribute, i) => (
                <AddAttributeComponent
                  key={i}
                  attrIndex={i}
                  allAttrs={this.state.other_attributes}
                  value={otherAttribute}
                  onRemove={this.attributeRemoved.bind(this, i)}
                  onEdit={this.attributeEdited.bind(this, i)}
                  shouldShowClose={true}
                />
              ))}
              <SubmitControl
                style={{ marginLeft: 32, marginTop: 15, fontWeight: "regular" }}
                classNames={["btn", "submitStyle"]}
                innerWrapperClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  delivery,
  categories,
  countries,
  counter,
  shipmentReducer,
}) => ({
  deliveryPartners: delivery.deliveryPartner || [],
  deliveryModes: delivery.deliveryModes || [],
  categories: categories.data || [],
  deliveryMode: shipmentReducer.shipment,
  deliveryPartner: shipmentReducer.deliveryPartner,
  returnPeriod: shipmentReducer.returnPeriod,
  countries: countries.data || [],
  currencyISO: counter.currencyISO,
});
export default connect(mapStateToProps, {
  createProductGroup,
  loadCountries,
  loadDeliveryModesAndPartners,
  loadCategories,
  getVendorReturnPolicy,
  uploadImagetoS3,
})(ProductGroupCreateComponent);
