import { LOADING_PAYMENTS_PROGRESS, LOADING_PAYMENTS_SUCCESS, LOADING_PAYMENTS_FAILED, LOADING_PENDING_PAYMENTS_PROGRESS, LOADING_PENDING_PAYMENTS_SUCCESS, LOADING_PENDING_PAYMENTS_FAILED } from "../types";
import { CALL_API, BASE_URL } from "../api.middleware";

export const getVendorPayments = (startDate, endDate, page = 1) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/payments?startDate=${startDate}&endDate=${endDate}&page=${page}`,
            types:[LOADING_PAYMENTS_PROGRESS, LOADING_PAYMENTS_SUCCESS, LOADING_PAYMENTS_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getPendingPayments = (startDate, endDate, page = 1) => async dispatch => {
    await dispatch({[CALL_API]: {
        url: `/vendors/pendingPayments?startDate=${startDate}&endDate=${endDate}&page=${page}`,
        types: [LOADING_PENDING_PAYMENTS_PROGRESS, LOADING_PENDING_PAYMENTS_SUCCESS, LOADING_PENDING_PAYMENTS_FAILED],
        showLoading: true
    }})
}

export const downloadPayments = (token, vendorId, startDate, endDate, download) => async dispatch => {
    try {
        const response = await fetch(`${BASE_URL}/vendors/downloadPayments?access_token=${token}&vendorId=${vendorId}&startDate=${startDate}&endDate=${endDate}&download=${download}`)
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Payments_${new Date().getTime()}.csv`;
        document.body.appendChild(a);
        a.click();    
        a.remove();
    } catch (error) {
        alert(error.message)
    }
}