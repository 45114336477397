import React, { Component } from "react";
import { connect } from "react-redux";
import OrderListingTableRow from "./order-listing-table-row";
import { loadOrders, filterOrders, downloadOrders } from "../../../store/actions/orders";
import PaginationLinks from "../../pagination";
import 'react-dates/initialize';
import './_datepicker.css'
import { DateRangePicker } from "react-dates";
import moment from "moment";

const customStyle = {
    headerRadio: {
        marginLeft: '20px'
    },
    searchDiv: {
        borderBottom: '1px solid #dbdbdb', width: '300px',paddingBottom:5,display:'inline-block',marginLeft: '19px',marginRight: '20px'
    },
    inputStyle:{
        width:'80%',
        border:0,
        outline:'none',
        marginLeft:5
    }
}
class OrderListComponent extends Component {
    constructor(props){
        super(props)
        this.onNavigateToPage = this.onNavigateToPage.bind(this)
        this.onSearchClicked = this.onSearchClicked.bind(this)
        this.onDownloadClicked = this.onDownloadClicked.bind(this)
    }
    state = { orders: [], checkedRadio: window.location.search ? window.location.search.split('?')[1].split('=')[1] : 'ALL', windowLocation:window.location.pathname, page:1, startDate:null, endDate:null, searchBy:'' }
    componentDidMount() {
        this.props.loadOrders(this.state.checkedRadio, this.state.searchBy, this.state.page)
    }
    onCheckedOrUnchecked(key, val){
        this.setState({[key]:val})
    }
    componentDidUpdate(prevProps, prevState){
        if(this.state.checkedRadio !== prevState.checkedRadio){
            this.props.loadOrders(this.state.checkedRadio, this.state.searchBy, this.state.page)
        }
    }
    onNavigateToPage(page){
        this.props.loadOrders(this.state.checkedRadio, this.state.searchBy, page)
    }
    onSearchClicked(){
        this.props.filterOrders({
            startDate:this.state.startDate, endDate:this.state.endDate
        }, this.state.checkedRadio, this.state.searchBy,this.state.page)
    }
    onDownloadClicked(){
        this.props.downloadOrders({
            startDate:this.state.startDate, endDate:this.state.endDate
        }, this.state.checkedRadio, this.state.searchBy,this.state.page, this.props.token, this.props.vendorId)
    }
    componentWillReceiveProps(nextProps){
        const {orders} = nextProps
        this.setState({orders})
    }
    render() {
        const { orders } = this.state
        return (
            <div>
                <div style={{ width: "95%", margin: "auto" }}>
                    <div>
                        <DateRangePicker startDate={this.state.startDate}
                            startDateId="your_unique_start_date_id"
                            endDate={this.state.endDate}
                            endDateId="your_unique_end_date_id"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            showClearDates={true}
                            reopenPickerOnClearDates={true}
                            isOutsideRange={day => (moment().diff(day) <= 0)}
                            showDefaultInputIcon={true}
                            minimumNights={0}
                            small={true}
                        />
                        <div style={customStyle.searchDiv}>
                            <img src={require('../../../images/search.png')} />
                            <input type='text' style={customStyle.inputStyle} onChange={e => this.setState({searchBy:e.target.value})} placeholder='Search Here' />
                        </div>
                        <button className='btn btn-success' style={{textAlign: "center", width: "100px"}} onClick={this.onSearchClicked}>Filter</button>
                        <button className='btn btn-success' style={{textAlign: "center", width: "100px", marginLeft:'15px'}} onClick={this.onDownloadClicked}>Download</button>
                    </div>
                    <div style={{ display:'flex', justifyContent:'space-between' ,marginBottom: '40px', background: '#F8F8F8', paddingLeft: 30, paddingRight:30, paddingTop: 14, paddingBottom: 14, marginTop: 25 }}>
                        <span>Filter :  </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'ALL'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'ALL')} /> All
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'ORDER_RECEIVED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'ORDER_RECEIVED')} /> New
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'ACCEPTED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'ACCEPTED')} /> Accepted
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'READY_TO_SHIP'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'READY_TO_SHIP')} /> Ready to Ship
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'SHIPPED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'SHIPPED')} /> Shipped
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'REJECTED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'REJECTED')} /> Rejected
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'RETURNED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'RETURNED')} /> Returned
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'DELIVERED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'DELIVERED')} /> Delivered
                        </span>
                        <span>
                            <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'CANCELLED'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'CANCELLED')} /> Cancelled
                        </span>
                    </div>
                    {orders && orders.length ?
                    <div>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Product Details</th>
                                    <th>Order ID</th>
                                    <th>Order Placed</th>
                                    <th>Quantity</th>
                                    <th>Shipping Address</th>
                                    <th>Current Status</th>
                                    <th>Set to Status</th>
                                    <th>Download Label</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => <OrderListingTableRow order={order} key={index} orderStatus={order.status} courierName={order.courierName} url={order.url} courierId={order.courierId}/>)}
                            </tbody>
                        </table>
                        <PaginationLinks apiUrl={this.state.windowLocation} totalPages={this.props.totalPages} currentPage={this.props.page} onNavigateToPage={this.onNavigateToPage} />
                        </div>
                        : <p style={{textAlign:'center'}}><b>No Orders Available</b></p>}
                </div>
            </div>
        )
    }
}
export default connect(({orders, auth}) => ({
    token:auth.token, vendorId:auth.currentVendor.id,
    orders:orders.data, 
    page:orders.page, totalPages:orders.totalPages
}), {filterOrders, loadOrders, downloadOrders })(OrderListComponent)