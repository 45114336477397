import { LOADING_PRODUCT_GROUP_SUCCESS, CREATE_PRODUCT_GROUP_SUCCESS, UPDATE_PRODUCT_GROUP_SUCCESS, GET_PRODUCT_GROUP_DETAIL_SUCCESS } from "../types";

export default function productGroups(state = {data:[], page:1, totalPages:1}, action) {
    const {type, product_groups, page, totalPages} = action
    if(type == LOADING_PRODUCT_GROUP_SUCCESS){
        console.log("1--")
        return {...state, data:product_groups, page, totalPages}
    }
    if(action.type == CREATE_PRODUCT_GROUP_SUCCESS){
        console.log("2--")
        
        return {...state, data: state.data.concat([action.product_group])}
    }
    if(type == UPDATE_PRODUCT_GROUP_SUCCESS){
        console.log("3--")
        
        return { ...state, data: state.data.map(productGroup => (productGroup.id == action.id ? { ...action } : productGroup)) }
    }
    if(type == GET_PRODUCT_GROUP_DETAIL_SUCCESS){
        console.log("4--")
        
        return {...state, data:state.data.concat([action.product_group])}
    }
    return state
}
