import React, { Component } from "react";
import { Switch, Route } from "react-router";
import ModalSwitch from "../router-enhancements/modal-switch";

import VendorCreateComponent from "./register";
import LoginComponent  from "./login";
import SuccessComponent from "./success";
import RegisterSuccessComponent from "./register-success"
import CreatePasswordComponent from "./create-password";
import VerifyEmailComponent from "./verify-email";
import ForgotPasswordComponent from "./forgot-password";
import ProtectedRoute from "../protected-route";
import productGroupsRouter from "./product-groups/router";
import BankInfoComponent from "./banking-info/create"
import CreateMemberComponent from "./team-members/create";
import TeamMembersListComponent from "./team-members/list";
import ProfileComponent from "./profile";
import ForgotPasswordSuccessComponent from "./forgot-password-success";
import ComingSoonComponent from "./comingsoon";
import ProductsComponent from "./product-groups/products-home";
import ProductEditComponent from "../vendors/product-groups/edit-product";
import OrdersListComponent from "../vendors/orders/list";
import HomeComponent from "./home";
import paymentsListingComponent from "./payments/payments-listing-component";
import NotificationComponent from "./notifications/listing";
import OrderDetailComponent from "./orders/detail";
import ListProducts from "./product-groups/edit-all-products";
import SubComponent from "./product-groups/edit-single-product";
import ImageRenderer from "../image";
import ResetUserPasswordComponent from "../customers/Resetpassword";
import ActivateUserComponent from "../customers/ActivateUser";
import ResetPasswordSuccessComponent from "../customers/ResetPasswordSuccess";
import ActivateUserComponentSuccess from "../customers/ActivateUserSuccess";
export default function VendorsRouter(props) {
    const { location, history } = props;
    return <ModalSwitch location={location} history={history}>
        <Route path="/image" component={ImageRenderer} />
        <Route path="/login" component={LoginComponent} />
        <Route path="/success" component={SuccessComponent} />
        <Route path="/register-success" component={RegisterSuccessComponent} />
        <Route path="/create-password" component={CreatePasswordComponent} />
        <Route path="/verify-email" component={VerifyEmailComponent} />
        <Route path="/forgot-password" component={ForgotPasswordComponent} />
        <Route path="/forgot-password-success" component={ForgotPasswordSuccessComponent} />
        <Route path="/activate-user" component={ActivateUserComponent} />
        <Route path="/activate-user-success" component={ActivateUserComponentSuccess} />
        <Route path="/reset-user-password" component={ResetUserPasswordComponent} />
        <Route path="/reset-user-success" component={ResetPasswordSuccessComponent} />
        <ProtectedRoute path="/home" component={HomeComponent} />
        <ProtectedRoute path="/product-groups" component={productGroupsRouter} />
        <ProtectedRoute path="/bank-information" component={BankInfoComponent} />
        <ProtectedRoute path="/team-members/new" component={CreateMemberComponent} />
        <ProtectedRoute path="/team-members" component={TeamMembersListComponent} />
        <ProtectedRoute path="/profile" component={ProfileComponent} />
        <ProtectedRoute path="/payments" component={paymentsListingComponent} />
        <ProtectedRoute path="/products/:productId/update" component={ProductEditComponent} />
        <ProtectedRoute path="/products" component={ProductsComponent} />
        <ProtectedRoute path='/orders/:orderId/detail' component={OrderDetailComponent} />
        <ProtectedRoute path="/orders" component={OrdersListComponent} />
        <ProtectedRoute path="/notifications" component={NotificationComponent} />
        <ProtectedRoute path="/all-products" component={ListProducts} />
        <ProtectedRoute path="/edit-product" component={SubComponent} />
        <Route path="/register" component={VendorCreateComponent} />
        <ProtectedRoute path="/" component={HomeComponent} />
    </ModalSwitch>
}