import { CALL_API } from "../api.middleware";
import { push } from "react-router-redux";
import axios from "axios";
import {VENDOR_REGISTRATION_PROGRESS,VENDOR_REGISTRATION_SUCCESS, VENDOR_REGISTRATION_FAILED, 
    LOADING_COUNTRIES, LOADING_COUNTRIES_SUCCESS, LOADING_COUNTRIES_FAILED, VENDOR_LOGIN_PROGRESS, 
    VENDOR_LOGIN_SUCCESS, VENDOR_LOGIN_FAILED, VENDOR_LOGOUT, VENDOR_CREATE_PASSWORD_PROGRESS, 
    VENDOR_CREATE_PASSWORD_SUCCESS, VENDOR_CREATE_PASSWORD_FAILED, VENDOR_EMAIL_VERIFICATION, 
    VENDOR_FORGOT_PASSWORD_PROGRESS, VENDOR_FORGOT_PASSWORD_SUCCESS, VENDOR_FORGOT_PASSWORD_FAILED, 
    BANKING_INFO_PROGRESS, BANKING_INFO_SUCCESS, BANKING_INFO_FAILED, LOADING_PROFILE_PROGRESS, 
    LOADING_PROFILE_SUCCESS, LOADING_PROFILE_FAILED, ADD_MEMBER_PROGRESS, ADD_MEMBER_SUCCESS, 
    ADD_MEMBER_FAILED, LOADING_MEMBERS_PROGRESS, LOADING_MEMBERS_SUCCESS, LOADING_MEMBERS_FAILED, 
    UPDATE_PROFILE_PROGRESS, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILED, LOADING_STOCK_PROGRESS, 
    LOADING_STOCK_SUCCESS, LOADING_STOCK_FAILED, LOADING_HOMEPAGE_PROGRESS, LOADING_HOMEPAGE_SUCCESS, 
    LOADING_HOMEPAGE_FAILED, SWITCH_ACCOUNT_PROGRESS, SWITCH_ACCOUNT_SUCCESS, SWITCH_ACCOUNT_FAILED,
     LOADING_NOTIFICATIONS_PROGRESS, LOADING_NOTIFICATIONS_FAILED, LOADING_NOTIFICAIONS_SUCCESS, LOADING_REGISTRATION_SUCCESS, LOADING_REGISTRATION_FAILED, LOADING_REGISTRATION_PROGRESS, LOADING_EMAIL_VERIFY_SUCCESS, LOADING_EMAIL_VERIFY_PROGRESS, LOADING_EMAIL_VERIFY_FAILED, LOADING_FORGOT_PASSWORD_PROGRESS, LOADING_FORGOT_PASSWORD_SUCCESS, LOADING_FORGOT_PASSWORD_FAILED, LOADING_SHIPMENT, LOADING_SHIPMENT_SUCCESS, LOADING_SHIPMENT_FAILED,LOADING_DELIVERY_OPTIONS, LOADING_DELIVERY_OPTIONS_SUCCESS, LOADING_DELIVERY_OPTIONS_FAILED} from "../types/";

     export const register = (payload) => async dispatch => {
    try {
        if(payload.api_key == "" && payload.access_token == "" && payload.shopify_storename == ""){
            delete payload.api_key
            delete payload.access_token
            delete payload.shopify_storename
        }
        if(payload.shipment == "collect_at_store" || payload.shipment == "digital" ){
            delete payload.deliveryPartner
        }

        await dispatch({[CALL_API]: {
            url:'/vendors/register',
            types:[VENDOR_REGISTRATION_PROGRESS, VENDOR_REGISTRATION_SUCCESS, VENDOR_REGISTRATION_FAILED],
            showLoading:true,
            body:payload
        }})
        dispatch(push('/register-success'))
    } catch (error) {
        alert(error.message)
    }
}

export const vendorLogin = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url: '/vendors/login?role=VENDOR',
            types:[VENDOR_LOGIN_PROGRESS, VENDOR_LOGIN_SUCCESS, VENDOR_LOGIN_FAILED],
            showLoading:true,
            body: payload
        }})
        dispatch(push('/home'))
    } catch (error) {
        alert(error.message)
    }
}

export const vendorLogout = () => async dispatch => {
    try {
        await dispatch({type:VENDOR_LOGOUT})
        dispatch(push('/login'))
    } catch (error) {
        alert(error.message)
    }
}

export const ForgotPassword = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/forgot_password`,
            types:[VENDOR_FORGOT_PASSWORD_PROGRESS, VENDOR_FORGOT_PASSWORD_SUCCESS, VENDOR_FORGOT_PASSWORD_FAILED],
            showLoading: true,
            body:payload
        }})
        dispatch(push('/forgot-password-success'))
    } catch (error) {
        alert(error.message)
    }
}

export const vendorCreatePassword = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url: `/vendors/reset_password${payload.searchUrl}`,
            types: [VENDOR_CREATE_PASSWORD_PROGRESS, VENDOR_CREATE_PASSWORD_SUCCESS, VENDOR_CREATE_PASSWORD_FAILED],
            showLoading: true,
            showMessage:`Password reset successful`,
            body: payload
        }})
        dispatch(push('/login'))
    } catch (error) {
        alert(error.message)
    }
}

export const vendorVerifyPasswordToken = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url: `/vendors/reset_password${payload.searchUrl}`,
            types: [VENDOR_CREATE_PASSWORD_PROGRESS, VENDOR_CREATE_PASSWORD_SUCCESS, VENDOR_CREATE_PASSWORD_FAILED],
            // showLoading: true,
            // body: payload
        }})
        // dispatch(push('/login'))
    } catch (error) {
        alert(error.message)
    }
}

export const verifyEmailAddress = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/verify${payload.searchUrl}`,
            types:[``, VENDOR_EMAIL_VERIFICATION, ``]
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const loadCountries = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:'/currency-codes',
            types:[LOADING_COUNTRIES, LOADING_COUNTRIES_SUCCESS, LOADING_COUNTRIES_FAILED],
            showLoading:true,
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const loadDeliveryModesAndPartners = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:'/delivery_options',
            types:[LOADING_DELIVERY_OPTIONS, LOADING_DELIVERY_OPTIONS_SUCCESS, LOADING_DELIVERY_OPTIONS_FAILED],
            showLoading:true,
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const updateBankInfo = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/bank_details`,
            types:[BANKING_INFO_PROGRESS, BANKING_INFO_SUCCESS, BANKING_INFO_FAILED],
            showMessage:`Updated Successfully`,
            showLoading:true,
            body:payload
        }})
        dispatch(push('/bank-information'))
    } catch (error) {
        alert(error.message)
    }
}

export const getVendorProfile = () => async dispatch => {
    try {
       return await dispatch({[CALL_API]: {
            url:`/users/vendor_profile`,
            types:[LOADING_PROFILE_PROGRESS, LOADING_PROFILE_SUCCESS, LOADING_PROFILE_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const addTeamMember = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/team_members`,
            types:[ADD_MEMBER_PROGRESS, ADD_MEMBER_SUCCESS, ADD_MEMBER_FAILED],
            showLoading:true,
            body:payload
        }})
        dispatch(push('/team-members'))
    } catch (error) {
        alert(error.message)
    }
}

export const listTeamMembers = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/team_members`,
            types:[LOADING_MEMBERS_PROGRESS, LOADING_MEMBERS_SUCCESS, LOADING_MEMBERS_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const deleteMember = (member) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/remove_member`,
            types:[LOADING_MEMBERS_PROGRESS, LOADING_MEMBERS_SUCCESS, LOADING_MEMBERS_FAILED],
            showLoading:true,
            body:{member}
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const updateVendorProfile = (payload) => async dispatch => {
    try {
        if(payload.shipment == "collect_at_store" || payload.shipment == "digital" ){
            console.log("-----")
            delete payload.deliveryPartner
        }
        await dispatch({[CALL_API]: {
            url:`/vendors/profile`,
            types:[UPDATE_PROFILE_PROGRESS, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILED],
            showMessage:`Profile updated Successfully`,
            showLoading:true,
            body:payload
        }})
        dispatch(push('/profile'))
    } catch (error) {
        alert(error.message)
    }
}

export const updateRole = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/update_member`,
            types:[LOADING_MEMBERS_PROGRESS, LOADING_MEMBERS_SUCCESS, LOADING_MEMBERS_FAILED],
            showMessage:`Role updated successfully`,
            showLoading:true,
            body:payload
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const loadStock = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/minstock`,
            types:[LOADING_STOCK_PROGRESS, LOADING_STOCK_SUCCESS, LOADING_STOCK_FAILED],
            showLoading: true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getVendorHomePage = () => async dispatch => {
    try {
        await dispatch({[CALL_API]:{
            url:`/vendors/home`,
            types:[LOADING_HOMEPAGE_PROGRESS, LOADING_HOMEPAGE_SUCCESS, LOADING_HOMEPAGE_FAILED],
            showLoading:true,
            showMessage:`Loading Profile Success`
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const acceptOrRejectOrder = (orderId, updates) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/orders/${orderId}/edit?isFromHome=${true}`,
            types:[LOADING_HOMEPAGE_PROGRESS, LOADING_HOMEPAGE_SUCCESS, LOADING_HOMEPAGE_FAILED],
            showLoading:true,
            body:updates,
            showMessage:`Order Updated Successfully`
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const switchVendorAccount = (vendorId) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/switch_account?switchId=${vendorId}`,
            types:[SWITCH_ACCOUNT_PROGRESS, SWITCH_ACCOUNT_SUCCESS, SWITCH_ACCOUNT_FAILED],
            showLoading:true,
            body:{vendor:vendorId},
            showMessage:'Account Switched'
        }})
        dispatch(push('/home'))
    } catch (error) {
        alert(error.message)
    }
}

export const getNotifications = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/notifications`,
            types:[LOADING_NOTIFICATIONS_PROGRESS, LOADING_NOTIFICAIONS_SUCCESS, LOADING_NOTIFICATIONS_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getSuccessPageText = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url: `/registration_success`,
            types:[LOADING_REGISTRATION_PROGRESS, LOADING_REGISTRATION_SUCCESS, LOADING_REGISTRATION_FAILED],
            showLoading: true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const emailVerificationSuccessPage = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/verify_email_success`,
            types:[LOADING_EMAIL_VERIFY_PROGRESS, LOADING_EMAIL_VERIFY_SUCCESS, LOADING_EMAIL_VERIFY_FAILED],
            showLoading: false
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const resetPasswordSuccess = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/reset_password_success`,
            types:[LOADING_FORGOT_PASSWORD_PROGRESS, LOADING_FORGOT_PASSWORD_SUCCESS, LOADING_FORGOT_PASSWORD_FAILED],
            showLoading: false
        }})
    } catch (error) {
        alert(error.message)
    }    
}


export const getVendorReturnPolicy = () => async dispatch => {
    try {
        return await dispatch({[CALL_API]:{
            url:`/vendors/return_policy`,
            types:[LOADING_SHIPMENT,LOADING_SHIPMENT_SUCCESS,LOADING_SHIPMENT_FAILED],
            showLoading: false
        }
        })
    } catch (error) {
        alert(error.message)
    }
}