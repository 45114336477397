import React, { Component } from "react";
import { connect } from "react-redux";
import { editOrder } from "../../../store/actions/orders";
import "./orders.css";
import { formatDate } from "../../../utils";
import axios from "axios";
import { BASE_URL } from "../../../store/api.middleware";

class OrderListingTableRow extends Component {
  constructor(props) {
    super(props);
    this.onNonRejectClicked = this.onNonRejectClicked.bind(this);
    this.onRejectClicked = this.onRejectClicked.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.toggleDeliveryPopup = this.toggleDeliveryPopup.bind(this);
    this.ontrClicked = this.ontrClicked.bind(this);
  }
  state = {
    status: this.props.orderStatus,
    showPopup: false,
    reason: ``,
    showDeliveryPopup: false,
    courierName: this.props.courierName || ``,
    url: this.props.url || ``,
    courierId: this.props.courierId || ``,
  };
  toggleDeliveryPopup() {
    this.setState({ showDeliveryPopup: !this.state.showDeliveryPopup, courierName: this.props.courierName || ``,
    url: this.props.url || ``,
    courierId: this.props.courierId || `` });
  }
  togglePopup() {
    this.setState({ showPopup: !this.state.showPopup });
  }
  ontrClicked() {
    window.location = `/orders/${this.props.order.id}/detail`;
  }
  onImage = () => {
    window.open(`/image?id=${this.props.order.id}`);
  };
  onNonRejectClicked(value,product_short_id,id,quantity) {
    // if (value == `SHIPPED` && this.props.order.product.deliveryPartner != `gifteffects_delivery` && !this.state.courierId){
    //     this.setState({showDeliveryPopup: true})
    // } else {
    this.setState({ status: value });
    let updates = { status: value };
    if (this.state.courierName) {
      updates = {
        ...updates,
        courierName: this.state.courierName,
        url: this.state.url,
        courierId: this.state.courierId,
      };
    }
    console.log(updates,"----------status")
    if(updates.status === "ACCEPTED"){
        console.log(product_short_id,"product_short_id----Accept----------------")
        console.log(id,"id----Accept---------------------")
        console.log(quantity,"quantity----Accept---------------------")
        axios.put(`${BASE_URL}/products/update_shopifyproduct`, {
          product_short_ids: [product_short_id],
          _ids: [id],
          counts: [quantity]
      }, {
          headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'PUT'
          }
      })
      .then((el) => {
          console.log(el, "order reduce update-----------------------");
      })
      .catch((error) => {
          console.log(error, "err===============");
      });
    }
    this.props.editOrder(this.props.order.id, updates);
    this.setState({
      showDeliveryPopup: false,
    });
    // }
  }

  updateTracking() {
    const updates = {
        courierName: this.state.courierName,
        url: this.state.url,
        courierId: this.state.courierId,
      };
    this.props.editOrder(this.props.order.id, updates);
    this.setState({
      showDeliveryPopup: false,
    });
  }

  onRejectClicked() {

    this.setState({ status: `REJECTED`, showPopup: !this.state.showPopup });

    this.props.editOrder(this.props.order.id, {
      status: `REJECTED`,
      reject_reason: this.state.reason,
    });
  }
  get statusButtons() {
    let statusText = <span></span>;
    switch (this.props.orderStatus) {
      case `ORDER_RECEIVED`:
        statusText = { text: `Accept`, value: `ACCEPTED` };
        break;
      case `ACCEPTED`:
        statusText = { text: "Ready to Ship", value: `READY_TO_SHIP` };
        break;
      case `READY_TO_SHIP`:
        statusText = { text: `Shipped`, value: `SHIPPED` };
        break;
      case `SHIPPED`:
        statusText = { text: `Delivered`, value: "DELIVERED" };
        break;
      case `CANCELLED`:
        statusText = <p>Order Cancelled</p>;
        break;
      case `REJECTED`:
        statusText = <p>Order Rejected</p>;
        break;
      case `RETURNED`:
        statusText = <p>Product Retruned</p>;
        break;
      case `DELIVERED`:
        statusText = <p>Order Delivered</p>;
        break;
      case `REFUND_COMPLETED`:
        statusText = <p>Refund Processed</p>;
        break;
      case `REFUND_INITIATED`:
        statusText = <p>Refund Initiated</p>;
        break;
      default:
        statusText = <span></span>;
        break;
    }
    return statusText;
  }
  render() {
    const {
      product,
      quantity,
      delivery_address,
      status,
      product_cost,
    } = this.props.order;
    return (
      <tr>
        {console.log(product,"-------------------")}
        <td
          className="tdStyle"
          style={{ cursor: "pointer" }}
          onClick={(event) => this.ontrClicked()}
        >
          <div style={{ display: "flex" }}>
            <div>
              <img
                src={
                  product.images[0] ||
                  `https://appx-dev-ge.s3.ap-south-1.amazonaws.com/Gift_Effects_Logo.png`
                }
                height={88}
                width={88}
              />
            </div>
            <div style={{ marginLeft: 15, width: 250 }}>
              <p style={{ fontWeight: "bold" }}>{product.name}</p>
              <p style={{ wordWrap: "break-word" }}>
                <b>Cost:</b> {product_cost}
              </p>
              <div>
                <ul
                  style={{
                    listStyleType: "none",
                    paddingLeft: 0,
                    display: "inline-block",
                  }}
                >
                  {product.extra_attributes &&
                    Object.keys(product.extra_attributes).map((attr) => (
                      <li style={{ display: "inline-block", marginRight: 10 }}>
                        <b>{attr}:</b>
                        {product.extra_attributes[attr]}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </td>
        <td>{this.props.order.order_short_id || `#123`}</td>
        <td>{formatDate(this.props.order.createdAt)}</td>
        <td className="tdStyle">{quantity}</td>
        <td className="tdStyle">
          {delivery_address && (
            <div>
              <p>{delivery_address && delivery_address.name}</p>
              <span>{delivery_address.address}</span>,
              <span>{delivery_address.city}</span>,
              <span>{delivery_address.state}</span>,
              <span>{delivery_address.country}</span> -
              <span>{delivery_address.pincode}</span>
              <br/>
              <span><b>Contact: </b>{delivery_address.contact}</span>
            </div>
          )}
        </td>
        <td className="textTransformation">
          {status.replace(/_/g, " ").toLowerCase()}
        </td>
        <td className="tdStyle">
          {/* {console.log(this.state.status,"status--------")} */}
          {this.state.showPopup ? (
            <div className="popup">
              <div style={{ textAlign: "center" }} className="popup_inner">
                <span
                  onClick={this.togglePopup}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    paddingRight: "10px",
                  }}
                >
                  x
                </span>
                <p style={{ marginTop: "40px" }}>
                  <b>Menton reasons for reject/cancellation</b>
                </p>
                <div style={{ marginTop: "65px" }}>
                  <input
                    style={{ width: "300px" }}
                    type="text"
                    value={this.state.reason}
                    onChange={(e) => this.setState({ reason: e.target.value })}
                  />
                </div>
                <button
                  style={{ marginTop: "15px" }}
                  disabled={!this.state.reason.trim().length}
                  className="btn btn-success"
                  onClick={this.onRejectClicked}
                >
                  Reject Order
                </button>
              </div>
            </div>
          ) : this.state.showDeliveryPopup ? (
            <div className="popup">
              <div style={{ textAlign: "center" }} className="popup_inner">
                <span
                  onClick={this.toggleDeliveryPopup}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    paddingRight: "10px",
                  }}
                >
                  x
                </span>
                <p style={{ marginTop: "40px" }}>
                  <b>Enter Order Tracking Details</b>
                </p>
                <div style={{ marginTop: "30px" }}>
                  <label>Courier Name </label>
                  <br />
                  <input
                    style={{ width: "300px" }}
                    type="text"
                    value={this.state.courierName}
                    onChange={(e) =>
                      this.setState({ courierName: e.target.value })
                    }
                  />
                  <br />
                  <label>Courier ID </label>
                  <br />
                  <input
                    style={{ width: "300px" }}
                    type="text"
                    value={this.state.courierId}
                    onChange={(e) =>
                      this.setState({ courierId: e.target.value })
                    }
                  />
                  <br />
                  <label>URL </label>
                  <br />
                  <input
                    style={{ width: "300px" }}
                    type="text"
                    value={this.state.url}
                    onChange={(e) => this.setState({ url: e.target.value })}
                  />
                  <br />
                </div>
                <button
                  style={{ marginTop: "15px" }}
                  disabled={
                    !(!!this.state.courierName ||
                    !!this.state.courierId || !!this.state.url)
                  }
                  className="btn btn-success"
                  onClick={(e) => this.updateTracking()}
                >
                  Update Order
                </button>
              </div>
            </div>
          ) : null}
          {[`READY_TO_SHIP`, "SHIPPED"].includes(status) &&
            this.props.order.product.deliveryPartner !==
              `gifteffects_delivery` && (
              <input
                type="button"
                className="btn btn-success"
                name="status"
                style={{
                  textAlign: "center",
                  width: "125px",
                  marginBottom: "20px",
                }}
                onClick={this.toggleDeliveryPopup}
                value="Tracking"
              />
            )}
          {[
            `DELIVERED`,
            `REJECTED`,
            "CANCELLED",
            "REFUND_COMPLETED",
            "REFUND_INITIATED",
            "REFUND_REJECTED",
          ].includes(status) ? (
            <p style={{ textTransform: "capitalize" }}>
              Order {status.toLowerCase().replace(/_/g, " ")}
            </p>
          ) : (
            <input
              type="button"
              className="btn btn-success"
              name="status"
              style={{ textAlign: "center", width: "125px" }}
              onClick={(event) =>
                this.onNonRejectClicked(this.statusButtons.value,product.product_short_id,product._id,quantity)
              }
              value={this.statusButtons.text}
            />
          )}
          {[
            `REJECTED`,
            `DELIVERED`,
            "RETURNED",
            "REFUND_COMPLETED",
            "REFUND_INITIATED",
            "REFUND_REJECTED",
            "CANCELLED",
            `SHIPPED`,
          ].includes(status) ? (
            <span></span>
          ) : (
            <input
              className="btn btn-warning"
              type="button"
              onClick={this.togglePopup}
              style={{ marginTop: "20px", width: "125px" }}
              name="status"
              value="Reject"
            />
          )}
          {[`SHIPPED`].includes(status) ? (
            <input
              className="btn btn-warning"
              type="button"
              onClick={this.togglePopup}
              style={{ marginTop: "20px", width: "125px" }}
              name="status"
              value="Shipping failed"
            />
          ) : (
            <span></span>
          )}
        </td>
        <td>
          {([`ORDER_RECEIVED`, `READY_TO_SHIP`, `ACCEPTED`].includes(
            status
          ) && (
            <a href="javascript:void(0)" onClick={this.onImage}>
              Download
            </a>
          )) ||
            `N/A`}
        </td>
      </tr>
    );
  }
}
export default connect(null, { editOrder })(OrderListingTableRow);
