import { CALL_API } from "../api.middleware";
import { push } from "react-router-redux";
import {  } from "../types/product-groups";
import { CREATE_PRODUCT_PROGRESS, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILED, 
    LOADING_PRODUCT_PROGRESS, LOADING_PRODUCT_SUCCESS, LOADING_PRODUCT_FAILED, UPDATE_PRODUCT_PROGRESS, 
    UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED, ARCHIVE_PRODUCT_FAILED, ARCHIVE_PRODUCT_PROGRESS, 
    ARCHIVE_PRODUCT_SUCCESS, LOADING_PRODUCT_DETAIL_PROGRESS, LOADING_PRODUCT_DETAIL_SUCCESS, 
    LOADING_PRODUCT_DETAIL_FAILED, UNARCHIVE_PRODUCT_PROGRESS, UNARCHIVE_PRODUCT_SUCCESS, 
    UNARCHIVE_PRODUCT_FAILED, LOADING_ALL_PRODUCTS, LOADING_ALL_PRODUCTS_SUCCESS, LOADING_ALL_PRODUCTS_FAILED, 
    LOADING_ALL_PRODUCT_GROUPS, LOADING_ALL_PRODUCT_GROUPS_SUCCESS, LOADING_ALL_PRODUCT_GROUPS_FAILED, 
    UPDATING_ALL_PRODUCTS, UPDATING_ALL_PRODUCTS_SUCCESS, UPDATING_ALL_PRODUCTS_FAILED} from "../types/products";

import {LOADING_PRODUCT_POLICY_PROGRESS,LOADING_PRODUCT_POLICY_SUCCESS,LOADING_PRODUCT_POLICY_FAILED}
 from "../types/index"    
export const createProduct = (groupId, payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:'/products/new',
            types:[CREATE_PRODUCT_PROGRESS, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILED],
            showLoading:true,
            showMessage:`Product created Successfully`,
            body:{...payload, product_group:groupId}
        }})
        dispatch(push(`/product-groups/${groupId}/products`))
    } catch (error) {
        alert(error.message)
    }
}

export const listProducts = (groupId, query = {}, filterByQuery = {}, page) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/products/by_group?group=${groupId}&searchBy=${JSON.stringify(query)}&filterBy=${JSON.stringify(filterByQuery)}&page=${page}`,
            types:[LOADING_PRODUCT_PROGRESS, LOADING_PRODUCT_SUCCESS, LOADING_PRODUCT_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getProductDetail = (productId) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/products/${productId}/detail`,
            types:[LOADING_PRODUCT_DETAIL_PROGRESS, LOADING_PRODUCT_DETAIL_SUCCESS, LOADING_PRODUCT_DETAIL_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const editProduct = (productId, payload, isFromDetail = false) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/products/${productId}/update`,
            types:[UPDATE_PRODUCT_PROGRESS, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED],
            showLoading:true,
            showMessage:`Product updated Successfully`,
            body:payload
        }})
        if(isFromDetail){
            dispatch(push(`/products/${productId}/update`))
            return
        } else {
            dispatch(push(`/products`))
        }
    } catch (error) {
        alert(error.message)
    }
}

export const activateProduct = (productId, groupId, payload, isFromDetail = false) => async dispatch => {
    try {
        await dispatch({
            [CALL_API]: {
                url: `/products/${productId}/mark-active`,
                types: [UPDATE_PRODUCT_PROGRESS, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED],
                showLoading: true,
                showMessage: `Product updated Successfully`,
                // body: payload
            }
        })
        if (isFromDetail) {
            dispatch(push(`/products/${productId}/update`))
            return
        } else {
            if(groupId){
                dispatch(push(`/product-groups/${groupId}/products`))
            } else {
                dispatch(push(`/products`))
            }
        }
    } catch (error) {
        alert(error.message)
    }
}

export const editProductMoreDetails = (productId, payload, isFromDetail = false) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/products/${productId}/update`,
            types:[UPDATE_PRODUCT_PROGRESS, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILED],
            body:payload
        }})
        if(isFromDetail){
            dispatch(push(`/products/${productId}/update`))
            return
        } else {
            dispatch(push(`/products`))
        }
    } catch (error) {
        alert(error.message)
    }
}

export const getProductReturnPolicy = (productId) => async dispatch => {
    try {
        return await dispatch({[CALL_API]:{
            url:`/products/${productId}/return_policy`,
            types:[LOADING_PRODUCT_POLICY_PROGRESS,LOADING_PRODUCT_POLICY_SUCCESS,LOADING_PRODUCT_POLICY_FAILED],
            showLoading: false
        }
        })
    } catch (error) {
        alert(error.message)
    }
}

export const archiveProduct = (productId, payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/products/${productId}/archive`,
            types:[ARCHIVE_PRODUCT_PROGRESS, ARCHIVE_PRODUCT_SUCCESS, ARCHIVE_PRODUCT_FAILED],
            showLoading:true,
            showMessage:`Product archived Successfully`,
            body:payload
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const unArchiveProduct = (productId, payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url: `/products/${productId}/unarchive`,
            showLoading:true,
            body:payload,
            showMessage:`Product unarchived Successfully`,
            types:[UNARCHIVE_PRODUCT_PROGRESS, UNARCHIVE_PRODUCT_SUCCESS, UNARCHIVE_PRODUCT_FAILED]
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getAllProducts = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/products/get-all`,
            showLoading: true,
            types:[LOADING_ALL_PRODUCTS, LOADING_ALL_PRODUCTS_SUCCESS, LOADING_ALL_PRODUCTS_FAILED]
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getAllProductgroups = () => async dispatch => {
    try {
        await dispatch({
            [CALL_API]: {
                url: `/products/product-groups/get-all`,
                showLoading: true,
                types: [LOADING_ALL_PRODUCT_GROUPS, LOADING_ALL_PRODUCT_GROUPS_SUCCESS, LOADING_ALL_PRODUCT_GROUPS_FAILED]
            }
        })
    } catch (error) {
        alert(error.message)
    }
}

export const updateProduct = (productId, payload) => async dispatch => {
    try {
        await dispatch({
            [CALL_API]: {
                url:`/products/${productId}/edit-product-group`,
                showLoading: true,
                types:[UPDATING_ALL_PRODUCTS, UPDATING_ALL_PRODUCTS_SUCCESS, UPDATING_ALL_PRODUCTS_FAILED],
                body: payload
            }
        })
        setTimeout(() => {
            alert(`Updated successfully`)
            window.close()
        }, 500);
    } catch (error) {
        alert(error.message)
    }
}