import { CALL_API } from "../api.middleware";
import {
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_FAILED,
  RESET_USER_PASSWORD_SUCCESS,
  USER_EMAIL_VERIFICATION,
} from "../types/users";

export const activateEmail = (url) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/users/verify${url}`,
        types: [``, USER_EMAIL_VERIFICATION, ``],
        method: "GET",
        showLoader: true,
      },
    });
  } catch (error) {
    alert(error.message);
  }
};

export const resetUserPassword =
  (resetPasswordpayload, url) => async (dispatch) => {
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/users/reset_password${url}`,
          body: resetPasswordpayload,
          types: [``, RESET_USER_PASSWORD_SUCCESS, ``],
          method: "POST",
          showLoader: true,
        },
      });
    } catch (error) {
      alert(error.message);
    }
  };
