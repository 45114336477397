import ReactModal from 'react-modal';
import { Switch, Route } from "react-router";
import React, { Component } from "react";

export default class ModalSwitch extends React.Component {
    // We can pass a location to <Switch/> that will tell it to
    // ignore the router's current location and use the location
    // prop instead.
    //
    // We can also use "location state" to tell the app the user
    // wants to go to `/img/2` in a modal, rather than as the
    // main page, keeping the gallery visible behind it.
    //
    // Normally, `/img/2` wouldn't match the gallery at `/`.
    // So, to get both screens to render, we can save the old
    // location and pass it to Switch, so it will think the location
    // is still `/` even though its `/img/2`.
    previousLocation = this.props.location;
  
    componentWillUpdate(nextProps) {
      const { location } = this.props;
      // set previousLocation if props.location is not modal
      if (
        nextProps.history.action !== "POP" &&
        (!location.state || !location.state.modal)
      ) {
        this.previousLocation = this.props.location;
      }
    }
  
    render() {
      const { location, children, history } = this.props;
      const isModal = !!(
        location.state &&
        location.state.modal &&
        this.previousLocation !== location
      ); // not initial render

      const modalAttrs = { 
        isOpen : true, 
        ariaHideApp : false,
        shouldCloseOnOverlayClick : true,
        overlayClassName : 'modal fade in',
        className : 'modal-dialog modal-lg',
        style : { 
          overlay : { display : 'block', backgroundColor : 'rgba(0,0,0,0.4)' },
          content : { outline : 'none'}
        }
      }
      return (
        <div>
          <Switch location={isModal ? this.previousLocation : location}>
            {children}
          </Switch>
          {isModal ? <ReactModal {...modalAttrs}>
          <div className="modal-content">
            <div className="modal-header" style={styles.modalHeader}>
              <button type="button" className="close" aria-label="Close" style={{padding:'1rem'}} onClick={history.goBack}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <Switch location={location}>
              {children}
            </Switch> 
            </div>
          </div>
          </ReactModal>: null}
        </div>
      );
    }
  }

  const styles = {
    modalHeader : {
      padding:'1rem',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }
  