import React, { Component } from "react";
import { connect } from "react-redux";
import { listProducts } from "../../../store/actions/product";
import ProductListTableRowComponent from "./product-list-table-row";
import { loadStock } from "../../../store/actions/vendors";
import PaginationLinks from "../../pagination";
import axios from "axios";
const customStyle = {
  headerRadio: {
    marginLeft: "20px",
  },
  searchDiv: {
    borderBottom: "1px solid #979797",
    width: "300px",
    paddingBottom: 10,
  },
  searchBox: {
    border: "none",
    width: "85%",
    marginLeft: 5,
    outline: "none",
  },
  firstheaderTable: {
    fontSize: 13,
    fontWeight: 500,
    paddingLeft: 32,
    width: 400,
  },
  headerTable: {
    fontSize: 13,
    fontWeight: 500,
    width: 200,
    textAlign: "center",
  },
  txtcenter: {
    textAlign: "center",
  },
  buttonStyle: {
    backgroundColor: "transparent",
    borderWidth: 2,
    borderColor: "#979797",
    outline: "none",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    marginRight: 10,
    color: "#595D65",
  },
};
class ProductsComponent extends Component {
  constructor(props) {
    super(props);
    this.onNavigateToPage = this.onNavigateToPage.bind(this);
    this.state = {
      sortingupdate: false,
      checkedRadio: window.location.search
        ? window.location.search.split("?")[1].split("=")[1]
        : "active",
      productSearch: "",
      windowLocation: window.location.pathname,
      page: 1,
      products: [],
    };
  }

  onCheckedOrUnchecked = (key, value) => {
    this.setState({ [key]: value });
  };

  onSearched = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  get query() {
    let queryObj = { archived: false };
    switch (this.state.checkedRadio) {
      case "lowStock":
        queryObj = { stock: { $gt: 0, $lt: this.props && this.props.stock } };
        break;
      case "noStock":
        queryObj = { stock: 0 };
        break;
      case "archived":
        queryObj = { archived: true };
        break;
      case "all":
        queryObj = {};
        break;
      case "inactive":
        queryObj = { isActive: false };
        break;
      default:
        // { queryObj }
        break;
    }
    return queryObj;
  }
  componentDidMount() {
    this.props.loadStock();
    this.props.listProducts(
      null,
      { name: this.state.productSearch },
      this.query
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.checkedRadio !== this.state.checkedRadio) {
      this.props.listProducts(
        null,
        { name: this.state.productSearch },
        this.query
      );
    }
  }
  onSearchClicked = () => {
    this.props.listProducts(
      null,
      { name: this.state.productSearch },
      this.query
    );
  };

  componentWillReceiveProps(nextProps) {
    const { products } = nextProps;

    this.setState({ products: products });
  }
  onNavigateToPage(page) {
    this.props.listProducts(
      null,
      { name: this.state.productSearch },
      this.query,
      page
    );
  }

  filterProductsBySelection(product) {
    let applyFilter = true;
    switch (this.state.checkedRadio) {
      case "active":
        applyFilter = product.archived ? false : true;
        break;
      case "lowStock":
        applyFilter = product.stock < 5 || false;
        break;
      case "noStock":
        applyFilter = product.stock === 0 || false;
        break;
      case "archived":
        applyFilter = product.archived || false;
        break;
      case "all":
        applyFilter = true;
        break;
      default:
        break;
    }
    return applyFilter;
  }
  render() {
    const { products, sortingupdate } = this.state;
    return (
      <div>
        <div>
          {/* <div style={{position:"absolute",top:94,left:0,textAlign:"center",backgroundColor:"#B6B6B6",width:"100%"}}>
                        <p style={{paddingTop:6,paddingBottom:6,margin:0,color:"#FFFFFF",fontWeight:400}}>Three things need your attention!!!</p> */}
        </div>
        <div style={{ width: "70%", margin: "auto" }}>
          <div>
            <div style={{ display: "flex" }}>
              <div style={customStyle.searchDiv}>
                <img src={require("../../../images/search.png")} />
                <input
                  style={customStyle.searchBox}
                  value={this.state.productSearch}
                  type="text"
                  name="productSearch"
                  onChange={(event) => this.onSearched("productSearch", event)}
                  placeholder="Search Products Here"
                />
              </div>
              <input
                className="btn btn-success"
                type="button"
                style={{ marginLeft: "25px" }}
                value="Search"
                onClick={this.onSearchClicked}
              />
            </div>

            <div
              style={{
                marginBottom: "40px",
                background: "#F8F8F8",
                paddingLeft: 35,
                paddingTop: 14,
                paddingBottom: 14,
                marginTop: 25,
              }}
            >
              <span>Filter : </span>
              <input
                style={customStyle.headerRadio}
                type="checkbox"
                checked={this.state.checkedRadio === "all"}
                name="all"
                onChange={(event) =>
                  this.onCheckedOrUnchecked("checkedRadio", "all")
                }
              />{" "}
              All
              <input
                style={customStyle.headerRadio}
                type="checkbox"
                checked={this.state.checkedRadio === "active"}
                name="all"
                onChange={(event) =>
                  this.onCheckedOrUnchecked("checkedRadio", "active")
                }
              />{" "}
              Active Products
              <input
                style={customStyle.headerRadio}
                type="checkbox"
                checked={this.state.checkedRadio === "lowStock"}
                name="all"
                onChange={(event) =>
                  this.onCheckedOrUnchecked("checkedRadio", "lowStock")
                }
              />{" "}
              Low in Stock
              <input
                style={customStyle.headerRadio}
                type="checkbox"
                checked={this.state.checkedRadio === "noStock"}
                name="all"
                onChange={(event) =>
                  this.onCheckedOrUnchecked("checkedRadio", "noStock")
                }
              />{" "}
              Out of Stock
              <input
                style={customStyle.headerRadio}
                type="checkbox"
                checked={this.state.checkedRadio === "archived"}
                name="all"
                onChange={(event) =>
                  this.onCheckedOrUnchecked("checkedRadio", "archived")
                }
              />{" "}
              Archived Products
              {this.props.accountRole !== `VENDOR_DATA_ENTRY` ? (
                <span>
                  <input
                    style={customStyle.headerRadio}
                    type="checkbox"
                    checked={this.state.checkedRadio === "inactive"}
                    name="all"
                    onChange={(event) =>
                      this.onCheckedOrUnchecked("checkedRadio", "inactive")
                    }
                  />
                  <span> Inactive Products</span>
                </span>
              ) : (
                ``
              )}
            </div>
            {products &&
            products.length &&
            products.filter((product) =>
              this.filterProductsBySelection(product)
            ).length ? (
              <div>
                <table className="table table-striped">
                  <thead style={{ background: "#F0F0F0" }}>
                    <tr>
                      <th style={customStyle.firstheaderTable}>
                        Product Details
                        <button
                          onClick={() => {
                            this.setState((prevState) => ({
                              sortingupdate: !prevState.sortingupdate,
                            }));
                            this.componentDidMount();
                          }}
                          style={{ border: "none" }}
                        >
                          <i className="fas fa-sort" />
                        </button>
                      </th>
                      <th style={customStyle.headerTable}>Product ID</th>
                      <th style={customStyle.headerTable}>Units in Stock</th>
                      <th style={customStyle.headerTable}>Selling Price</th>
                      <th style={customStyle.headerTable}></th>
                    </tr>
                  </thead>
                  {sortingupdate === true ? (
                    <tbody>
                      {products
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .filter((product) =>
                          this.filterProductsBySelection(product)
                        )
                        .map((product) => (
                          <ProductListTableRowComponent
                            accountRole={this.props.accountRole}
                            product={product}
                          />
                        ))}
                    </tbody>
                  ) : (
                    <tbody>
                      {products
                        .sort((a, b) => (a.name < b.name ? 1 : -1))
                        .filter((product) =>
                          this.filterProductsBySelection(product)
                        )
                        .map((product,index) => (
                          <ProductListTableRowComponent
                            accountRole={this.props.accountRole}
                            product={product}
                            key = {index}
                          />
                        ))}
                    </tbody>
                  )}
                  {/* TODO : Replace Magnifier image with no Products available Image */}
                </table>
                <PaginationLinks
                  currentPage={this.props.page}
                  totalPages={this.props.totalPages}
                  apiUrl={window.location.pathname}
                  onNavigateToPage={this.onNavigateToPage}
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <p style={{ fontWeight: "bold" }}>No Products Available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ products, stock, auth }) => ({
    products: products.data,
    accountRole: auth.account_role,
    page: products.page,
    totalPages: products.totalPages,
    stock: stock.data.min_stock,
  }),
  { listProducts, loadStock }
)(ProductsComponent);
