import { CALL_API } from "../api.middleware";
import { push } from "react-router-redux";
import { LOADING_CATEGORIES_PROGRESS, LOADING_CATEGORIES_SUCCESS, LOADING_CATEGORIES_FAILED, CREATE_PRODUCT_GROUP_PROGRESS, CREATE_PRODUCT_GROUP_SUCCESS, CREATE_PRODUCT_GROUP_FAILED, LOADING_PRODUCT_GROUP_PROGRESS, LOADING_PRODUCT_GROUP_SUCCESS, LOADING_PRODUCT_GROUP_FAILED, UPDATE_PRODUCT_GROUP_PROGRESS, UPDATE_PRODUCT_GROUP_SUCCESS, UPDATE_PRODUCT_GROUP_FAILED, GET_PRODUCT_GROUP_DETAIL_PROGRESS, GET_PRODUCT_GROUP_DETAIL_SUCCESS, GET_PRODUCT_GROUP_DETAIL_FAILED, LOADING_PRODUCT_GROUP_POLICY_SUCCESS, LOADING_PRODUCT_GROUP_POLICY_PROGRESS, LOADING_PRODUCT_GROUP_POLICY_FAILED } from "../types/product-groups";
import { UPLOADING_IMAGE_PROGRESS, UPLOADING_IMAGE_SUCCESS, UPLOADING_IMAGE_FAILED, LOADING_PRODUCT_POLICY_PROGRESS, LOADING_PRODUCT_POLICY_SUCCESS, LOADING_PRODUCT_POLICY_FAILED } from "../types";

export const createProductGroup = (payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:'/product_groups/new',
            types:[CREATE_PRODUCT_GROUP_PROGRESS, CREATE_PRODUCT_GROUP_SUCCESS, CREATE_PRODUCT_GROUP_FAILED],
            showLoading:true,
            showMessage:`Product group created Successfully`,
            body:payload
        }})
        dispatch(push('/product-groups'))
    } catch (error) {
        alert(error.message)
    }
}

export const listProductGroups = (page) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/product_groups?page=${page}`,
            types:[LOADING_PRODUCT_GROUP_PROGRESS, LOADING_PRODUCT_GROUP_SUCCESS, LOADING_PRODUCT_GROUP_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const SearchProductGroups = (page = 1,search) => async dispatch => {
    try {
       const searchName = search.name
        await dispatch({[CALL_API]: {
            url:`/product_groups/serachprogrp?page=${page}&searchBy=${searchName}`,
            types:[LOADING_PRODUCT_GROUP_PROGRESS, LOADING_PRODUCT_GROUP_SUCCESS, LOADING_PRODUCT_GROUP_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getProductGroupDetail = (groupId) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/product_groups/${groupId}/detail`,
            types:[GET_PRODUCT_GROUP_DETAIL_PROGRESS, GET_PRODUCT_GROUP_DETAIL_SUCCESS, GET_PRODUCT_GROUP_DETAIL_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const updateProductGroup = (groupId, payload) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/product_groups/${groupId}/update`,
            types:[UPDATE_PRODUCT_GROUP_PROGRESS, UPDATE_PRODUCT_GROUP_SUCCESS, UPDATE_PRODUCT_GROUP_FAILED],
            showLoading:true,
            showMessage:`Product group updated Successfully`,
            body:payload
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const loadProductGroupReturnPolicy = (groupId) => async dispatch => {
    try {
        return await dispatch({[CALL_API]:{
            url:`/product_groups/${groupId}/return_policy`,
            types:[LOADING_PRODUCT_GROUP_POLICY_PROGRESS,LOADING_PRODUCT_GROUP_POLICY_SUCCESS,LOADING_PRODUCT_GROUP_POLICY_FAILED],
            showLoading:true,
        }})
    } catch (error) {
        alert(error.message)
    }
} 


export const loadCategories = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/categories`,
            types:[LOADING_CATEGORIES_PROGRESS, LOADING_CATEGORIES_SUCCESS, LOADING_CATEGORIES_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getSignedUrl = (payload) => async dispatch => {
    try {
        return await dispatch({[CALL_API]: {
            url:`/sign_s3`,
            showLoading:true,
            showMessage:`Image Uploaded successfully`,
            types:[UPLOADING_IMAGE_PROGRESS, UPLOADING_IMAGE_SUCCESS, UPLOADING_IMAGE_FAILED],
            body:payload
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const uploadImagetoS3 =(fileName, fileType, file) => async dispatch => {
    try {
        const resp = await dispatch(getSignedUrl({ file_name:fileName, file_type:fileType }));
        const {signed_request, url} = resp.body
        return await fetch(signed_request, {
            method:'PUT', body:file
        }).then(res => {
            console.log(res)
            return url
        })
    } catch (error) {
        alert(error.message)
    }
}