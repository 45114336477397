import React, { Component } from "react";
import imgUrl from '../../images/dropdown.png'

export function SelectControl({
    label,
    name,
    options = [],
    value = "",
    classNames=["form-control"], 
    labelClassNames=["control-label","col-sm-3"],
    outerWrapperClassNames=["form-group"],
    innerWrapperClassNames=["col-sm-6"],
    onChange=undefined,
    ...otherProps
}) {
    const changeHandler = event => onChange.call(null, event.target.value);
    const selectAttrs = Object.assign({ name, value, className : classNames.join(" ")}, onChange ? {value, onChange : changeHandler} : { defaultValue : value })
    const labelAttrs = { className : labelClassNames.join(" "), htmlFor: name }
    return <div className={outerWrapperClassNames.join(" ")}>
        <label {...labelAttrs} style={{textAlign:"left",padding:0,fontSize:10,fontWeight:400}}>{label}</label>
        <div className={innerWrapperClassNames.join(" ")} style={{borderBottom:"1px solid #C7D1DB",padding:0}}>
            <select {...selectAttrs} {...otherProps}style={{border:"none",fontWeight:350,color:"#595D65",boxShadow:"none",borderRadius:0,padding:7,marginLeft:-7,backgroundColor:"transparent",WebkitAppearance: "none",backgroundImage: "url(" + imgUrl + ")",backgroundRepeat: 'no-repeat',backgroundPosition:"100% 50%"}}>
                {options.map(option => <option key={option.value || option.label} value={option.value}>{option.label || option.value}</option>)}
            </select>
        </div>
    </div>
}