import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrderDetail, editOrder } from '../../../store/actions/orders';
import "./orders.css";
import { formatDate } from '../../../utils';

class OrderDetailComponent extends Component {
    constructor(props) {
        super(props)
        this.onNonRejectClicked = this.onNonRejectClicked.bind(this)
        this.onRejectClicked = this.onRejectClicked.bind(this)
        this.togglePopup = this.togglePopup.bind(this)
    }
    componentDidMount() {
        this.props.getOrderDetail(window.location.pathname.split('/')[2])
    }
    state = { status: ``, showPopup: false, reason: `` }
    togglePopup() {
        this.setState({ showPopup: !this.state.showPopup });
    }
    onNonRejectClicked(value) {
        this.setState({ status: value })
        this.props.editOrder(this.props.orderDetail.id, { status: value }, true)
    }
    onRejectClicked() {
        this.setState({ status: `REJECTED`, showPopup: !this.state.showPopup })
        this.props.editOrder(this.props.orderDetail.id, { status: `REJECTED`, reject_reason: this.state.reason })
    }
    onImage = () => {
        window.open(`/image?id=${this.props.orderDetail.id}`)
    }
    get statusButtons() {
        let statusText = <span></span>
        switch (this.props.orderDetail && this.props.orderDetail.status) {
            case `ORDER_RECEIVED`:
                statusText = { text: `Accept`, value: `ACCEPTED` }
                break;
            case `ACCEPTED`:
                statusText = { text: `Ready to Ship`, value: `READY_TO_SHIP` }
                break;
            case `READY_TO_SHIP`:
                statusText = { text: `Shipped`, value: `SHIPPED` }
                break;
            case `SHIPPED`:
                statusText = { text: `Delivered`, value: 'DELIVERED' }
                break;
            case `CANCELLED`:
                statusText = <p>Order Cancelled</p>
                break;
            case `REJECTED`:
                statusText = <p>{`Order Rejected` + this.props.orderDetail.reject_reason}</p>
                break;
            case `RETURNED`:
                statusText = <p>Product Retruned</p>
                break;
            case `DELIVERED`:
                statusText = <p>Order Delivered</p>
                break;
            case `REFUND_COMPLETED`:
                statusText = <p>Refund Processed</p>
                break;
            case `REFUND_INITIATED`:
                statusText = <p>Refund Initiated</p>
                break;
            case `REFUND_REJECTED`:
                statusText = <p>Refund Rejected</p>
                break;
            default:
                statusText = <span></span>
                break;
        }
        return statusText
    }
    render() {
        const { orderDetail } = this.props
        return (
            <div style={{ width: '70%', margin: 'auto' }}>
                {orderDetail && Object.keys(orderDetail).length ?
                    <div>
                        <div>
                            <div className='orderDetail'>
                                <img src={orderDetail.product.images[0] || `https://appx-dev-ge.s3.ap-south-1.amazonaws.com/Gift_Effects_Logo.png`} height={100} width={100} />
                                <div className='productDetail'>
                                    <p><b>Name: </b>{orderDetail.product.name}</p>
                                    <p><b>Cost: </b>{orderDetail.product_cost}</p>
                                    <p><b>Quantity: </b>{orderDetail.quantity}</p>
                                </div>
                            </div>
                            <div style={{marginTop:20, marginBottom:20}}>
                                <p style={{marginBottom:10}}><b>Payment Details: </b></p>
                                <p className="details">Payment Mode: {orderDetail.payment.payment_mode.replace("_", " ")}{["card","credit_card"].includes(orderDetail.payment.payment_mode) && ` ending with ` + orderDetail.payment.card_id}</p>
                                {['RAZORPAY', 'PAYPAL'].includes(orderDetail.payment.payment_type) &&
                                    <div>
                                        {`RAZORPAY` === orderDetail.payment.payment_type &&
                                            <span>
                                                <p className="details">Payment to: {orderDetail.payment.payment_type || `Not required`}</p>
                                                <p className="details">Payment ID: {orderDetail.payment.razorpay_paymentid || `Not required`}</p>
                                                <p className="details">Amount: {orderDetail.payment.amount}</p>
                                            </span> ||
                                            `PAYPAL` === orderDetail.payment.payment_type &&
                                            <span>
                                                <p className="details">Payment to: {orderDetail.payment.payment_type || `Not required`}</p>
                                                {/* <p className="details">Payment ID: {orderDetail.payment.razorpay_paymentid || `Not required`}</p> */}
                                                <p className="details">Amount: {orderDetail.payment.amount}</p>
                                            </span>
                                        }
                                    </div>
                                }
                                <p className="details">Paid From Wallet: {orderDetail.payment.wallet_used ? `Yes` : `No`}</p>
                                <p className="details">Amount paid from Wallet: {orderDetail.payment.wallet_amount_used || `0`}</p>
                            </div>
                            <div>
                                <p style={{marginBottom:10}}><b>Delivery Address: </b></p>
                                {
                                    this.props.orderDetail.delivery_address &&
                                    <div>
                                        <p className='details'>{this.props.orderDetail.delivery_address && this.props.orderDetail.delivery_address.name}</p>
                                        <div className='details'>
                                            <span>{this.props.orderDetail.delivery_address.address}</span>, 
                                            <span>{this.props.orderDetail.delivery_address.city}</span>, 
                                            <span>{this.props.orderDetail.delivery_address.state}</span>, 
                                            <span>{this.props.orderDetail.delivery_address.country}</span> -
                                            <span>{this.props.orderDetail.delivery_address.pincode}</span>
                                            <br/>
                                            <span><b>Contact: </b> {this.props.orderDetail.delivery_address.contact}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{marginTop:'20px',marginBottom:'20px'}}>
                                {[`DELIVERED`, `REJECTED`, 'CANCELLED', 'REFUND_COMPLETED', 'REFUND_REJECTED', 'REFUND_INITIATED'].includes(orderDetail.status) ?
                                <p style={{ textTransform: 'capitalize' }}>Order {orderDetail.status.toLowerCase().replace(/_/g, ' ')} {orderDetail.status === `REJECTED` ? `(${orderDetail.reject_reason})` : ``}</p> :
                                <input type='button' className='btn btn-success' name='status' style={{ textAlign: 'center', width: '125px' }} onClick={event => this.onNonRejectClicked(this.statusButtons.value)} value={this.statusButtons.text} />
                            }
                                {[`REJECTED`, `DELIVERED`, 'RETURNED', 'CANCELLED', `SHIPPED`, 'REFUND_COMPLETED', 'REFUND_REJECTED', 'REFUND_INITIATED'].includes(orderDetail.status) ?
                                <span></span> :
                                <input className='btn btn-warning' type='button' onClick={this.togglePopup} style={{ marginLeft:'30px',width: '125px' }} name='status' value='Reject' />
                            }
                            {[`SHIPPED`].includes(orderDetail.status) ?
                                <input className='btn btn-warning' type='button' onClick={this.togglePopup} style={{ marginLeft: '30px', width: '125px' }} name='status' value='Shipping failed' /> :
                                <span></span>
                            }
                            {
                                [`ORDER_RECEIVED`, `READY_TO_SHIP`, `ACCEPTED`].includes(orderDetail.status) &&
                                <input className='btn btn-success' type='button' onClick={this.onImage} style={{ marginLeft: '30px', width: '200px' }} name='status' value='Download Shipping Label' />
                            }
                            </div>
                        </div>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Modified At</th>
                                    <th>Modified By</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>{orderDetail.transitions.map(transition =>
                                (transition.changed_by && <tr>
                                    <td>{formatDate(transition.createdAt)}</td>
                                    <td>{transition.changed_by.name || transition.changed_by.role}</td>
                                    <td style={{ textTransform: 'capitalize' }}>{(transition.previous_status ? transition.previous_status.replace(/_/g, ' ').toLowerCase() + ` - ` : ``).concat(transition.current_status.replace(/_/g, ' ').toLowerCase())}</td>
                                </tr>)
                            )
                            }
                            </tbody>
                        </table>

                    </div> :
                    <p style={{ textAlign: 'center' }}>Fetching..</p>
                }
                <div>
                    {this.state.showPopup ?
                        <div className='popup'>
                            <div style={{ textAlign: 'center' }} className='popup_inner'>
                                <span onClick={this.togglePopup} style={{ cursor: 'pointer', float: 'right', paddingRight: '10px' }}>x</span>
                                <p style={{ marginTop: '40px' }}><b>Menton reasons for reject</b></p>
                                <div style={{ marginTop: '65px' }}>
                                    <input style={{ width: '300px' }} type='text' value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })} />
                                </div>
                                <button style={{ marginTop: '15px' }} disabled={!this.state.reason.trim().length} className='btn btn-success' onClick={this.onRejectClicked}>Reject Order</button>
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ orders }) => ({ orderDetail: orders.detail || {} })
export default connect(mapStateToProps, { getOrderDetail, editOrder })(OrderDetailComponent)