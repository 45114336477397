export const VENDOR_REGISTRATION_PROGRESS = "VENDOR_REGISTRATION_PROGRESS";
export const VENDOR_REGISTRATION_SUCCESS = "VENDOR_REGISTRATION_SUCCESS";
export const VENDOR_REGISTRATION_FAILED = "VENDOR_REGISTRATION_FAILED";

export const VENDOR_LOGIN_PROGRESS = "VENDOR_LOGIN_PROGRESS";
export const VENDOR_LOGIN_SUCCESS = "VENDOR_LOGIN_SUCCESS";
export const VENDOR_LOGIN_FAILED = "VENDOR_LOGIN_FAILED";
export const VENDOR_LOGOUT = "VENDOR_LOGOUT";

export const VENDOR_CREATE_PASSWORD_PROGRESS = "VENDOR_CREATE_PASSWORD_PROGRESS";
export const VENDOR_CREATE_PASSWORD_SUCCESS = "VENDOR_CREATE_PASSWORD_SUCCESS";
export const VENDOR_CREATE_PASSWORD_FAILED = "VENDOR_CREATE_PASSWORD_FAILED";

export const VENDOR_EMAIL_VERIFICATION = "VENDOR_EMAIL_VERIFICATION";

export const VENDOR_FORGOT_PASSWORD_PROGRESS = "VENDOR_FORGOT_PASSWORD_PROGRESS";
export const VENDOR_FORGOT_PASSWORD_SUCCESS = "VENDOR_FORGOT_PASSWORD_SUCCESS";
export const VENDOR_FORGOT_PASSWORD_FAILED = "VENDOR_FORGOT_PASSWORD_FAILED";

export const BANKING_INFO_PROGRESS = "BANKING_INFO_PROGRESS";
export const BANKING_INFO_SUCCESS = "BANKING_INFO_SUCCESS";
export const BANKING_INFO_FAILED = "BANKING_INFO_FAILED";

export const LOADING_PROFILE_PROGRESS = "LOADING_PROFILE_PROGRESS";
export const LOADING_PROFILE_SUCCESS = "LOADING_PROFILE_SUCCESS";
export const LOADING_PROFILE_FAILED = "LOADING_PROFILE_FAILED";

export const UPDATE_PROFILE_PROGRESS = "UPDATE_PROFILE_PROGRESS";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

export const ADD_MEMBER_PROGRESS = "ADD_MEMBER_PROGRESS";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS"
export const ADD_MEMBER_FAILED = "ADD_MEMBER_FAILED"

export const LOADING_MEMBERS_PROGRESS = "LOADING_MEMBERS_PROGRESS";
export const LOADING_MEMBERS_SUCCESS = "LOADING_MEMBERS_SUCCESS";
export const LOADING_MEMBERS_FAILED = "LOADING_MEMBERS_FAILED";

export const LOADING_HOMEPAGE_PROGRESS = "LOADING_HOMEPAGE_PROGRESS";
export const LOADING_HOMEPAGE_SUCCESS = "LOADING_HOMEPAGE_SUCCESS";
export const LOADING_HOMEPAGE_FAILED = "LOADING_HOMEPAGE_FAILED";

export const UPDATE_ORDER_FROM_HOMEPAGE_PROGRESS = "UPDATE_ORDER_FROM_HOMEPAGE_PROGRESS";
export const UPDATE_ORDER_FROM_HOMEPAGE_SUCCESS = "UPDATE_ORDER_FROM_HOMEPAGE_SUCCESS";
export const UPDATE_ORDER_FROM_HOMEPAGE_FAILED = "UPDATE_ORDER_FROM_HOMEPAGE_FAILED";

export const SWITCH_ACCOUNT_PROGRESS  = 'SWITCH_ACCOUNT_PROGRESS';
export const SWITCH_ACCOUNT_SUCCESS = 'SWITCH_ACCOUNT_SUCCESS';
export const SWITCH_ACCOUNT_FAILED = 'SWITCH_ACCOUNT_FAILED';