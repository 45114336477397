import React, { Component } from "react";
import { connect } from "react-redux";
import { SubmitControl, Form } from "../forms";
import { activateEmail } from "../../store/actions/users";
var divStyle = {
    width: "30%",
    border: "1px solid #A2A2A2",
    borderRadius: 8,
    margin: "auto",
  },
  headingStyle = {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 40,
    // marginBottom: 40,
    textAlign: "center",
  };

class ActivateUserComponent extends Component {
  state = {
    searchUrl: window.location.search,
  };
  onSubmitted = () => {
    this.activate(this.state.searchUrl);
  };

  activate = async (url) => {
    const response = await this.props.activateEmail(url);
    if (response) {
      window.location = `/activate-user-success`;
    }
  };

  render() {
    return (
      <div style={divStyle}>
        <p style={headingStyle}>Confirm Account To Login</p>
        <Form onSubmit={this.onSubmitted}>
          <SubmitControl
            title="Confirm"
            style={{
              backgroundColor: "#595D65",
              backgroundImage: "none",
              width: "86%",
              marginBottom: 32,
              marginTop: 40,
            }}
          />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = ({ users }) => users;

export default connect(mapStateToProps, {
  activateEmail,
})(ActivateUserComponent);