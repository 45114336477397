export const LOADING_CATEGORIES_PROGRESS = "LOADING_CATEGORIES_PROGRESS";
export const LOADING_CATEGORIES_SUCCESS = "LOADING_CATEGORIES_SUCCESS";
export const LOADING_CATEGORIES_FAILED = "LOADING_CATEGORIES_FAILED";

export const CREATE_PRODUCT_GROUP_PROGRESS = "CREATE_PRODUCT_GROUP_PROGRESS";
export const CREATE_PRODUCT_GROUP_SUCCESS = "CREATE_PRODUCT_GROUP_SUCCESS";
export const CREATE_PRODUCT_GROUP_FAILED = "CREATE_PRODUCT_GROUP_FAILED";

export const LOADING_PRODUCT_GROUP_PROGRESS = "LOADING_PRODUCT_GROUP_PROGRESS";
export const LOADING_PRODUCT_GROUP_SUCCESS = "LOADING_PRODUCT_GROUP_SUCCESS";
export const LOADING_PRODUCT_GROUP_FAILED = "LOADING_PRODUCT_GROUP_FAILED";

export const UPDATE_PRODUCT_GROUP_PROGRESS = "UPDATE_PRODUCT_GROUP_PROGRESS";
export const UPDATE_PRODUCT_GROUP_SUCCESS = "UPDATE_PRODUCT_GROUP_SUCCESS";
export const UPDATE_PRODUCT_GROUP_FAILED = "UPDATE_PRODUCT_GROUP_FAILED";

export const GET_PRODUCT_GROUP_DETAIL_PROGRESS = "GET_PRODUCT_GROUP_DETAIL_PROGRESS";
export const GET_PRODUCT_GROUP_DETAIL_SUCCESS = "GET_PRODUCT_GROUP_DETAIL_SUCCESS";
export const GET_PRODUCT_GROUP_DETAIL_FAILED = "GET_PRODUCT_GROUP_DETAIL_FAILED";

export const LOADING_PRODUCT_GROUP_POLICY_PROGRESS = "LOADING_PRODUCT_GROUP_POLICY_PROGRESS";
export const LOADING_PRODUCT_GROUP_POLICY_SUCCESS = "LOADING_PRODUCT_GROUP_POLICY_SUCCESS";
export const LOADING_PRODUCT_GROUP_POLICY_FAILED = "LOADING_PRODUCT_GROUP_POLICY_FAILED";