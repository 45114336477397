import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  InputControl,
  SubmitControl,
  SelectControl,
  TextEditor,
} from "../../forms";
import {
  getProductDetail,
  editProduct,
  editProductMoreDetails,
  getProductReturnPolicy,
} from "../../../store/actions/product";
import Background from "../../../images/bg-image.png";
import Background1 from "../../../images/imagecurved.png";
import Carousel from "nuka-carousel";
import { uploadImagetoS3 } from "../../../store/actions/product-groups";
import currencyList from "../../../currency-codes.json";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { loadDeliveryModesAndPartners } from "../../../store/actions/vendors";
import MultiSelect from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import ActivityLog from "./activity-log";

var customStyles = {
    borderWidth: 0,
  },
  multiSelect = {
    borderWidth: 0,
    width: "100%",
  };

var hover = {
  position: "relative",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  p: { display: "none" },
  "&::hover": {
    "&+p": {
      display: "block",
    },
  },
};
const style = {
  pill: {
    listStylePosition: "inside",
    border: "1px solid gainsboro",
    display: "inline-block",
    background: "#F5F5F7",
    minWidth: "127px",
    padding: "2px 0px 2px 8px",
    borderRadius: "20px",
    margin: "5px 2px",
  },
  ulStyle: {
    marginLeft: "-310px",
    paddingLeft: "0px",
    marginBottom: "0px",
    marginTop: "20px",
  },

  closeButtonStyle: {
    float: "right",
    cursor: "pointer",
    padding: "0px 8px",
    borderLeftStyle: "solid",
    borderLeftWidth: "1px",
    borderLeftColor: "gainsboro",
    marginLeft: "8px",
  },
};
class ProductEditComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onOtherAttrEdit = this.onOtherAttrEdit.bind(this);
    this.onNumberFieldChange = this.onNumberFieldChange.bind(this);
    this.state = {
      ...this.props.product,
      extra_attributes: this.props.extra_attributes,
      editorState: EditorState.createEmpty(),
      buttonStyle: { display: "none" },
      bgImage: [],
      buttonStyles: {
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: "#979797",
        outline: "none",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 4,
        marginRight: 10,
      },
      divStyle: {
        width: 148,
        height: 150,
        backgroundColor: "#000000",
        display: "inline-block",
        position: "relative",
      },
    };
    this.handleFile = this.handleFile.bind(this);
  }
  handleSubmit() {
    let length =
      this.state.length ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.length);
    let breadth =
      this.state.breadth ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.breadth);
    let depth =
      this.state.depth ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.depth);
    let dead_weight =
      this.state.dead_weight ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.dead_weight);

    const shipment =
      this.state.shipment ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.shipment);
    const deliveryPartner =
      this.state.deliveryPartner ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.deliveryPartner);

    // if(![...this.state.images, ...this.state.toBeUploaded].length){
    //     if(window.confirm('If images not uploaded product group images will be copied')){
    //         console.log('yes')
    //     } else {
    //         return
    //     }
    // }

    if (this.state.shipment == "digital") {
      length = "";
      breadth = "";
      depth = "";
      dead_weight = "";
    }
    if (
      (!(Number(length) >= 0) || length === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Length in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(breadth) >= 0) || breadth === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Breadth in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(depth) >= 0) || depth === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Depth in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(dead_weight) >= 0) || dead_weight === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Weight in kgs. If not applicable for product, then please enter value of 0 (zero)"
      );
      return;
    }
    if (!shipment) {
      alert("Please select Delivery Mode");
      return;
    }
    if (!deliveryPartner) {
      alert("Please select Delivery Partner");
      return;
    }
    const extra_attributes =
      this.state.extra_attributes &&
      Object.keys(this.state.extra_attributes).reduce((prev, curr) => {
        Object.assign(prev, {
          [curr]: this.state.extra_attributes[curr].trim(),
        });
        return prev;
      }, {});
    const product_group = this.state.product_group.id;
    const bg_images = this.state.bgImage.length
      ? this.state.bgImage
      : this.state.bg_images;
    const payload = {
      ...this.state,
      length,
      breadth,
      depth,
      dead_weight,
      shipment,
      deliveryPartner,
      extra_attributes,
      product_group,
      bg_images,
      return_policy: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
    };
    this.props.editProduct(this.state.id, { ...payload }, true);
  }

  onOtherAttrEdit(key, val) {
    let x = { ...this.state.extra_attributes, [key]: val };
    this.setState({ extra_attributes: x });
  }
  onNumberFieldChange(key, val) {
    if (Number(val) >= 0) {
      this.setState({ [key]: val });
    }
  }
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };
  async componentDidMount() {
    this.props.loadDeliveryModesAndPartners();
    this.props.getProductDetail(this.props.productId);
    let {
      body: { return_policy },
    } = await this.props.getProductReturnPolicy(this.props.productId);
    return_policy &&
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(return_policy))
        ),
      });
  }
  componentWillReceiveProps(nextProps) {
    const {
      product: { extra_attributes, images, ...details },
    } = nextProps;
    if (JSON.stringify(details) !== JSON.stringify(this.props.product)) {
      Object.assign(details, {
        length: (details.length || 0).toString(),
        breadth: (details.breadth || 0).toString(),
        dead_weight: (details.dead_weight || 0).toString(),
        depth: (details.depth || 0).toString(),
      });
      this.setState({
        ...details,
        extra_attributes,
        images:
          images.length > (this.state.images || []).length
            ? images
            : this.state.images,
      });
    }
  }
  onImageRemoved(value) {
    this.setState({
      images: this.state.images.filter((image) => image != value),
    });
  }
  onDataChanged = (key, value) => {
    this.setState({ [key]: value });
  };
  handleBgImage(e) {
    if (e.target.files[0]) {
      let uploadedFiles = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedFiles
        .then((res) => {
          this.setState({ bgImage: res });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  addMoreDetails(e) {
    if (e.target.files[0]) {
      let uploadedImages = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      uploadedImages
        .then((res) => {
          this.props.editProductMoreDetails(
            this.state.id,
            { more_details: res[0] },
            true
          );
          this.setState({ more_details: res[0] });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  removeMoreDetails() {
    this.props.editProductMoreDetails(
      this.state.id,
      { more_details: "" },
      true
    );
    this.setState({ more_details: "" });
  }
  handleMultiShipment = (option) => {
    this.setState({ shipment: option.value });
    if (option.value === "door_delivery") {
      this.setState({
        deliveryPartner: this.state.deliveryPartner || "",
        banned_check: false,
      });
    }
  };

  handleMultiPartner = (option) => {
    this.setState({ deliveryPartner: option.value });
    if (option.value === "others") {
      this.setState({
        banned_check: false,
      });
    } else {
      this.setState({
        banned_check: true,
      });
    }
  };

  handleFile(e) {
    if (e.target.files[0]) {
      let uploadedFiles = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedFiles
        .then((res) => {
          this.setState({ images: [...this.state.images, ...res] });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  render() {
    const deliveryOptions = [];
    for (var i = 1; i <= 20; i++) {
      deliveryOptions.push({ label: i, value: i });
    }

    console.log(this.state.images, "img");

    const deliveryModes = this.props.deliveryModes.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const deliveryPartner = this.props.deliveryPartner.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const selectedDeliveryMode = deliveryModes.find(
      (option) =>
        option.value ===
        (this.state.shipment ||
          (this.props.product &&
            this.props.product.product_group &&
            this.props.product.product_group.shipment))
    );
    const selectedDeliveryPartner = deliveryPartner.find(
      (option) =>
        option.value ===
        (this.state.deliveryPartner ||
          (this.props.product &&
            this.props.product.product_group &&
            this.props.product.product_group.deliveryPartner))
    );
    let {
      name,
      description,
      cost,
      length,
      breadth,
      depth,
      dead_weight,
      stock,
      extra_attributes = {},
      expected_delivery,
      returnPeriod,
      images = [],
      bg_images = [],
      bgImage,
    } = this.state;
    // bg_images = bgImage.length ? bgImage : bg_images
    const _length =
      length ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.length);
    const _breadth =
      breadth ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.breadth);
    const _depth =
      depth ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.depth);
    const _deadWeight =
      dead_weight ||
      (this.props.product &&
        this.props.product.product_group &&
        this.props.product.product_group.dead_weight);
    return (
      <div style={{ width: "70%", margin: "auto" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", textAlign: "center" }}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                height: 500,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#979797",
                boxShadow: "0 0 10px #979797",
                position: "relative",
              }}
            >
              {/* <div style={{position:"absolute",width:"100%",height:215,backgroundImage: "url(" + (bg_images[0] || Background) + ")", backgroundRepeat: "no-repeat", backgroundSize: "100%", overflow: "hidden",paddingTop: 50}}>
                            <div style={bg_images.length ? hover :{display:'none'}} className='hover'>
                            <input type="file" id="bg-imgs" style={{display:'none'}} onChange={event => this.handleBgImage(event)} />
                                <label htmlFor="bg-imgs" style={{cursor:"pointer",display:"none"}}>Click here to change the image</label>
                            </div>
                            <p style={bg_images.length ? {display:'none'} : {display:'block'}}>Background Image</p>
                            <input type="file" id="bg-img" style={{display:'none'}} onChange={event => this.handleBgImage(event)} />
                            <label htmlFor='bg-img' style={bg_images.length ? {display:'none'} : { textDecoration: "underline", cursor: "pointer" }}>Click Here to Upload</label>
                                <p style={bg_images.length ? {display:'none'} : {display:'block'}}>(740 X 430 Pixels)</p>
                            </div> */}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 390,
                  top: 145,
                  backgroundImage: "url(" + Background1 + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                {[...images].length < 2 ? (
                  <div>
                    <div
                      style={{
                        width: 148,
                        height: 150,
                        backgroundColor: "#000000",
                        display: "inline-block",
                        position: "relative",
                        top: -50,
                      }}
                    >
                      {[...images].length ? (
                        <span>
                          <img
                            src={images[0]}
                            style={{ width: 150, height: 150 }}
                          />
                          <img
                            src={require("../../../images/close.png")}
                            onClick={(event) => this.onImageRemoved(images[0])}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              padding: 5,
                              width: 25,
                              height: 25,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            fontSize: 12,
                            width: "100%",
                            bottom: 0,
                            paddingBottom: 20,
                          }}
                        >
                          <p>Product Image</p>
                          <input
                            type="file"
                            id="abc"
                            onChange={(event) =>
                              this.handleFile(event, "images")
                            }
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="abc"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Click Here to Upload
                          </label>
                          <p>(Max. 380 X 310 Pixels)</p>
                        </div>
                      )}
                    </div>
                    {[...images].length ? (
                      <div style={{ marginTop: 20 }}>
                        <div style={this.state.divStyle}>
                          <div
                            style={{
                              position: "absolute",
                              color: "#FFFFFF",
                              fontSize: 12,
                              width: "100%",
                              bottom: 0,
                              paddingBottom: 20,
                            }}
                          >
                            <p>Product Image</p>
                            <input
                              type="file"
                              id="abc"
                              onChange={(event) =>
                                this.handleFile(event, "images")
                              }
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor="abc"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              Click Here to Upload
                            </label>
                            <p>(Max. 380 X 310 Pixels)</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                ) : (
                  <div>
                    <Carousel dragging={true} style={{ top: -50, height: "150px" }}>
                      {[...images].map((image) => (
                        <span style={{ position: "relative" }}>
                          <img
                            src={image}
                            style={{
                              width: 150,
                              height: 150,
                              display: "inline-block",
                            }}
                          />
                          <img
                            src={require("../../../images/close.png")}
                            onClick={(event) => this.onImageRemoved(image)}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: -70,
                              color: "white",
                              padding: 5,
                              cursor: "pointer",
                              width: 25,
                              height: 25,
                            }}
                          />
                        </span>
                      ))}
                    </Carousel>
                    <div style={this.state.divStyle}>
                      <div
                        style={{
                          position: "absolute",
                          color: "#FFFFFF",
                          fontSize: 12,
                          width: "100%",
                          bottom: 0,
                          paddingBottom: 20,
                          marginTop: 30,
                        }}
                      >
                        <p>Product Image</p>
                        <input
                          type="file"
                          id="abc"
                          onChange={(event) => this.handleFile(event, "images")}
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="abc"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Click Here to Upload
                        </label>
                        <p>(Max. 380 X 310 Pixels)</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ width: "55%" }}>
            <Form onSubmit={this.handleSubmit}>
              <p style={{ textAlign: "center", paddingLeft: "55px" }}>
                Product Group of{" "}
                <b>
                  {this.props.product &&
                    this.props.product.product_group &&
                    this.props.product.product_group.name}
                </b>
              </p>
              <InputControl
                label="Product Name"
                required
                name=""
                value={name}
                onChange={(name) => this.setState({ name })}
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div className="form-group">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Description
                </label>
                <div className="col-sm-offset-3">
                  <textarea
                    rows="4"
                    cols="48"
                    style={{
                      resize: "none",
                      width: "100%",
                      marginBottom: "12px",
                      outline: "0",
                      borderWidth: "0 0 0.2px",
                    }}
                    label="Description"
                    required
                    value={description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    classNames={["form-control"]}
                    innerWrapperClassNames={["col-sm-offset-3"]}
                    labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                  />
                </div>
              </div>
              {this.state.shipment != "digital" && (
                <div>
                  <div className="Length">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Length/Height (cms)"
                      placeholder="Length/Height in cms"
                      value={_length}
                      onChange={(_length) =>
                        this.onNumberFieldChange("length", _length)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Breadth">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Breadth/Width (cms)"
                      placeholder="Breadth/Width in cms"
                      value={_breadth}
                      onChange={(_breadth) =>
                        this.onNumberFieldChange("breadth", _breadth)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Depth">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Depth (cms)"
                      placeholder="Depth in cms"
                      value={_depth}
                      onChange={(_depth) =>
                        this.onNumberFieldChange("depth", _depth)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Dead Weight">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Weight (kgs)"
                      placeholder="Weight in kgs"
                      value={_deadWeight}
                      onChange={(_deadWeight) =>
                        this.onNumberFieldChange("dead_weight", _deadWeight)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                </div>
              )}

              <InputControl
                label="Stock"
                disabled={this.props.accountRole == `VENDOR_DATA_ENTRY`}
                required
                value={stock}
                onChange={(stock) =>
                  Number(stock) >= 0 && this.setState({ stock })
                }
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div className="selling-price">
                <span className="currency">
                  {currencyList[this.props.currencyISO]}
                </span>
                <InputControl
                  disabled={this.props.accountRole == `VENDOR_DATA_ENTRY`}
                  label="Selling Price"
                  required
                  value={cost}
                  onChange={(cost) =>
                    Number(cost) >= 0 && this.setState({ cost })
                  }
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                />
              </div>
              <SelectControl
                label="Expected Delivery in Days"
                required
                value={expected_delivery}
                options={deliveryOptions}
                onChange={(expected_delivery) =>
                  this.setState({ expected_delivery })
                }
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <InputControl
                label="Max days for return"
                required={true}
                value={returnPeriod}
                placeholder="Max days for return"
                onChange={(returnPeriod) =>
                  this.onNumberFieldChange("returnPeriod", returnPeriod)
                }
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div style={{ marginLeft: "118px", marginBottom: 13 }}>
                <label>Return Policy</label>
                <TextEditor
                  editorState={this.state.editorState}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
              <div className="date-picker">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Due Date
                </label>
                <div className="picker-container col-sm-offset-3">
                  <SingleDatePicker
                    date={
                      (this.state.available_date &&
                        moment(this.state.available_date)) ||
                      null
                    }
                    onDateChange={(available_date) =>
                      this.setState({ available_date })
                    }
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    id="1"
                    showDefaultInputIcon={true}
                    focused={this.state.focused}
                    showClearDate={true}
                    small={true}
                    numberOfMonths={1}
                    placeholder="Please select available until date (If any)"
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: "0px",
                    fontSize: "10px",
                    fontWeight: 400,
                    marginTop: "15px",
                  }}
                >
                  Add More Details
                </label>
                {this.state.more_details ? (
                  <div style={{ display: "flex" }}>
                    <ul style={style.ulStyle}>
                      {
                        <li key={0} style={style.pill}>
                          <a
                            style={{ textDecoration: "none" }}
                            href={this.state.more_details}
                            target="_blank"
                          >
                            View Existing
                          </a>
                          <span
                            title="remove"
                            onClick={(e) => this.removeMoreDetails()}
                            style={style.closeButtonStyle}
                          >
                            &times;
                          </span>
                        </li>
                      }
                    </ul>
                  </div>
                ) : (
                  <span></span>
                )}
                <div className="col-sm-offset-3">
                  <input
                    accept=".xls, .xlsx, .png, .svg, .jpg, .jpeg, .doc, .docx, .pdf"
                    type="file"
                    id="bg-img1"
                    style={{ display: "none" }}
                    onChange={(event) => this.addMoreDetails(event)}
                  />
                  <label
                    className="col-sm-offset-3 col-sm-9"
                    htmlFor="bg-img1"
                    style={{
                      textAlign: "left",
                      padding: "0px",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginTop: "0px",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Click Here to Upload More Details
                  </label>
                </div>
              </div>
              {Object.keys(extra_attributes).length ? (
                <div className="form-group">
                  <label className="col-sm-3"></label>
                  <label className="col-sm-9" style={{ padding: 0 }}>
                    Other Attributes
                  </label>
                </div>
              ) : (
                ``
              )}
              {Object.keys(extra_attributes).map((otherAttribute, i) => (
                <InputControl
                  label={otherAttribute}
                  key={i}
                  required
                  value={extra_attributes[otherAttribute]}
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                  onChange={(event) =>
                    this.onOtherAttrEdit(otherAttribute, event)
                  }
                />
              ))}
              <div className="form-group" style={multiSelect}>
                <label
                  className="col-sm-9 col-sm-offset-3"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Delivery Mode
                </label>
                <MultiSelect
                  placeholder="Please Select Delivery Mode"
                  value={selectedDeliveryMode}
                  options={deliveryModes}
                  isMulti={false}
                  isSearchable={true}
                  onChange={this.handleMultiShipment}
                  className="col-sm-10 col-sm-offset-3"
                  style={customStyles}
                />
              </div>
              {this.state.shipment === "door_delivery" && (
                <div className="form-group" style={multiSelect}>
                  <label
                    className="col-sm-9 col-sm-offset-3"
                    style={{
                      textAlign: "left",
                      padding: 0,
                      fontSize: 10,
                      fontWeight: 400,
                    }}
                  >
                    Delivery Partner
                  </label>
                  <MultiSelect
                    isDisabled={this.state.shipment != "door_delivery"}
                    placeholder="Please Select Delivery Partner"
                    value={selectedDeliveryPartner}
                    options={deliveryPartner}
                    isMulti={false}
                    isSearchable={true}
                    onChange={this.handleMultiPartner}
                    className="col-sm-10 col-sm-offset-3"
                    style={customStyles}
                  />
                </div>
              )}
              {this.state.shipment === "door_delivery" &&
                this.state.deliveryPartner == "gifteffects_delivery" && (
                  <div className="chkbox-container">
                    <label
                      style={{ width: "24%" }}
                      className="lbl-mobile"
                    ></label>
                    <label
                      style={{
                        marginLeft: 12,
                        fontSize: 12,
                        fontWeight: 350,
                        opacity: 0.5,
                      }}
                    >
                      <a href="https://uat.gifteffects.com/admin/cms/banned" target="__blank">
                        View GiftEffects Banned Items For Delivery
                      </a>
                    </label>
                  </div>
                )}
              {this.state.shipment === "door_delivery" &&
                this.state.deliveryPartner == "gifteffects_delivery" && (
                  <div className="chkbox-container" style={{ display: "flex" }}>
                    <label
                      style={{ width: "24%" }}
                      className="lbl-mobile"
                    ></label>
                    <input
                      type="checkbox"
                      name="banned_check"
                      checked={this.state.banned_check}
                      onChange={(event) =>
                        this.onDataChanged("banned_check", event.target.checked)
                      }
                      className="chkbox"
                    />
                    <label
                      style={{
                        marginLeft: 12,
                        fontSize: 12,
                        fontWeight: 350,
                        opacity: 0.5,
                      }}
                    >
                      This product variant doesn't belong to any of the
                      GiftEffects's banned-items{" "}
                      <a href="https://uat.gifteffects.com/admin/cms/banned" target="__blank">
                        More Info
                      </a>{" "}
                    </label>
                  </div>
                )}
              <SubmitControl
                style={{
                  backgroundColor: "#595D65",
                  backgroundImage: "none",
                  width: "80%",
                  marginBottom: 32,
                  marginLeft: 32,
                  marginTop: 15,
                  fontWeight: "regular",
                }}
                innerWrapperClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
            </Form>
          </div>
        </div>
        <ActivityLog
          logs={this.props.product && this.props.product.activityLog}
        />
      </div>
    );
  }
}

export default connect(
  ({ delivery, products, counter, auth }, { match }) => ({
    productId: match.params.productId,
    currencyISO: counter.currencyISO,
    product: products.detail || {},
    accountRole: auth.account_role,
    deliveryPartner: delivery.deliveryPartner || [],
    deliveryModes: delivery.deliveryModes || [],
  }),
  {
    loadDeliveryModesAndPartners,
    getProductDetail,
    editProduct,
    uploadImagetoS3,
    editProductMoreDetails,
    getProductReturnPolicy,
  }
)(ProductEditComponent);
