import React, { Component } from "react";

export function SubmitControll({
    title="Verify Shopify Connection",
    classNames=["btn", "btn-primary", "col-xs-12"], 
    outerWrapperClassNames=["form-group"],
    innerWrapperClassNames=["col-md-offset-3", "col-sm-7"],
    ...otherProps
}) {
    const attrs = {
        type : "button",
        name:"Verify Shopify Connection",
        value:title,
        className:classNames.join(" ")
    }
    return <div className={outerWrapperClassNames.join(" ")}>
        <div className={innerWrapperClassNames.join(" ")} style={{padding:0}}>
            <input {...attrs} {...otherProps}/>
        </div>
    </div>
}