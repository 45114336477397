import React from "react";
import { connect } from "react-redux";
import MultiSelect from "react-select";
import { getAllProducts, getAllProductgroups, updateProduct } from "../../../store/actions/product";
import "../orders/orders.css";
class ListProducts extends React.Component {
    componentDidMount() {
        this.props.getAllProductgroups()
        this.props.getAllProducts()
    }
    state = { showPopup: false }
    handleClose = () => {
        this.setState({ showPopup: false })
    }
    handleChange = (productId,payload) => {
        this.props.updateProduct(productId, payload)
    }
    onEditClicked = (options, product, productId, productGroups, productGroup) => {
        localStorage.setItem(`options`, JSON.stringify(options))
        localStorage.setItem(`product`, JSON.stringify(product))
        localStorage.setItem(`productId`, productId)
        localStorage.setItem(`productGroups`, JSON.stringify(productGroups))
        localStorage.setItem(`productGroup`, JSON.stringify(productGroup))
        window.open(`/edit-product`, '_blank')
    }
    render() {
        const options = (this.props.productGroups || []).map(country => ({ label: country.name, value: country.id }))
        return (
            <div>
                <table className="table table-hovered">
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Product ID</th>
                            <th>Product Group</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(this.props.products || []).map((product, index) =>
                            <tr key={index}>
                                <td style={{ width: '50%' }}>{product.name}</td>
                                <td>{product.product_short_id}</td>
                                <td>{((this.props.productGroups || []).find(productGroup => productGroup.id === product.product_group.id) || {}).name || `N/A`}</td>
                                <td><input className="btn btn-info" type="submit" style={{ cursor: 'pointer' }} value="Edit product group"
                                    // onClick={e => this.setState({ showPopup: true, productId:product.id })} value="Edit Product group" />
                                    onClick={e => this.onEditClicked(options, product, product.id, this.props.productGroups, 
                                        this.props.productGroups.find(productGroup => productGroup.id == product.product_group.id)
                                    )} />
                                </td>
                                {/* <td>{this.state.showPopup &&
                                    <SubComponent
                                        options={options}
                                        product={product}
                                        selectedProductId={this.state.productId}
                                        productGroups={this.props.productGroups}
                                        productGroup={this.props.productGroups.find(productGroup => productGroup.id == product.product_group.id)}
                                        handleClose={this.handleClose}
                                        handleChange={this.handleChange}
                                    />} */}
                                {/* </td> */}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}


const mapStateToProps = ({ allProducts }) => ({ productGroups: allProducts.productGroups, products: allProducts.products })
export default connect(mapStateToProps, { getAllProductgroups, getAllProducts, updateProduct })(ListProducts)