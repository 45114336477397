export const LOADING_ORDERS_PROGRESS = "LOADING_ORDERS_PROGRESS";
export const LOADING_ORDERS_SUCCESS = "LOADING_ORDERS_SUCCESS";
export const LOADING_ORDERS_FAILED = "LOADING_ORDERS_FAILED";

export const DOWNLOADING_ORDERS_PROGRESS = "DOWNLOADING_ORDERS_PROGRESS";
export const DOWNLOADING_ORDERS_SUCCESS = "DOWNLOADING_ORDERS_SUCCESS";
export const DOWNLOADING_ORDERS_FAILED = "DOWNLOADING_ORDERS_FAILED";

export const LOADING_ORDER_DETAIL_PROGRESS = "LOADING_ORDER_DETAIL_PROGRESS";
export const LOADING_ORDER_DETAIL_SUCCESS = "LOADING_ORDER_DETAIL_SUCCESS";
export const LOADING_ORDER_DETAIL_FAILED = "LOADING_ORDER_DETAIL_FAILED";

export const UPDATE_ORDER_PROGRESS = "UPDATE_ORDER_PROGRESS";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILED = "UPDATE_ORDER_FAILED";

export const GET_SUPPORT_NUMBER_PROGRESS = "GET_SUPPORT_NUMBER_PROGRESS";
export const GET_SUPPORT_NUMBER_SUCCESS = "GET_SUPPORT_NUMBER_SUCCESS";
export const GET_SUPPORT_NUMBER_FAILED = "GET_SUPPORT_NUMBER_FAILED";