import React from "react";
import MultiSelect from "react-select";
import { updateProduct } from "../../../store/actions/product";
import { connect } from "react-redux";
class SubComponent extends React.Component {
    constructor(){
        super()
        this.handleChange = this.handleChange.bind(this)
    }
    state = {}
    handleChange = () => {
        const { options, product,productId,productGroups,productGroup,...others} = this.state
        this.props.updateProduct(this.state.productId, others)
    }
    componentWillMount(){
        this.setState({
            options: JSON.parse(localStorage.getItem(`options`)),
            product: JSON.parse(localStorage.getItem(`product`)),
            productId: localStorage.getItem(`productId`),
            productGroups: JSON.parse(localStorage.getItem(`productGroups`)),
            productGroup: JSON.parse(localStorage.getItem(`productGroup`))
        })
        localStorage.removeItem(`options`)
        localStorage.removeItem(`product`)
        // localStorage.removeItem(`productId`)
        localStorage.removeItem(`productGroups`)
        localStorage.removeItem(`productGroup`)
    }
    render() {
        const currentSelected = this.state.productGroups.find(productGroup => productGroup.id === this.state.product_group)
        return (
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <div className='form-group' style={{ paddingTop: 35, width: '70%', margin: 'auto' }}>
                            <div className='multi_select row'>
                                <MultiSelect placeholder="Please Select Product Group"
                                    className='col-md-12'
                                    style={{ borderWidth: 0, width: '100%', marginLeft: 0 }}
                                    options={this.state.options}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={option => this.setState({ product_group: option.value })}
                                />
                            </div>
                            {(currentSelected || { other_attributes: [] }).other_attributes.map((attribute, index) =>
                                (
                                    <div key={index} style={{ marginTop: '10px' }}>
                                        <input style={{ width: '100%' }} className="form-control" required type='text' placeholder={attribute} value={this.state[attribute]} onChange={e => this.setState({ [attribute]: e.target.value })} />
                                    </div>
                                ))}
                            <input onClick={this.handleChange} value="Submit" className="btn btn-success col-md-4 col-md-offset-4" type="button" style={{ marginTop: '30px' }} />
                        </div>
                    </div>
                </div>
        )
    }
}

export default connect(null, { updateProduct })(SubComponent)
