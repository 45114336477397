import React, { Component } from "react";
import { connect } from "react-redux";
import { InputControl, SubmitControl, Form } from "../forms";
import { MINIMUM_8_ALPHA_NUMERIC } from "../vendors/create-password";
import { MINUIM_PASSWORD_MESSAGE } from "../vendors/create-password";
import { resetUserPassword } from "../../store/actions/users";
var divStyle = {
    width: "50%",
    border: "1px solid #A2A2A2",
    borderRadius: 8,
    margin: "auto",
  },
  headingStyle = {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 40,
    marginBottom: 40,
    textAlign: "center",
  };

class ResetUserPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmitted = this.onSubmitted.bind(this);
    this.state = {
      password: "",
      confirm_password: "",
      error: this.getInitialErrorState(),
      searchUrl: window.location.search,
    };
  }

  getInitialErrorState = () => {
    return { isError: false, key: "", message: "ⓘ " + MINUIM_PASSWORD_MESSAGE };
  };
  onSubmitted = async () => {
    const { error, ...rest } = this.state;
    if (!!error.isError) {
      return;
    }
    if (!rest.confirm_password || rest.confirm_password !== rest.password) {
      alert("Password and Confirm Password should be same");
    } else {
      let response = await this.props.resetUserPassword(
        { password: rest.password },
        rest.searchUrl
      );
      if (response) {
        window.location = `/reset-user-success`;
      }
    }
  };

  handlePasswordChnage = (password) => {
    if (!MINIMUM_8_ALPHA_NUMERIC.test(password)) {
      this.setState({
        error: {
          isError: true,
          key: "password",
          message: MINUIM_PASSWORD_MESSAGE,
        },
        password,
      });
      return;
    }
    this.setState({
      password,
      error: { ...this.getInitialErrorState(), message: "" },
    });
  };
  render() {
    const { password, confirm_password } = this.state;
    return (
      <div style={divStyle}>
        <p style={headingStyle}>Reset Password</p>
        <Form onSubmit={this.onSubmitted}>
          <InputControl
            type="password"
            value={password}
            name=""
            placeholder="Password"
            onChange={this.handlePasswordChnage}
            style={{
              marginTop: 23,
              border: "none",
              boxShadow: "none",
              borderRadius: 0,
              fontSize: 14,
              padding: 0,
              fontWeight: 350,
              color: "#595D65",
            }}
          />
          <div
            style={{
              display: "flex",
              marginLeft: "24%",
              marginRight: "24%",
              marginTop: -15,
            }}
          >
            <label
              style={{
                fontSize: 10,
                color: this.state.error.isError ? "red" : "grey",
              }}
            >
              {this.state.error.message}
            </label>
          </div>
          <InputControl
            type="password"
            value={confirm_password}
            name=""
            placeholder="Confirm Password"
            onChange={(confirm_password) => this.setState({ confirm_password })}
            style={{
              marginTop: 23,
              border: "none",
              boxShadow: "none",
              borderRadius: 0,
              fontSize: 14,
              padding: 0,
              fontWeight: 350,
              color: "#595D65",
            }}
          />
          <SubmitControl
            title="Create Password"
            style={{
              backgroundColor: "#595D65",
              backgroundImage: "none",
              width: "86%",
              marginBottom: 32,
              marginTop: 60,
            }}
          />
        </Form>
      </div>
    );
  }
}

export default connect(null, {
  resetUserPassword,
})(ResetUserPasswordComponent);