import React, { Component } from "react";
import { MINUIM_PASSWORD_MESSAGE } from "../vendors/create-password";

export function InputControl({
  label,
  name,
  type = "text",
  value = "",
  required = false,
  readOnly = false,
  placeholder = "",
  classNames = ["form-control"],
  labelClassNames = ["control-label", "col-sm-3"],
  outerWrapperClassNames = ["form-group"],
  innerWrapperClassNames = ["col-sm-6"],
  onChange = undefined,
  ...otherProps
}) {
  const changeHandler = (event) => onChange.call(null, event.target.value);
  const inputAttrs = Object.assign(
    {
      type,
      name,
      required,
      readOnly,
      placeholder,
      className: classNames.join(" "),
    },
    onChange ? { value, onChange: changeHandler } : { defaultValue: value }
  );
  const labelAttrs = { className: labelClassNames.join(" "), htmlFor: name };
  return (
      <div className={outerWrapperClassNames.join(" ")}>
        <label
          {...labelAttrs}
          style={{
            textAlign: "left",
            padding: 0,
            fontSize: 10,
            fontWeight: 400,
          }}
        >
          {label}
        </label>
        <div
          className={innerWrapperClassNames.join(" ")}
          style={{ borderBottom: "1px solid #C7D1DB", padding: 0 }}
        >
          <input
            {...inputAttrs}
            {...otherProps}
            style={{ border: "none", boxShadow: "none", padding: 0, marginTop: "-8px" }}
          />
        </div>
      </div>
  );
}
