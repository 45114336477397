import React, { Component } from "react";
import { Switch, Route } from "react-router";
import ModalSwitch from "../../router-enhancements/modal-switch";
import ProductGroupsListingComponent from "./list";
import ProductGroupCreateComponent from "./create";
import AddProductComponent from './add-product';
import EditProductGroupComponent from "./edit";
import ProductListComponent from "./list-products";
export default function productGroupsRouter(props) {
    const { location, history } = props;
    return <ModalSwitch location={location} history={history}>
        <Route path="/product-groups/new" component={ProductGroupCreateComponent} />
        <Route path="/product-groups/:groupId/add-products" component={AddProductComponent} />
        <Route path="/product-groups/:groupId/products" component={ProductListComponent} />
        <Route path="/product-groups/:groupId/edit" component={EditProductGroupComponent} />
        <Route path="/" component={ProductGroupsListingComponent} />
    </ModalSwitch>
}
