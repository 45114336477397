export * from "./counter";
export * from "./auth";
export * from './vendors';
export * from './product-groups';

export const REFRESH_STATUS_IN_PROGRESS = "REFRESH_STATUS_IN_PROGRESS";
export const REFRESH_STATUS_SUCCESS = "REFRESH_STATUS_SUCCESS";
export const REFRESH_STATUS_FAILED = "REFRESH_STATUS_FAILED";

export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SHOW_TOAST = "SHOW_TOAST"
export const STOP_TOAST = "STOP_TOAST"

export const LOADING_COUNTRIES = "LOADING_COUNTRIES";
export const LOADING_COUNTRIES_SUCCESS = "LOADING_COUNTRIES_SUCCESS";
export const LOADING_COUNTRIES_FAILED = "LOADING_COUNTRIES_FAILED";

export const LOADING_DELIVERY_OPTIONS = "LOADING_DELIVERY_OPTIONS";
export const LOADING_DELIVERY_OPTIONS_SUCCESS = "LOADING_DELIVERY_OPTIONS_SUCCESS";
export const LOADING_DELIVERY_OPTIONS_FAILED = "LOADING_DELIVERY_OPTIONS_FAILED";

export const UPLOADING_IMAGE_PROGRESS = "UPLOADING_IMAGE_PROGRESS";
export const UPLOADING_IMAGE_SUCCESS = "UPLOADING_IMAGE_SUCCESS";
export const UPLOADING_IMAGE_FAILED = "UPLOADING_IMAGE_FAILED"

export const LOADING_STOCK_PROGRESS = "LOADING_STOCK_PROGRESS";
export const LOADING_STOCK_SUCCESS = "LOADING_STOCK_SUCCESS";
export const LOADING_STOCK_FAILED = "LOADING_STOCK_FAILED";

export const LOADING_PAYMENTS_PROGRESS = "LOADING_PAYMENTS_PROGRESS";
export const LOADING_PAYMENTS_SUCCESS = "LOADING_PAYMENTS_SUCCESS";
export const LOADING_PAYMENTS_FAILED = "LOADING_PAYMENTS_FAILED";

export const LOADING_PENDING_PAYMENTS_PROGRESS = "LOADING_PENDING_PAYMENTS_PROGRESS";
export const LOADING_PENDING_PAYMENTS_SUCCESS = "LOADING_PENDING_PAYMENTS_SUCCESS";
export const LOADING_PENDING_PAYMENTS_FAILED = "LOADING_PENDING_PAYMENTS_FAILED";

export const LOADING_NOTIFICATIONS_PROGRESS = "LOADING_NOTIFICATIONS_PROGRESS";
export const LOADING_NOTIFICAIONS_SUCCESS = "LOADING_NOTIFICAIONS_SUCCESS";
export const LOADING_NOTIFICATIONS_FAILED = "LOADING_NOTIFICATIONS_FAILED";

export const LOADING_REGISTRATION_PROGRESS = "LOADING_REGISTRATION_PROGRESS";
export const LOADING_REGISTRATION_SUCCESS = "LOADING_REGISTRATION_SUCCESS";
export const LOADING_REGISTRATION_FAILED = "LOADING_REGISTRATION_FAILED";

export const LOADING_EMAIL_VERIFY_PROGRESS = "LOADING_EMAIL_VERIFY_PROGRESS";
export const LOADING_EMAIL_VERIFY_SUCCESS = "LOADING_EMAIL_VERIFY_SUCCESS";
export const LOADING_EMAIL_VERIFY_FAILED = "LOADING_EMAIL_VERIFY_FAILED";

export const LOADING_FORGOT_PASSWORD_PROGRESS = "LOADING_FORGOT_PASSWORD_PROGRESS";
export const LOADING_FORGOT_PASSWORD_SUCCESS = "LOADING_FORGOT_PASSWORD_SUCCESS";
export const LOADING_FORGOT_PASSWORD_FAILED = "LOADING_FORGOT_PASSWORD_FAILED";

export const LOADING_SHIPMENT = "LOADING_SHIPMENT_PROGRESS";
export const LOADING_SHIPMENT_SUCCESS = "LOADING_SHIPMENT_SUCCESS";
export const LOADING_SHIPMENT_FAILED = "LOADING_SHIPMENT_FAILED"

export const LOADING_PRODUCT_POLICY_PROGRESS = "LOADING_PRODUCT_POLICY_PROGRESS";
export const LOADING_PRODUCT_POLICY_SUCCESS = "LOADING_PRODUCT_POLICY_SUCCESS";
export const LOADING_PRODUCT_POLICY_FAILED = "LOADING_PRODUCT_POLICY_FAILED"