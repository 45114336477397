import React, { Component } from "react";
import { connect } from "react-redux";
import { editProduct, archiveProduct, unArchiveProduct, activateProduct } from "../../../store/actions/product";
import { NavLink } from "react-router-dom";

const customStyle = {
    dataStyles:{
        paddingBottom:10,
        paddingTop:30,
        textAlign:"center",
        width:200
    },
    buttonStyle :{
        backgroundColor:"transparent",
        borderWidth:2,
        borderColor:"#979797",
        outline:"none",
        paddingTop:8,
        paddingBottom:8,
        borderRadius:4,
        marginBottom:10,
        color:"#595D65",
        width:170
    },
    dataStylesFirst:{
        paddingLeft:32,
        paddingBottom:10,
        paddingTop:30,
        width:400
    }
}

class ProductListTableRowComponent extends Component {
    constructor(props) {
        super(props)
        this.onDataChanged = this.onDataChanged.bind(this)
        this.onProductArchived  = this.onProductArchived.bind(this)
        this.onUpdateClicked = this.onUpdateClicked.bind(this)
        this.onActiveClicked = this.onActiveClicked.bind(this)
        this.onCancelClicked = this.onCancelClicked.bind(this)
    }
    state = {
        isStockEditable: false,
        isSellingPriceEditable: false,
        editIconStyle:{marginBottom:3,cursor:"pointer"},
        editIconStyle1:{marginBottom:3,cursor:"pointer"},
        divStyle:{display:'none'},
        divStyle1:{display:'none'},
        groupId:this.props.groupId,
        archived:this.props.product.archived,
        productId:this.props.product.id, 
        name:this.props.product.name, 
        stock:this.props.product.stock, 
        cost:this.props.product.cost, 
        description:this.props.product.description,
        bg_images:this.props.product.bg_images,
        images:this.props.product.images
    }
    onDataChanged(event){
        this.setState({[event.target.attributes['data-productkey'].nodeValue]: event.target.innerText})
    }
    onProductArchived(productId){
        this.props.archiveProduct(productId,{archived:true})
    }
    onProductUnArchived(productId){
        this.props.unArchiveProduct(productId, { archived: false })
    }
    onActiveClicked(){
        this.props.activateProduct(this.state.productId, this.state.groupId, {isActive: true})
    }
    onEditIconClicked(data){
        this.setState(data)
    }
    onCancelClicked(data){
        this.setState(data)
    }
    onUpdateClicked(){
        this.setState({
            editIconStyle:{marginBottom:3,cursor:"pointer"},
            editIconStyle1:{marginBottom:3,cursor:"pointer"},
            divStyle:{display:'none'},
            divStyle1:{display:'none'}    
        })
        this.props.editProduct(this.state.productId, this.state)
    }
    componentWillReceiveProps(nextProps){
        const {product, groupId} = nextProps
        this.setState({
            groupId: groupId,
            archived: product.archived,
            productId: product.id,
            name: product.name,
            stock: product.stock,
            cost: product.cost,
            description: product.description,
            bg_images: product.bg_images,
            images: product.images
        })
    }
    render() {
        const { name, stock, cost, description, images, isStockEditable, isSellingPriceEditable, productId, archived } = this.state
        return <tr>
            <td style={customStyle.dataStylesFirst}>
              <div style={{display:"flex"}}>
                  <div>
                    <img src={images[0] || `https://appx-dev-ge.s3.ap-south-1.amazonaws.com/Gift_Effects_Logo.png`} height={88} width={88} />
                  </div>
                  <div style={{marginLeft:15,width:250}}>
                    <p style={{fontWeight:"bold"}}>{name}</p>
                    <p style={{wordWrap:'break-word'}}>{description}</p>
                  </div>
                </div>
            </td>
            <td style={customStyle.dataStyles}>{productId}</td>
            <td style={customStyle.dataStyles}>
                <div>
                    <div tabIndex="0" data-productkey="stock" contentEditable={isStockEditable} onKeyUp={this.onDataChanged}>
                        <span style={{marginRight:13}}>{this.props.product.stock}</span>
                        {this.props.accountRole !== `VENDOR_DATA_ENTRY` && 
                        <img src={require('../../../images/edit.png')} onClick={event => this.onEditIconClicked({isStockEditable:true,editIconStyle:{display:'none'}, divStyle:{display:'block'}})} style={this.state.editIconStyle}/>}
                    </div>
                    <div style={this.state.divStyle}>
                        <span style={{ cursor:"pointer"}} onClick={event => this.onUpdateClicked()}>Update</span> | <span style={{opacity:0.5,cursor:"pointer"}} onClick={event => this.onCancelClicked({isStockEditable:false, editIconStyle:{marginBottom:3,cursor:"pointer"}, divStyle:{display:'none'}})}>Cancel</span>
                    </div>
                </div>
            </td>
            <td style={customStyle.dataStyles}>
                <div>
                    <div tabIndex="0" data-productkey="cost" contentEditable={isSellingPriceEditable} onKeyUp={this.onDataChanged} style={{textAlign:"center"}}>
                        <span style={{marginRight:13}}>{this.props.product.cost || 0}</span>
                        {this.props.accountRole !== `VENDOR_DATA_ENTRY` && <img src={require('../../../images/edit.png')} onClick={event => this.onEditIconClicked({isSellingPriceEditable:true,editIconStyle1:{display:'none'}, divStyle1:{display:'block'}})} style={this.state.editIconStyle1} />}
                    </div>
                    <div style={this.state.divStyle1}>
                        <span style={{ cursor:"pointer"}} onClick={event => this.onUpdateClicked()}>Update</span> | <span style={{opacity:0.5,cursor:"pointer"}} onClick={event => this.onCancelClicked({isSellingPriceEditable:false,editIconStyle1:{marginBottom:3,cursor:"pointer"}, divStyle1:{display:'none'}})}>Cancel</span>
                    </div>
                </div>
            </td>
            <td style={customStyle.dataStyles}>
                <ul style={{ listStyleType: 'none', textAlign: "right" }}>
                    <li><NavLink to={{pathname: `/products/${this.state.productId}/update`}}>
                        <button style={customStyle.buttonStyle}>Edit Product Attributes</button></NavLink></li>
                    {this.props.accountRole !== `VENDOR_DATA_ENTRY` && !this.props.product.isActive && <li><NavLink to='#'><button onClick={event => this.onActiveClicked(productId)} style={customStyle.buttonStyle}>Make Active</button></NavLink></li>}
                        { this.props.accountRole !== `VENDOR_DATA_ENTRY` &&
                            (archived ? 
                            <li><NavLink to='#'><button onClick={event => this.onProductUnArchived(productId)} style={customStyle.buttonStyle}>Unarchive</button></NavLink></li>
                            :
                            <li><NavLink to='#'><button onClick={event => this.onProductArchived(productId)} style={customStyle.buttonStyle}>Archive Product</button></NavLink></li>
                        )}
                </ul>
            </td>
        </tr>;
    }
}

export default connect(null, { editProduct, activateProduct, archiveProduct, unArchiveProduct})(ProductListTableRowComponent)
