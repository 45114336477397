import React, { Component } from "react";
import { connect } from "react-redux";
import { listTeamMembers, deleteMember, updateRole } from "../../../store/actions/vendors";
import { NavLink } from "react-router-dom";
var buttonStyle = {
    backgroundColor:"transparent",
    borderWidth:2,
    borderColor:"#979797",
    outline:"none",
    paddingLeft:30,
    paddingRight:30,
    paddingTop:8,
    paddingBottom:8,
    borderRadius:4,
    marginRight:10,
    color:"#595D65",
    cursor:"pointer",
    marginTop:20,
    marginBottom:20
},selected={
    borderColor:"#F5900C"
}

class TeamMembersListComponent extends Component {
    constructor(props){
        super(props)
        const {teamMembers} = props
        this.state = {teamMembers:teamMembers}
    }
    componentDidMount(){
        this.props.listTeamMembers()
    }
    componentWillReceiveProps(nextProps){
        const {teamMembers} = nextProps
        this.setState({teamMembers:teamMembers})
    }
    deleteMember(memberId){
        if(window.confirm(`Are you sure, want to delete ?`)){
            this.props.deleteMember(memberId)
        }
        return
    }
    render(){
        return <div style={{width:"70%",margin:"auto",textAlign:"center"}}>
                
                {this.state.teamMembers && this.state.teamMembers.length ? 
                <div>
                <h4 style={{textAlign:'center'}}>TEAM MEMBERS</h4>
                <NavLink to="/team-members/new"><button style={buttonStyle}>+ ADD MEMBER</button></NavLink>
                <table className="table table-striped" style={{textAlign:"left"}}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.teamMembers.length && this.state.teamMembers.map((teamMember, index) => 
                            <tr key={index}>
                                <td>{teamMember.name}</td>
                                <td>{teamMember.email}</td>
                                <td>
                                    <select className="form-control" value={teamMember.accessRole} onChange={e => this.props.updateRole({member:teamMember.id, account_role:e.target.value})}>
                                        <option value='VENDOR'>Edit</option>
                                        <option value='VENDOR_MEMBER'>View</option>
                                        <option value='VENDOR_DATA_ENTRY'>Catalog Manager</option>
                                    </select>
                                </td>
                                <td>{teamMember.verified ? `Verified` : `Pending Verification`}</td>
                                <td style={{cursor:'pointer'}} onClick={event => this.deleteMember(teamMember.id)}>Delete</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                </div>
                :
                <div>
                <h4 style={{textAlign:'center'}}>Members not found</h4>
                <NavLink to="/team-members/new"><button style={buttonStyle}>+ ADD MEMBER</button></NavLink>
                </div>
                }
            </div>
    }
}
const mapStateToProps = ({vendors}) => ({teamMembers:vendors.data.teamMembers})
export default connect(mapStateToProps, {listTeamMembers, deleteMember, updateRole})(TeamMembersListComponent)