import React from "react";
// import htmlToImage from "html-to-image";
import {toPng} from "html-to-image";
import { connect } from "react-redux";
import { getOrderDetail, getSupportNumber } from "../store/actions/orders";
import { parse } from "querystring";

class TextToImage extends React.Component {
    componentDidMount(){
        document.getElementById("mydiv").innerHTML = this.props.name
        toPng(document.getElementById('mydiv'), {width:700, height:600, backgroundColor:"white"})
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = `Image.png`;
                link.href = dataUrl;
                link.click();
            });
    }
    render(){
        return (
            <div id="mydiv">

            </div>
        )
    }
}

class ImageRenderer extends React.Component {
    state = {}
    componentDidMount(){
        this.props.getOrderDetail(
            parse(this.props.location.search.replace(`?`, ``), {
                ignoreQueryPrefix: true,
            }).id
        )
        this.props.getSupportNumber()
    }
    componentWillReceiveProps(newProps){
        const {orderDetail, support_number} = newProps
        this.setState({...orderDetail, support_number})
    }
    
    render() {
        const addressLine1 = this.state.delivery_address && this.state.delivery_address.address && this.state.delivery_address.address.length > 50 ?
            this.state.delivery_address.address.substring(0, 50) : this.state.delivery_address && this.state.delivery_address.address;
        const addressLine2 = this.state.delivery_address && this.state.delivery_address.address && this.state.delivery_address.address.length > 50 ?
                                this.state.delivery_address.address.substring(50) : '';
        
        const bAddressLine1 = this.state.business && this.state.business.address && this.state.business.address.length > 50 ?
            this.state.business.address.substring(0, 50) : this.state.business && this.state.business.address;
        const bAddressLine2 = this.state.business && this.state.business.address && this.state.business.address.length > 50 ?
                                this.state.business.address.substring(50) : '';

        const html = this.state.delivery_address && `
        <div>
            <b><u>Delivery Address:</u></b>
            <p><b>Name:${this.state.delivery_address.name}</b></p>
            <p><b>Contact:</b> ${this.state.delivery_address.contact}</p>
            <p><b>Address:</b> ${addressLine1}</p>
            <p>${addressLine2}</p>
            <p>${this.state.delivery_address.city},  
                ${this.state.delivery_address.state}, 
            ${this.state.delivery_address.country}, 
            ${this.state.delivery_address.pincode}</p>
        </div>
        <div style="margin-top:20px, margin-bottom:20px">
            <b><u>Warehouse Address:</u></b>
            <p><b>Business:</b> ${this.state.business.company}</p>
            <p><b>Contact: </b> ${this.state.business.business_contact}</p>
            <p><b>Address:</b> ${bAddressLine1}</p>
            <p>${bAddressLine2}</p>
            <p>${this.state.business.city},
            ${this.state.business.vendor_country},
            ${this.state.business.postal_code}</p>
        </div>
        <div style="margin-top:20px">
            <p>For any queries reach at <b>${this.state.support_number || ``}</b></p>
        </div>
        `
        return html ? <TextToImage name={html} imageTitle={this.props.imageTitle} /> : 
        <div></div>
    }
}
const mapStateToProps = ({orders, vendors}) => ({
    orderDetail: orders.detail,
    support_number: (vendors || {}).supportNumber
})
export default connect(mapStateToProps, { getOrderDetail, getSupportNumber})(ImageRenderer)