import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import { verifyEmailAddress, emailVerificationSuccessPage } from "../../store/actions/vendors";
class VerifyEmailComponent extends Component {
    state = {
        searchUrl:window.location.search
    }
    async componentDidMount(){
      await this.props.verifyEmailAddress(this.state)
      this.props.emailVerificationSuccessPage()
    }
    render(){
      return (
        <div style={{ textAlign: "center", marginHorizontal: 15 }}>
        <ReactMarkdown source={this.props.pageContent.content}></ReactMarkdown>
        </div>
      );  
    }
}

const mapStateToProps = ({pageContent}) => ({pageContent:pageContent && pageContent.data || {content:''}})
export default connect(mapStateToProps, {verifyEmailAddress, emailVerificationSuccessPage})(VerifyEmailComponent)