import { LOADING_PRODUCT_SUCCESS, UPDATE_PRODUCT_SUCCESS, ARCHIVE_PRODUCT_SUCCESS, LOADING_PRODUCT_DETAIL_SUCCESS, UNARCHIVE_PRODUCT_SUCCESS } from "../types/products";

export default function products(state = {data:[], detail:{}, page:1, totalPages:1}, action) {
    const {type, products, page, totalPages} = action
    if(type === LOADING_PRODUCT_SUCCESS){
        return {...state, data:products, page, totalPages}
   }
    if(type === UPDATE_PRODUCT_SUCCESS){
        return {...state, 
            data:state.data.map(product => product.id === action.id ? {...action} : product),
            detail: state.detail != {} && state.detail.id === action.id ? {...action}: state.detail
        }
    }
    if(type === ARCHIVE_PRODUCT_SUCCESS){
        return {...state, data:state.data.map(product => product.id === action.id ? {...action} : product)}
    }
    if(type === UNARCHIVE_PRODUCT_SUCCESS){
        return {...state, data:state.data.map(product => product.id === action.id ? {...action}: product)}
    }
    if(type === LOADING_PRODUCT_DETAIL_SUCCESS){
        return {...state, detail:action}
    }
    return state
}