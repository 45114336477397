// eslint-disable-next-line
import React, { Component } from "react";
import { connect, } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { vendorLogout, switchVendorAccount } from "../store/actions/vendors";
const highlightStyle = {color:"#000000",fontSize:14,borderBottom:"3px solid #595D65",borderColor:"#595D65"}
const s = {
    pointerEvents:'none', //This makes it not clickable
    opacity:0.6         //This grays it out to look disabled
}
class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            searchUrl: window.location,
          }
        this.onAccountSwitch = this.onAccountSwitch.bind(this)    
    }
    onAccountSwitch(vendorId){
        this.props.switchVendorAccount(vendorId)
    }
    render(){
        const currentVendorDetail = this.props.vendor_details && this.props.vendor_details.find(v => v.vendor ===  this.props.currentVendor.id)
        return <div className="header-main">
            <nav className="navbar navbar-default navbar-static-top" style={{marginBottom:0}}>
                <div className="container">
                    <div className="navbar-header">
                    <button type="button" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar" className="navbar-toggle collapsed"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button><a href="/home" className="navbar-brand" style={{color:"#000000",fontWeight:"bold",marginLeft:80,fontSize:14}}><img src={require('../images/ge-vendor-logo.png')} style={{marginBottom:3,marginRight:8, height:23, width:22}}/>{this.state.searchUrl.href.includes("user")?"GIFT EFFECTS": "GIFT EFFECTS VENDOR HUB"}</a>                    
                    </div>
                    <div id="navbar" className="navbar-collapse collapse">
                        {this.props.isLoggedIn ?
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    {[`EDIT`,`VENDOR`].includes(this.props.account_role) ? 
                                    <span className='notificationCount'>
                                        <NavLink to='/notifications'>
                                            <img src={require('../images/bell.png')} height='16px' width='16px' style={{marginTop:'17px', marginRight:'20px'}} />
                                            <span className='count'>{this.props.notificationCount}</span>
                                        </NavLink>
                                    </span> : `` 
                                    // ((this.props.account_role != `VENDOR_DATA_ENTRY`) ? <NavLink style={{color:"#000000",fontSize:14}} activeStyle={highlightStyle} to='/orders'>ORDERS</NavLink>
                                    //  :
                                    //     (
                                    //         <div>
                                    //             <NavLink to="/product-groups" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14 }}>PRODUCT GROUPS</NavLink>            
                                    //             <NavLink style={{ color: "#000000", fontSize: 14 }} activeStyle={highlightStyle} to='/products'>PRODUCTS</NavLink>
                                    //         </div>
                                    // ))
                                    }
                                </li>
                                <li className='businessDropDown'>
                                    <ul className="dropDownItems">
                                        {[`EDIT`, `VENDOR`].includes(this.props.account_role) && <li style={currentVendorDetail && !currentVendorDetail.deactivated ? {} : s}><NavLink to="/profile">PROFILE</NavLink></li>}
                                        {this.props.vendors.map((vendor, index) => this.props.currentVendor.id !== vendor.id ? 
                                            <li key={index} style={currentVendorDetail && !currentVendorDetail.deactivated ? {} : s}><NavLink onClick={e => this.onAccountSwitch(vendor.id)} to='#'>Switch to {(vendor.company).toUpperCase()}</NavLink>
                                            </li>: <span key={index}></span>)}
                                        {[`EDIT`, `VENDOR`].includes(this.props.account_role) &&<li><NavLink to="/register" style={{color:"#000000",fontSize:14}}>REGISTER ANOTHER BUSINESS</NavLink></li>}
                                    </ul>
                                    {[`EDIT`, `VENDOR`].includes(this.props.account_role) ?
                                        <NavLink to="/profile" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14 }}> HELLO {this.props.currentVendor.company.toUpperCase()}<img style={{ marginLeft: '5px', marginBottom: '2px' }} src={require("../images/dropdown.png")} /></NavLink> :
                                        <a activeStyle={highlightStyle} style={{ color: "#000000", cursor:this.props.vendors.length > 1 ? 'pointer' : 'default', fontSize: 14 }}>HELLO {this.props.currentVendor.company.toUpperCase()}</a>
                                }
                                </li>
                                <li><NavLink to="/logout" activeStyle={highlightStyle} onClick={this.props.vendorLogout} style={{color:"#000000",fontSize:14}}>LOGOUT</NavLink></li>
                            </ul>
                            :
                            <ul className="nav navbar-nav navbar-right signin-btn" style={{marginRight:110}}>
                                <li><NavLink activeStyle={highlightStyle} to="/register" >REGISTER</NavLink></li>
                                <li><NavLink to="/login" activeStyle={highlightStyle}>SIGN IN</NavLink></li>
                            </ul>
                        }
                    </div>
                </div>
            </nav>
            <div style={{display:"inline-block",width:"100%",backgroundColor:"#EDEDED"}}>
                {this.props.isLoggedIn && [`EDIT`, `VENDOR`].includes(this.props.account_role) && currentVendorDetail && !currentVendorDetail.deactivated ? <ul className="nav" style={{width:"67%",margin:"auto"}}>
                                <li style={{display:"inline-block"}}><NavLink to="/home" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14,float:"none" }}>HOME</NavLink></li>
                                <li style={{display:"inline-block"}}><NavLink to="/product-groups" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14,float:"none" }}>PRODUCT GROUPS</NavLink></li>
                                <li style={{display:"inline-block"}}><NavLink to="/products" activeStyle={highlightStyle}  style={{ color: "#000000", fontSize: 14 }}>PRODUCTS</NavLink></li>
                                <li style={{display:"inline-block"}}><NavLink to="/payments" activeStyle={highlightStyle}  style={{ color: "#000000", fontSize: 14 }}>PAYMENTS</NavLink></li>
                                <li style={{display:"inline-block"}}><NavLink to="/orders" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14 }}>ORDERS</NavLink></li>
                                <li style={{display:"inline-block"}}><NavLink to="/team-members" activeStyle={highlightStyle} style={{color:"#000000", fontSize: 14}}>TEAM MEMBERS</NavLink></li>
                                <li style={{display:"inline-block"}}><NavLink to="/bank-information" activeStyle={highlightStyle} style={{color:"#000000", fontSize: 14}}>BANK INFORMATION</NavLink></li>                            
                            </ul> : 
                            (this.props.account_role === `VENDOR_DATA_ENTRY` ? 
                            <ul className="nav" style={{ width: "23%", margin: "auto" }}>
                                <li style={{ display: "inline-block" }}><NavLink to="/product-groups" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14, float: "none" }}>PRODUCT GROUPS</NavLink></li>
                                <li style={{ display: "inline-block" }}><NavLink to="/products" activeStyle={highlightStyle} style={{ color: "#000000", fontSize: 14 }}>PRODUCTS</NavLink></li>
                            </ul> : ``)
                        }
            </div>
            </div>
    }
}

const mapStateToProps = ({auth}) => ({vendor_details:auth.vendor_details, name : auth.name, vendors:auth.vendors, account_role:auth.account_role, currentVendor:auth.currentVendor, isLoggedIn : !!auth.token});

export default withRouter(connect(mapStateToProps, {vendorLogout, switchVendorAccount})(Header));