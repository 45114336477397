import React from "react";
import { formatDate } from "../../../utils";
function formatLi(log) {
    return log.changes.map((change, i) => (
    i == 0 ?
    (<tr>
        <td rowSpan={log.changes.length}>{log.activityBy.name}</td>
        <td rowSpan={log.changes.length}>{formatDate(log.createdAt)}</td>
        <td>{Object.keys(change)[0]}</td>
        <td>{change[Object.keys(change)[0]].previous}</td>
        <td>{change[Object.keys(change)[0]].current}</td>
    </tr>) : 
            (<tr>
                <td>{Object.keys(change)[0]}</td>
                <td>{change[Object.keys(change)[0]].previous}</td>
                <td>{change[Object.keys(change)[0]].current}</td>
            </tr>)
    ))
}
const ActivityLog = (props) => {
    return (<div style={{ maxHeight: '336px',overflow: 'auto'}}>
        {
            props.logs && props.logs.length && 
            (<table className="table table-striped">
                <thead>
                    <tr>
                        <th>Activity By</th>
                        <th>Activity At</th>
                        <th>Attribute</th>
                        <th>Before change</th>
                        <th>After change</th>
                    </tr>
                </thead>
                <tbody>
                    {props.logs.map(log => formatLi(log)
                        // <tr>
                        //     <td>{log.activityBy.name}</td>
                        //     <td>{formatDate(log.createdAt)}</td>
                        //     <td><ul>{log.changes.map(change => {

                        //         return Object.keys(change).map(key => {
                        //             return (<li style={{ marginBottom:5,listStyleType:'none'}}>
                        //                 <b style={{ marginRight: 5, textTransform: 'capitalize'}}><i>{key.replace(/_/g, ' ')}: </i></b>
                        //                 {change[key].current ? `${change[key].previous} - ${change[key].current}` : change[key].previous}
                        //             </li>) 
                        //         })
                        //     })}</ul></td>
                        // </tr>
                    )}
                </tbody>
            </table>)
        }
    </div>)
}
export default ActivityLog