import React, { Component } from "react";

export function PaginationLink({
  onClick,
  page = 1,
  title,
  query = "",
  classes = ["page-link"]
}) {
  //     const searchParams = new URLSearchParams(query || window.location.search);
  //     searchParams.set('page', page);
  //     const href = [path].concat(searchParams.toString() ? searchParams.toString() : []).join("?");
  return (
    <a
      style={{ cursor: "pointer" }}
      className={classes.join(" ")}
      onClick={onClick.bind(null, page)}
    >
      {title || page}
    </a>
  );
}

export default function PaginationLinks({
  onNavigateToPage = page => {},
  currentPage = 1,
  totalPages = 1,
  apiUrl = "/",
  classes = ["pagination", "justify-content-center"],
  itemClasses = ["page-item"],
  linkClass = "page-link",
  activeClass = "active",
  disabledClass = "disabled",
  maxLinks = 5
}) {
  const enablePrevious = currentPage > 1;
  const enableNext = currentPage < totalPages;
  const startingPage =
    maxLinks < totalPages
      ? Math.max(
          1,
          Math.min(
            totalPages - maxLinks + 1,
            currentPage - Math.floor(maxLinks / 2)
          )
        )
      : 1;
  const pages = new Array(Math.min(maxLinks, totalPages))
    .fill(0)
    .map((_, index) => index + startingPage);
  return (
    <nav align="center">
      <ul className={classes.join(" ")}>
        <li
          className={itemClasses
            .concat(enablePrevious ? [] : disabledClass)
            .join(" ")}
        >
          {enablePrevious ? (
            <PaginationLink
              page={Math.max(1, currentPage - 1)}
              title="Prev"
              onClick={onNavigateToPage}
            />
          ) : (
            <span className={linkClass}>Prev</span>
          )}
        </li>

        {pages.map((pageNo, index) => {
          const active = pageNo === currentPage;
          const classes = itemClasses.concat(active ? activeClass : []);
          return (
            <li className={classes.join(" ")} key={pageNo}>
              {active ? (
                <span className={linkClass}>
                  {pageNo}
                  {active && <span className="sr-only">(current)</span>}
                </span>
              ) : (
                <PaginationLink page={pageNo} onClick={onNavigateToPage} />
              )}
            </li>
          );
        })}
        <li
          className={itemClasses
            .concat(enableNext ? [] : disabledClass)
            .join(" ")}
        >
          {enableNext ? (
            <PaginationLink
              page={Math.min(totalPages, currentPage + 1)}
              title="Next"
              onClick={onNavigateToPage}
            />
          ) : (
            <span className={linkClass}>Next</span>
          )}
        </li>
      </ul>
    </nav>
  );
}
