import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, InputControl, SubmitControl, SelectControl, TextEditor } from "../../forms";
import { listProductGroups, uploadImagetoS3, loadProductGroupReturnPolicy } from "../../../store/actions/product-groups";
import { createProduct } from "../../../store/actions/product";
import Background from '../../../images/bg-image.png';
import Background1 from '../../../images/imagecurved.png'
import Carousel from "nuka-carousel";
import currencyList from "../../../currency-codes.json";
import { SingleDatePicker } from 'react-dates';
import { loadDeliveryModesAndPartners } from "../../../store/actions/vendors";
import MultiSelect from "react-select";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { BASE_URL } from "../../../store/api.middleware";

var customStyles = {
  borderWidth: 0
}
  , multiSelect = {
    borderWidth: 0,
    width: '100%'
  }
const style = {
  pill: {
    listStylePosition: 'inside',
    border: '1px solid gainsboro',
    display: 'inline-block',
    background: '#F5F5F7',
    minWidth: '127px',
    padding: '2px 0px 2px 8px',
    borderRadius: '20px',
    margin: '5px 2px'
  },
  ulStyle: {
    marginLeft: '-310px',
    paddingLeft: '0px',
    marginBottom: '0px',
    marginTop: '20px'
  },

  closeButtonStyle: {
    float: 'right',
    cursor: 'pointer',
    padding: '0px 8px',
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderLeftColor: 'gainsboro',
    marginLeft: '8px'
  }
}
var buttonStyle = {
  backgroundColor: "transparent",
  borderWidth: 2,
  borderColor: "#979797",
  outline: "none",
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 4,
  marginRight: 10,
}, selected = {
  borderColor: "#F5900C"
},
  hover = {
    position: "relative", width: "100%", height: "100%", cursor: 'pointer',
    'p': { display: "none" },
    '&::hover': {
      '&+p': {
        display: "block",
      }
    }
  }
class AddProductComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNumberFieldChange = this.onNumberFieldChange.bind(this);
    const {
      group = {
        id: "",
        name: "",
        returnPeriod: "",
        shipment: "",
        deliveryPartner: "",
        description: "",
        length: "",
        breadth: "",
        depth: "",
        dead_weight: "",
        cost: "",
        other_attributes: [],
        expected_delivery: "",
        images: [],
        bg_images: [],
        isGiftCard: false,
        giftCardFields: {}
      },
    } = props;
    this.state = {
      editorState: EditorState.createEmpty(),
      more_details: "",
      shipment: group.shipment,
      deliveryPartner: group.deliveryPartner,
      available_date: null,
      focused: false,
      otherAttrCopy: {},
      id: group.id,
      name: group.name,
      description: group.description,
      cost: group.cost,
      length: group.length ? group.length + "" : null,
      breadth: group.breadth ? group.breadth + "" : null,
      depth: group.depth ? group.depth + "" : null,
      dead_weight: group.dead_weight ? group.dead_weight + "" : null,
      images: !!group.images ? [...group.images] : [],
      bg_images: !!group.bg_images ? [...group.bg_images] : [],
      other_attributes: group.other_attributes,
      extra_attributes: group.other_attributes.reduce(
        (prev, attr) => ({ ...prev, [attr]: `` }),
        {}
      ),
      expected_delivery: group.expected_delivery,
      returnPeriod:
        group.returnPeriod || (this.props.profile || {}).returnPeriod || "",
      buttonStyle: { display: "none" },
      divStyle: { display: "none" },
      buttonStyles: {
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: "#979797",
        outline: "none",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 4,
        marginRight: 10,
      },
      isGiftCard: group.isGiftCard,
      giftCardFields: group.giftCardFields,
      stock: group.isGiftCard ? 1:0
    };
  }
  async componentDidMount() {
    // let response = await this.props.listProductGroups();
    this.props.loadDeliveryModesAndPartners();
    const {
      body: { return_policy },
    } = await this.props.loadProductGroupReturnPolicy(this.props.groupId);
    return_policy &&
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(return_policy))
        ),
      });
  }
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };
  onNumberFieldChange(key, val) {
    if (Number(val) >= 0) {
      this.setState({ [key]: val });
    }
  }
  onDataChanged = (key, value) => {
    this.setState({ [key]: value });
  };
  componentWillReceiveProps(nextProps) {
    const { group } = nextProps;
    console.log(group, "grp-=-=-")
    if (JSON.stringify(group) !== JSON.stringify(this.props.group)) {
      this.setState({
        shipment: this.state.shipment,
        deliveryPartner: this.state.deliveryPartner,
        id: this.state.id || group.id,
        name: this.state.name || group.name,
        description: this.state.description || group.description,
        cost: this.state.cost || group.cost,
        images: [...this.state.images, ...group.images] || [],
        bg_images: [...this.state.bg_images, ...group.bg_images] || [],
        other_attributes: group && group.other_attributes,
        expected_delivery:
          this.state.expected_delivery || group.expected_delivery,
        returnPeriod: this.state.returnPeriod || group.returnPeriod,
        extra_attributes:
          group &&
          group.other_attributes.reduce(
            (prev, attr) => ({ ...prev, [attr]: `` }),
            {}
          ),
      });
    }
  }
  onExtraAttrEdit(key, value) {
    this.setState({
      ...this.state,
      otherAttrCopy: { ...this.state.otherAttrCopy, [key]: value },
      extra_attributes: { ...this.state.extra_attributes, [key]: value },
    });
  }

  handleMultiShipment = (option) => {
    this.setState({ shipment: option.value });
    if (option.value === "door_delivery") {
      this.setState({
        deliveryPartner: this.state.deliveryPartner || "",
        banned_check: false,
      });
    }
  };

  handleMultiPartner = (option) => {
    this.setState({ deliveryPartner: option.value });
    if (option.value === "others") {
      this.setState({
        banned_check: false,
      });
    } else {
      this.setState({
        banned_check: true,
      });
    }
  };

  handleSubmit() {
    if (this.state.shipment === "digital") {
      this.setState({ length: "", breadth: "", depth: "", dead_weight: "" });
    }
    if (this.state.shipment !== "digital" && (!(Number(this.state.length) >= 0) || this.state.length === "")) {
      alert("Please enter a value for Length in cms. If not applicable for product, then please enter value of 0 (zero).");
      return;
    }
    if (this.state.shipment !== "digital" && (!(Number(this.state.breadth) >= 0) || this.state.breadth === "")) {
      alert("Please enter a value for Breadth in cms. If not applicable for product, then please enter value of 0 (zero).");
      return;
    }
    if (this.state.shipment !== "digital" && (!(Number(this.state.depth) >= 0) || this.state.depth === "")) {
      alert("Please enter a value for Depth in cms. If not applicable for product, then please enter value of 0 (zero).");
      return;
    }
    if (
      this.state.shipment !== "digital" && (!(Number(this.state.dead_weight) >= 0) || this.state.dead_weight === "")
    ) {
      alert("Please enter a value for Weight in kgs. If not applicable for product, then please enter value of 0 (zero)");
      return;
    }
    if (!this.state.shipment) {
      alert("Please select Delivery Mode");
      return;
    }
    if (
      this.state.shipment === "door_delivery" &&
      !this.state.deliveryPartner
    ) {
      alert("Please select Delivery Partner");
      return;
    }
    this.state.extra_attributes = Object.keys(
      this.state.extra_attributes
    ).reduce((prev, curr) => {
      Object.assign(prev, {
        [curr]:
          this.state.extra_attributes[curr].trim() ||
          this.state.otherAttrCopy[curr],
      });
      return prev;
    }, {});
    // if(!this.props.group.images.length && !this.state.images.length){
    //     alert('You should upload images')
    //     return
    // }
    // if(!this.state.images.length){
    //     if(window.confirm('If No images product group images will be considered')){
    //         this.setState({images:this.props.group.images, bg_images:this.props.group.bg_images})
    //     } else {
    //         return
    //     }
    // }
    this.props.createProduct(this.props.groupId, {
      ...this.state,
      images: this.state.images.length
        ? this.state.images
        : this.props.group.images,
      bg_images: this.state.bg_images.length
        ? this.state.bg_images
        : this.props.group.bg_images,
      return_policy: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
    });
  }
  onAddMoreClicked() {
    this.setState({
      divStyle: {
        width: 148,
        height: 150,
        backgroundColor: "#000000",
        display: "inline-block",
        position: "relative",
      },
      buttonStyles: { display: "none" },
    });
  }

  handleFile(e) {
    if (e.target.files[0]) {
      let uploadedImages = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedImages
        .then((res) => {
          this.setState({ images: this.state.images.concat(res) });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  addMoreDetails(e) {
    if (e.target.files[0]) {
      let uploadedImages = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      uploadedImages
        .then((res) => {
          this.setState({ more_details: res[0] });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  handleBgImage(e) {
    if (e.target.files[0]) {
      let uploadedImages = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedImages
        .then((res) => {
          this.setState({ bg_images: res });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  onImageRemoved(value) {
    this.setState({
      images: this.state.images.filter((image) => image !== value),
    });
  }
  render() {
    const deliveryModes = this.props.deliveryModes.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const deliveryPartners = this.props.deliveryPartners.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];

    const selectedDeliveryMode = deliveryModes.find(
      (option) => option.value === this.state.shipment
    );
    const selectedDeliveryPartner = deliveryPartners.find(
      (option) => option.value === this.state.deliveryPartner
    );

    const deliveryOptions = [];
    for (var i = 1; i <= 20; i++) {
      deliveryOptions.push({ label: i, value: i });
    }
    const {
      name,
      stock,
      description,
      cost,
      length,
      breadth,
      depth,
      dead_weight,
      other_attributes,
      extra_attributes,
      expected_delivery,
      returnPeriod,
    } = this.state;
    return (
      <div style={{ width: "70%", margin: "auto" }}>
        <div style={{ display: "flex", marginBottom: 30 }}>
          <div style={{ width: "30%" }}></div>
          {/* <div style={{marginLeft:"23%"}}>
                        <button onClick={this.onAddAttrClicked} style={buttonStyle}>+ ADD MORE ATTRIBUTES</button>
                    </div> */}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", textAlign: "center" }}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                height: 500,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#979797",
                boxShadow: "0 0 10px #979797",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 390,
                  top: 100,
                  backgroundImage: "url(" + Background1 + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                {console.log(this.state.images, "this.state.images-=-=-this.state.images")}
                {this.state.images.length == 0 ? (
                  <div
                    style={{
                      position: "relative",
                      color: "#FFF",
                      fontSize: 12,
                      width: "150px",
                      bottom: 0,
                      paddingBottom: 20,
                      background: "#333",
                      margin: "auto",
                      paddingTop: "35px",
                    }}
                  >
                    <p>Product Image</p>
                    <input
                      type="file"
                      id="abc"
                      onChange={(event) => this.handleFile(event, "images")}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="abc"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Click Here to Upload
                    </label>
                    <p>(Max. 380 X 310 Pixels)</p>
                  </div>
                ) : (
                  <>
                    <div>
                      <Carousel dragging={true} style={{ top: -30 }}>
                        {this.state.images.map((item, index) => (
                          <div key={index}>
                            <span style={{ position: "relative" }}>
                              <img
                                src={item}
                                style={{
                                  width: 150,
                                  height: 150,
                                  display: "inline-block",
                                }}
                              />
                              <img
                                src={require("../../../images/close.png")}
                                alt=""
                                onClick={(event) => this.onImageRemoved(item)}
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: -70,
                                  color: "white",
                                  padding: 5,
                                  width: 25,
                                  height: 25,
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div style={{ marginTop: 45 }}>
                      <button
                        onClick={(event) => this.onAddMoreClicked()}
                        style={this.state.buttonStyles}
                      >
                        ADD MORE IMAGES
                      </button>
                      <div style={this.state.divStyle}>
                        <div
                          style={{
                            position: "absolute",
                            color: "#FFFFFF",
                            fontSize: 12,
                            width: "100%",
                            bottom: 0,
                            paddingBottom: 20,
                          }}
                        >
                          <p>Product Image</p>
                          <input
                            type="file"
                            id="abc"
                            onChange={(event) => this.handleFile(event)}
                            style={{ display: "none" }}
                          />
                          <label
                            htmlFor="abc"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Click Here to Upload
                          </label>
                          <p>(Max. 380 X 310 Pixels)</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <p
                style={{
                  textAlign: "left",
                  position: "absolute",
                  paddingLeft: 10,
                  bottom: 0,
                }}
              >
                <a href={`${BASE_URL}/best_practice`} target="_blank">
                  Best Practices
                </a>
              </p>
            </div>
            {/* <div style={{marginTop:25}}>
                        </div> */}
          </div>

          <div style={{ width: "55%" }}>
            <Form onSubmit={this.handleSubmit}>
              <InputControl
                label="Product Name"
                value={name}
                onChange={(name) => this.setState({ name })}
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div className="form-group">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Description
                </label>
                <div className="col-sm-offset-3">
                  <textarea
                    rows="4"
                    cols="48"
                    style={{
                      resize: "none",
                      width: "100%",
                      marginBottom: "12px",
                      outline: "0",
                      borderWidth: "0 0 0.2px",
                    }}
                    label="Description"
                    required
                    value={description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    className={["form-control"]}
                    innerWrapperClassNames={["col-sm-offset-3"]}
                    labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                  />
                </div>
              </div>
              {this.state.shipment !== "digital" && (
                <div>
                  <div className="Length">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Length/Height (cms)"
                      placeholder="Length/Height in cms"
                      value={length}
                      onChange={(length) =>
                        this.onNumberFieldChange("length", length)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Breadth">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Breadth/Width (cms)"
                      placeholder="Breadth/Width in cms"
                      value={breadth}
                      onChange={(breadth) =>
                        this.onNumberFieldChange("breadth", breadth)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Depth">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Depth (cms)"
                      placeholder="Depth in cms"
                      value={depth}
                      onChange={(depth) =>
                        this.onNumberFieldChange("depth", depth)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Dead Weight">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Weight (kgs)"
                      placeholder="Weight in kgs"
                      value={dead_weight}
                      onChange={(dead_weight) =>
                        this.onNumberFieldChange("dead_weight", dead_weight)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                </div>
              )}
              {
                !this.state.isGiftCard ?
                  <div>
                    <InputControl
                      label="Stock"
                      value={stock}
                      placeholder="Stock"
                      onChange={(stock) => this.onNumberFieldChange("stock", stock)}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <div className="selling-price">
                      <span className="currency">
                        {currencyList[this.props.currencyISO]}
                      </span>
                      <InputControl
                        label="Selling Price"
                        value={cost}
                        onChange={(cost) => this.onNumberFieldChange("cost", cost)}
                        classNames={["form-control"]}
                        innerWrapperClassNames={["col-sm-offset-3"]}
                        labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                      />
                    </div>
                    <SelectControl
                      label="Expected Delivery in Days"
                      value={expected_delivery}
                      options={deliveryOptions}
                      onChange={(expected_delivery) =>
                        this.setState({ expected_delivery })
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <InputControl
                      label="Max days for return"
                      required={true}
                      value={returnPeriod}
                      placeholder="Max days for return"
                      onChange={(returnPeriod) =>
                        this.onNumberFieldChange("returnPeriod", returnPeriod)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <div style={{ marginLeft: "118px", marginBottom: 13 }}>
                      <label>Return Policy</label>
                      <TextEditor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                    <div className="date-picker">
                      <label
                        className="col-sm-offset-3 col-sm-9"
                        style={{
                          textAlign: "left",
                          padding: 0,
                          fontSize: 10,
                          fontWeight: 400,
                        }}
                      >
                        Due Date
                      </label>
                      <div className="picker-container col-sm-offset-3">
                        <SingleDatePicker
                          date={this.state.available_date}
                          onDateChange={(available_date) =>
                            this.setState({ available_date })
                          }
                          onFocusChange={({ focused }) => this.setState({ focused })}
                          id="1"
                          showDefaultInputIcon={true}
                          focused={this.state.focused}
                          showClearDate={true}
                          small={true}
                          numberOfMonths={1}
                          placeholder="Please select available until date (If any)"
                        />
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <InputControl
                      label="Min Amount"
                      required
                      value={this.state.giftCardFields.minAmount}
                      onChange={(minAmt) => this.setState({ giftCardFields: { ...this.state.giftCardFields, minAmount: minAmt } })}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <InputControl
                      label="Max Amount"
                      required
                      value={this.state.giftCardFields.maxAmount}
                      onChange={(maxAmt) => this.setState({ giftCardFields: { ...this.state.giftCardFields, maxAmount: maxAmt } })}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <InputControl
                      label="Valid for (in years)"
                      required
                      value={this.state.giftCardFields.validForYears}
                      onChange={(years) => this.setState({ giftCardFields: { ...this.state.giftCardFields, validForYears: years } })}
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                    <div className="chkbox-container" style={{ marginBottom: '12px' }}>
                      <label style={{ width: "24%" }} className="lbl-mobile"></label>
                      <input type="checkbox" name="acceptIntlCards" checked={this.state.giftCardFields.acceptIntlCards} onChange={event => this.setState({ giftCardFields: { ...this.state.giftCardFields, acceptIntlCards: event.target.checked } })} className="chkbox" />
                      <label style={{ marginLeft: 12, fontSize: 12, fontWeight: 350, opacity: 0.5 }}>Accept international cards</label>
                    </div>
                    <div className="col-sm-offset-3" style={{ marginBottom: '12px' }}>
                      Delivery Mode: Digital
                    </div>
                  </div>
              }
              <div className="form-group">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: "0px",
                    fontSize: "10px",
                    fontWeight: 400,
                    marginTop: "15px",
                  }}
                >
                  Add More Details
                </label>
                {this.state.more_details ? (
                  <div style={{ display: "flex" }}>
                    <ul style={style.ulStyle}>
                      {
                        <li key={0} style={style.pill}>
                          <a
                            style={{ textDecoration: "none" }}
                            href={this.state.more_details}
                            target="_blank"
                          >
                            View Existing
                          </a>
                          <span
                            title="remove"
                            onClick={(e) => this.setState({ more_details: "" })}
                            style={style.closeButtonStyle}
                          >
                            &times;
                          </span>
                        </li>
                      }
                    </ul>
                  </div>
                ) : (
                  <span></span>
                )}
                <div className="col-sm-offset-3">
                  <input
                    type="file"
                    accept=".xls, .xlsx, .png, .svg, .jpg, .jpeg, .doc, .docx, .pdf"
                    id="bg-img1"
                    style={{ display: "none" }}
                    onChange={(event) => this.addMoreDetails(event)}
                  />
                  <label
                    className="col-sm-offset-3 col-sm-9"
                    htmlFor="bg-img1"
                    style={{
                      textAlign: "left",
                      padding: "0px",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginTop: "35px",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Click Here to Upload More Details
                  </label>
                </div>
              </div>
              {other_attributes && other_attributes.length ? (
                other_attributes.map((attribute, i) => (
                  <InputControl
                    placeholder={attribute}
                    required
                    value={
                      extra_attributes[attribute] ||
                      this.state.otherAttrCopy[attribute]
                    }
                    key={i}
                    onChange={(changedValue) =>
                      this.onExtraAttrEdit(attribute, changedValue)
                    }
                    classNames={["form-control"]}
                    innerWrapperClassNames={["col-sm-offset-3"]}
                    labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                  />
                ))
              ) : (
                <span></span>
              )}
              <div className="form-group" style={multiSelect}>
                <label
                  className="col-sm-9 col-sm-offset-3"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Delivery Mode
                </label>
                <MultiSelect
                  placeholder="Please Select Delivery Mode"
                  value={selectedDeliveryMode}
                  options={deliveryModes}
                  isMulti={false}
                  isSearchable={true}
                  onChange={this.handleMultiShipment}
                  className="col-sm-10 col-sm-offset-3"
                  style={customStyles}
                />
              </div>
              {this.state.shipment === "door_delivery" && (
                <div className="form-group" style={multiSelect}>
                  <label
                    className="col-sm-9 col-sm-offset-3"
                    style={{
                      textAlign: "left",
                      padding: 0,
                      fontSize: 10,
                      fontWeight: 400,
                    }}
                  >
                    Delivery Partner
                  </label>
                  <MultiSelect
                    isDisabled={this.state.shipment !== "door_delivery"}
                    placeholder="Please Select Delivery Partner"
                    value={selectedDeliveryPartner}
                    options={deliveryPartners}
                    isMulti={false}
                    isSearchable={true}
                    onChange={this.handleMultiPartner}
                    className="col-sm-10 col-sm-offset-3"
                    style={customStyles}
                  />
                </div>
              )}
              {this.state.shipment === "door_delivery" &&
                this.state.deliveryPartner === "gifteffects_delivery" && (
                  <div className="chkbox-container">
                    <label
                      style={{ width: "24%" }}
                      className="lbl-mobile"
                    ></label>
                    <label
                      style={{
                        marginLeft: 12,
                        fontSize: 12,
                        fontWeight: 350,
                        opacity: 0.5,
                      }}
                    >
                      <a href="https://uat.gifteffects.com/admin/cms/banned" target="__blank">
                        View GiftEffects Banned Items For Delivery
                      </a>
                    </label>
                  </div>
                )}
              {this.state.shipment === "door_delivery" &&
                this.state.deliveryPartner === "gifteffects_delivery" && (
                  <div className="chkbox-container" style={{ display: "flex" }}>
                    <label
                      style={{ width: "24%" }}
                      className="lbl-mobile"
                    ></label>
                    <input
                      type="checkbox"
                      required={true}
                      name="banned_check"
                      checked={this.state.terms}
                      onChange={(event) =>
                        this.onDataChanged("banned_check", event.target.checked)
                      }
                      className="chkbox"
                    />
                    <label
                      style={{
                        marginLeft: 12,
                        fontSize: 12,
                        fontWeight: 350,
                        opacity: 0.5,
                      }}
                    >
                      This product variant doesn't belong to any of the
                      GiftEffects's banned-items{" "}
                      <a href="https://uat.gifteffects.com/admin/cms/banned" target="__blank">
                        More Info
                      </a>{" "}
                    </label>
                  </div>
                )}
              <SubmitControl
                classNames={["submitStyle", "btn"]}
                style={{ marginLeft: 32 }}
                innerWrapperClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(({ productGroups, delivery, counter, vendors }, { match }) => ({
  groupId: match.params.groupId,
  deliveryPartners: delivery.deliveryPartner || [], deliveryModes: delivery.deliveryModes || [],
  currencyISO: counter.currencyISO,
  profile: vendors.data,
  group: (productGroups.data.find(productGroup => productGroup.id === match.params.groupId) || { other_attributes: [] })
}), { listProductGroups, loadDeliveryModesAndPartners, createProduct, loadProductGroupReturnPolicy, uploadImagetoS3 })(AddProductComponent)
