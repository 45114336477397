import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux'

import {START_LOADING, STOP_LOADING, SHOW_TOAST, STOP_TOAST,LOADING_SHIPMENT_SUCCESS, LOADING_DELIVERY_OPTIONS_SUCCESS, LOADING_DELIVERY_OPTIONS_FAILED,LOADING_PRODUCT_GROUP_POLICY_SUCCESS} from "../types/";
import vendors from "./vendors";
import countries from "./countries";
import categories from "./categories";
import auth from './auth';
import productGroups from "./product-groups";
import products from "./products";
import stock from "./stock";
import orders from './orders'
import payments from "./payments";
import notifications from "./notifications";
import pageContent from "./pageContent";
import users from "./users";
import { LOADING_ALL_PRODUCT_GROUPS_SUCCESS, LOADING_ALL_PRODUCTS_SUCCESS } from "../types/products";
const initialState = {
    count : 0, toastIncrement:0, message:'', notificationCount:0, currencyISO:`INR`
}
function counter(state = initialState, action) {
    switch (action.type) {
        case START_LOADING: {
            const count = ++state.count;
            return { ...state, count };
        }
        case STOP_LOADING: {
            const count = Math.max(0, --state.count);
            return { ...state, count, notificationCount:action.notificationCount, currencyISO:action.currencyISO };
        }
        case SHOW_TOAST: {
            const toastIncrement = ++state.toastIncrement;
            return { ...state, toastIncrement, message:action.message };
        }
        case STOP_TOAST: {
            const toastIncrement = Math.max(0, --state.toastIncrement);
            return { ...state, toastIncrement };
        }
    }    
    return state
}

function shipmentReducer(state = {shipment:null}, action){
    if(action.type == LOADING_SHIPMENT_SUCCESS){
        return { ...state, shipment: action.shipment, returnPeriod: action.returnPeriod,deliveryPartner:action.deliveryPartner}
    }
    return state
}

function delivery(state = {deliveryModes:[],deliveryPartner:[]},action){
    if(action.type == LOADING_DELIVERY_OPTIONS_SUCCESS){
        return {...state, deliveryModes: action.deliveryModes,deliveryPartner: action.deliveryPartner}
    }
    return state
}

function loadReturnPolicy(state = {return_policy:null},action){
    if(action.type == LOADING_PRODUCT_GROUP_POLICY_SUCCESS ){
        return {...state, editorState:action.return_policy,return_policy: action.return_policy}
    }
    return state
}

function allProducts(state = {productGroups:[], products:[]}, action) {
    const {type, ...others} = action
    if(action.type === LOADING_ALL_PRODUCT_GROUPS_SUCCESS){
        return {...state, productGroups: action.productGroups}
    }
    if(action.type === LOADING_ALL_PRODUCTS_SUCCESS){
        return { ...state, products: action.products}
    }
    return state
}

export default combineReducers({
    counter,
    pageContent,
    routerReducer,
    vendors,
    countries,
    categories,
    productGroups,
    products,
    stock,
    orders,
    payments,
    notifications,
    shipmentReducer,
    delivery,
    loadReturnPolicy,
    allProducts,
    auth,
    users
});
