import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, InputControl, SubmitControl, SelectControl } from "../../forms";
import { addTeamMember } from "../../../store/actions/vendors";

var buttonStyle = {
    backgroundColor:"transparent",
    borderWidth:2,
    borderColor:"#979797",
    outline:"none",
    paddingLeft:30,
    paddingRight:30,
    paddingTop:8,
    paddingBottom:8,
    borderRadius:4,
    marginRight:10,
},
dataStyle={border:"none",width:"25%",display:"inline-block",marginRight:50},dataStyleFirst={border:"none", width:"5%",display:"inline-block"},
dataStyleLast={border:"none",width:"30%",display:"inline-block",fontSize:14},
inputStyle={width:"100%",border:"none",borderBottom:"1px solid #C7D1DB",outline:"none"},
headerStyle={fontSize:10,fontWeight:400,border:"none",width:"25%",display:"inline-block",marginRight:50}

class CreateMemberComponent extends Component {
    constructor(props){
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    state = {name:'', email:'', role:``}
    handleSubmit(){
       this.props.addTeamMember(this.state)
    }
    render(){
        const {name, email, role} = this.state
        const options = [{ label: `Access Permissions`, value: `` }, { label: `View`, value: `VENDOR_MEMBER` }, { label: `Edit`, value: `VENDOR` }, { label: `Catalog Manager`, value:`VENDOR_DATA_ENTRY`}]
        return (
            <div style={{width:"55%",margin:"auto"}}>
                    <Form onSubmit={this.handleSubmit}>
                    <p style={{marginBottom:100,fontSize:20,textAlign:"center"}}>ADD TEAM MEMBER</p>
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.5%",fontSize:14,fontWeight:400,color:"#595D65"}}>Name</label>
                    </div>
                    <InputControl required value={name} onChange={name => this.setState({name})} />
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.5%",fontSize:14,fontWeight:400,color:"#595D65"}}>Email ID</label>
                    </div>
                    <InputControl required value={email} onChange={email => this.setState({email})} />
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.0%",marginTop:13,fontSize:14,fontWeight:400,color:"#595D65"}}> </label>
                    </div>
                    <SelectControl required={true} options={options} value={role} onChange={role => this.setState({role})} />
                    <SubmitControl style={{ backgroundColor: "#595D65", backgroundImage: "none", width: "80%", marginBottom: 32, marginLeft: 15, marginTop:15, fontWeight: 'regular' }}/>
                    </Form>
                    </div>
        )
    }
}

export default connect(null, {addTeamMember})(CreateMemberComponent)