import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, InputControl, SubmitControl } from "../../forms";
import { updateBankInfo, getVendorProfile } from "../../../store/actions/vendors";
class BankInfoComponent extends Component {
    constructor(props){
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
        const { bank_details = { name:'', account: '', neft:'', accountType:''} } = props;
        this.state = {
            name:bank_details.name,
            account:bank_details.account,
            neft:bank_details.neft,
            accountType:bank_details.accountType
        }
    }
    handleSubmit(){
        this.props.updateBankInfo(this.state)
    }
    componentDidMount(){
        this.props.getVendorProfile()
    }
    componentWillReceiveProps(nextProps){
        const {vendors} = nextProps
        this.setState({
            name:vendors.bank_details ? vendors.bank_details.name : '',
            account:vendors.bank_details ? vendors.bank_details.account : '',
            neft:vendors.bank_details ? vendors.bank_details.neft : '',
            accountType:vendors.bank_details ? vendors.bank_details.accountType : ''
            })
    }
    render(){
        const {name, account, neft, accountType} = this.state
        return( 
            <div style={{width:"55%",margin:"auto"}}>
                <p style={{marginBottom:100,fontSize:20,textAlign:"center"}}>BANK INFORMATION</p>
                <Form onSubmit={this.handleSubmit}>
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.5%",fontSize:14,fontWeight:400,color:"#595D65"}}>Account Holder Name</label>
                    </div>
                    <InputControl value={name} onChange={name => this.setState({name})} />
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.5%",fontSize:14,fontWeight:400,color:"#595D65"}}>Bank Account</label>
                    </div>
                    <InputControl value={account} onChange={account => this.setState({account})} />
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.5%",fontSize:14,fontWeight:400,color:"#595D65"}}>NEFT ID</label>
                    </div>
                    <InputControl value={neft} onChange={neft => this.setState({neft})} />
                    <div className="col-sm-12">
                        <label className="col-sm-2"></label>
                        <label className="col-sm-6" style={{paddingLeft:"6.5%",fontSize:14,fontWeight:400,color:"#595D65"}}>Bank Account Type</label>
                    </div>
                    <InputControl value={accountType} onChange={accountType => this.setState({accountType})} />
                    <SubmitControl style={{ backgroundColor: "#595D65", backgroundImage: "none", width: "80%", marginBottom: 32, marginLeft: 15, marginTop:15, fontWeight: 'regular' }}/>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = ({vendors}) => ({vendors: vendors.data})
export default connect(mapStateToProps, {getVendorProfile, updateBankInfo})(BankInfoComponent)