import React, { Component } from "react";
import { connect } from "react-redux";
import { SearchProductGroups } from "../../../store/actions/product-groups";
import { listProductGroupsSearch } from "../../../store/actions/product-groups";
import { NavLink } from "react-router-dom";
import PaginationLinks from "../../pagination";
import moment from 'moment';

var buttonStyle = {
  backgroundColor: "transparent",
  borderWidth: 2,
  borderColor: "#979797",
  outline: "none",
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 4,
  marginRight: 10,
  color: '#595D65',
  marginBottom: 30
}, headStyle = {
  border: "none"
}

const customStyle = {
  headerRadio: {
    marginLeft: "20px",
  },
  searchDiv: {
    borderBottom: "1px solid #979797",
    width: "350px",
    paddingBottom: 10,
  },
  searchBox: {
    border: "none",
    width: "90%",
    marginLeft: 5,
    outline: "none",
  },
  firstheaderTable: {
    fontSize: 13,
    fontWeight: 500,
    paddingLeft: 32,
    width: 400,
  },
  headerTable: {
    fontSize: 13,
    fontWeight: 500,
    width: 200,
    textAlign: "center",
  },
  txtcenter: {
    textAlign: "center",
  },
  buttonStyle: {
    backgroundColor: "transparent",
    borderWidth: 2,
    borderColor: "#979797",
    outline: "none",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    marginRight: 10,
    color: "#595D65",
  },
};
var ss=''
class ProductGroupsListingComponent extends Component {
  constructor(props) {
    super(props)
    this.onNavigateToPage = this.onNavigateToPage.bind(this)
  }
  state = {
    sortingupdate: false,
    // productGroupSearch: "",
    windowLocation: window.location.pathname,
    page: 1,
    productGroups: [],
    clicked: '',
    // go:false

  };


  onSearched = (key, value) => {
    // this.setState({ productGroupSearch: value });
    ss = value
  };

  componentDidMount() {
    // this.props.SearchProductGroups(
    //   null,
    //   { name: this.state.productGroupSearch },
    // );
    this.props.dispatch(SearchProductGroups(null,
      { name: ss })//this.state.productGroupSearch
    )

  }

  onSearchClicked = () => {
    // console.log("inside------")
    this.props.dispatch(SearchProductGroups(null,
      { name: ss })//this.state.productGroupSearch
    )

    // this.props.SearchProductGroups(
    //   null,
    //   { name: this.state.productGroupSearch },
    // );
  };

  componentWillReceiveProps(nextProps) {
    // console.log("inside component will receive props------")
    // console.log(nextProps, "------")
    const { productGroups1 } = nextProps
    // console.log(this.props.productGroups1,"productGroups1")
    this.setState({ productGroups: productGroups1 })
  }
  onNavigateToPage(page) {

    this.props.SearchProductGroups(
      null,
      // { name: this.state.productGroupSearch },
      { name: ss },
      page
    );
  }
  render() {
    const { clicked, productGroups, sortingupdate } = this.state
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", height: "40px" }}>
            <div style={customStyle.searchDiv}>
              <img src={require("../../../images/search.png")} />
              <input
                style={customStyle.searchBox}
                // value={ss}//this.state.productGroupSearch 
                type="text"
                name="productGroupSearch"
                onChange={(event) => 
                  this.onSearched("productGroupSearch", event.target.value)
                }
                placeholder="Search Product Group Name Here"
              />
            </div>
            <input
              className="btn btn-success"
              type="button"
              style={{ marginLeft: "25px" }}
              value="Search"
              onClick={this.onSearchClicked}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <NavLink to="/product-groups/new"><button onClick={this.onAddAttrClicked} style={buttonStyle}>+ Add Product Group</button></NavLink>
          </div>
        </div>
        {productGroups && productGroups.length ? <div>
          <table className="table table-striped table-responsive" style={{ tableLayout: 'fixed' }} >
            <thead style={{ background: "#F0F0F0" }}>
              <tr>
                <th style={{ border: "none", width: "25%" }}>Product Group Name <button
                  onClick={(e) => {
                    this.setState((prevState) => ({
                      sortingupdate: !prevState.sortingupdate,
                      clicked: "name"
                    }));
                    this.componentDidMount();
                  }}
                  style={{ border: "none" }}
                ><i className="fas fa-sort" /></button></th>
                <th style={{ border: "none", width: "50%" }}>Description</th>
                <th style={{ border: "none", width: "15%" }}>Created Date <button onClick={() => {
                  this.setState((prevState) => ({
                    sortingupdate: !prevState.sortingupdate,
                    clicked: "createdAt"

                  }));
                  this.componentDidMount();
                }} style={{ border: "none" }}><i className="fas fa-sort" /></button></th>
                <th style={{ border: "none", width: "15%" }}>Updated Date <button onClick={() => {
                  this.setState((prevState) => ({
                    sortingupdate: !prevState.sortingupdate,
                    clicked: "updatedAt"
                  }));
                  this.componentDidMount();
                }} style={{ border: "none" }}><i className="fas fa-sort" /></button></th>
                <th style={{ border: "none", width: "20%" }} colSpan="2">Actions</th>
              </tr>
            </thead>
            {sortingupdate === true ? (
              <tbody>
                {productGroups.sort((a, b) => (a[clicked] > b[clicked] ? 1 : -1))
                  .map(productGroup => (productGroup && <tr key={productGroup.id}>
                    <td><NavLink to={{ pathname: `/product-groups/${productGroup.id}/edit` }} style={{ textDecoration: "none", wordWrap: "break-word" }}>{productGroup.name}</NavLink></td>
                    {/* <td style={{ wordWrap: 'break-word' }}>{productGroup.description || `Not Specified`}</td> */}
                    <td style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{__html: productGroup.description || `Not Specified`}}></td><td style={{ wordWrap: 'break-word' }}>{productGroup.description || `Not Specified`}</td>
                    <td >{moment(productGroup.createdAt).format('MM/DD/YYYY hh:mm:ss') || `---`}</td>
                    <td >{moment(productGroup.updatedAt).format('MM/DD/YYYY hh:mm:ss') || `---`}</td>
                    <td><NavLink to={{ pathname: `/product-groups/${productGroup.id}/add-products` }} style={{ textDecoration: "none" }}>Add Product</NavLink></td>
                    <td><NavLink to={{ pathname: `/product-groups/${productGroup.id}/products` }} style={{ textDecoration: "none" }}>Products</NavLink></td>
                  </tr>))}
              </tbody>
            ) : (
              <tbody>
                {productGroups.sort((a, b) => (a[clicked] < b[clicked] ? 1 : -1))
                  .map(productGroup => (productGroup && <tr key={productGroup.id}>
                    <td><NavLink to={{ pathname: `/product-groups/${productGroup.id}/edit` }} style={{ textDecoration: "none", wordWrap: "break-word" }}>{productGroup.name}</NavLink></td>
                    {/* <td style={{ wordWrap: 'break-word' }}>{productGroup.description || `Not Specified`}</td> */}
                    <td style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{__html: productGroup.description || `Not Specified`}}></td>
                    <td >{moment(productGroup.createdAt).format('MM/DD/YYYY hh:mm:ss') || `---`}</td>
                    <td >{moment(productGroup.updatedAt).format('MM/DD/YYYY hh:mm:ss') || `---`}</td>
                    <td><NavLink to={{ pathname: `/product-groups/${productGroup.id}/add-products` }} style={{ textDecoration: "none" }}>Add Product</NavLink></td>
                    <td><NavLink to={{ pathname: `/product-groups/${productGroup.id}/products` }} style={{ textDecoration: "none" }}>Products</NavLink></td>
                  </tr>))}
              </tbody>
            )}
          </table>
          <PaginationLinks apiUrl={this.state.windowLocation} totalPages={this.props.totalPages} currentPage={this.props.page} onNavigateToPage={this.onNavigateToPage} />
        </div> : <div style={{ textAlign: "center" }}>
          <p style={{ fontWeight: "bold" }}>No Product Group Available</p></div>
        }
      </div>
    )
  }
}
// const mapStateToProps = ({ productGroups }) => ({ productGroups1: productGroups.data, page: productGroups.page, totalPages: productGroups.totalPages })
// export default connect(mapStateToProps, { SearchProductGroups })(ProductGroupsListingComponent)
const mapStateToProps = ({ productGroups }) => ({
  // productGroups1: this.state.productGroups[0].id == productGroups.data[0].id ? this.state.productGroups : productGroups.data,
  productGroups1:productGroups.data,
  page: productGroups.page,
  totalPages: productGroups.totalPages
})
export default connect(mapStateToProps)(ProductGroupsListingComponent)