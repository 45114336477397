import React, { Component } from "react";

export function Form({
    onSubmit: handleSubmit,
    classNames = ["form-horizontal"], 
    children,
    ...otherAttrs
}) {
    const attrs = Object.assign({
        className : classNames.join(" "),
        onSubmit : event => {
            event.preventDefault();
            (handleSubmit|| (_=>{})).call(null);
        }
    });
    return <form {...attrs}>
        {children}
    </form>
}