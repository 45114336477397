import React, { Component } from "react";
import { connect } from "react-redux";
import { getNotifications } from "../../../store/actions/vendors";
import PaginationLinks from "../../pagination";
import { formatDate } from "../../../utils";

class NotificationComponent extends Component {
    componentDidMount() {
        this.props.getNotifications()
    }
    onClickNotification(orderId){
        window.location = `/orders/${orderId}/detail`
    }
    render() {
        return (
            this.props.notifications && this.props.notifications.length ? <div>
            <h3><b>Notifications</b></h3>
            {this.props.notifications.map(notification =>
                <div style={{backgroundColor:notification.read ? `#fcf6f6` : `#cbc5c5`, cursor:'pointer'}} onClick={event => this.onClickNotification(notification.order.id)}>
                    <p style={{paddingLeft:'10px'}}>{notification.order.user.name} has placed {notification.order.product.name}</p>
                    <p style={{paddingLeft:'20px', opacity:0.5}}><i>{formatDate(notification.order.createdAt)}</i></p>
                </div>
            )}
            <PaginationLinks apiUrl={window.location.pathname} currentPage={this.props.page} totalPages={this.props.totalPages} />
        </div> : <p style={{textAlign:'center'}}>You haven't received any notifications yet</p>)
    }
}
const mapStateToProps = ({ notifications }) => ({ notifications: notifications.data, page:notifications.page, totalPages:notifications.totalPages })
export default connect(mapStateToProps, { getNotifications })(NotificationComponent)