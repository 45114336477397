// configureStore.js

import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import createHistory from 'history/createBrowserHistory'

import thunk from 'redux-thunk';
import apiMiddleware from './api.middleware'
import { routerMiddleware } from 'react-router-redux'

import rootReducer from './reducers/'
import reduxLogger from "redux-logger"
export const history = createHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist : ['auth']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(thunk, apiMiddleware, routerMiddleware(history)));//reduxLogger
export const persistor = persistStore(store);
export default store;