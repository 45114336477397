import { VENDOR_REGISTRATION_SUCCESS, VENDOR_REGISTRATION_FAILED, REFRESH_STATUS_IN_PROGRESS, REFRESH_STATUS_FAILED, REFRESH_STATUS_SUCCESS, VENDOR_LOGIN_SUCCESS, BANKING_INFO_SUCCESS, LOADING_PROFILE_SUCCESS, LOADING_MEMBERS_SUCCESS, ADD_MEMBER_SUCCESS, UPDATE_PROFILE_SUCCESS, LOADING_HOMEPAGE_SUCCESS, UPDATE_ORDER_FROM_HOMEPAGE_SUCCESS, SWITCH_ACCOUNT_SUCCESS } from '../types'
import { GET_SUPPORT_NUMBER_SUCCESS } from '../types/orders';

export default function vendors(state = {data :{}, status : REFRESH_STATUS_SUCCESS}, action) {
    // if (action.type === VENDOR_REGISTRATION_SUCCESS) {
    //     return { data: {}, status:REFRESH_STATUS_SUCCESS};
    // }
    // if(action.type == VENDOR_LOGIN_SUCCESS){
    //     return {...state, data: action}
    // }
    if(action.type == BANKING_INFO_SUCCESS){
        return {...state, data:action}
    }
    if(action.type == LOADING_PROFILE_SUCCESS){
        return {...state, data:action}
    }
    if(action.type == LOADING_MEMBERS_SUCCESS){
        return {...state, data:{teamMembers:action.teamMembers}}
    }
    if(action.type == ADD_MEMBER_SUCCESS){
        return {...state, data:{teamMembers:action.teamMembers}}
    }
    if(action.type == UPDATE_PROFILE_SUCCESS){
        return {...state, data:action}
    }
    if(action.type == LOADING_HOMEPAGE_SUCCESS){
        return {...state, data:action}
    }
    if(action.type == SWITCH_ACCOUNT_SUCCESS){
        return {...state, data:action.vendorHome}
    }
    if(action.type == UPDATE_ORDER_FROM_HOMEPAGE_SUCCESS){
        // state.data.newOrders.filter(o => o.id != action.id)
        return {...state.data, newOrders:state.data.newOrders.filter(o => o.id != action.id)}
    }
    if (action.type === GET_SUPPORT_NUMBER_SUCCESS){
        return {...state.data, supportNumber: action.support_number}
    }
    return state;
}
