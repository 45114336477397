import { VENDOR_LOGIN_SUCCESS, VENDOR_LOGOUT, SWITCH_ACCOUNT_SUCCESS } from "../types";

export default function auth(state = { token : null, name:null, email:null, vendors:[], vendor_details:[], account_role:'', currentVendor:''}, action) {
    const {type, vendorAccount, account_role} = action
    if(type == SWITCH_ACCOUNT_SUCCESS){
        return {...state, currentVendor:vendorAccount, account_role};
    }
    if (action.type === VENDOR_LOGIN_SUCCESS) {
        const { access_token : token,  name, email, vendors, vendor_details, account_role } = action;
        return {...state, token, name, email, vendors, vendor_details, currentVendor:vendors[0], account_role};
    } else if (action.type === VENDOR_LOGOUT) {
        return { token : null, name:null, email:null, vendors:[], currentVendor:''};
    }
    return state;
}
