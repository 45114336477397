export const CREATE_PRODUCT_PROGRESS = "CREATE_PRODUCT_PROGRESS";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

export const LOADING_PRODUCT_PROGRESS = "CREATE_PRODUCT_PROGRESS";
export const LOADING_PRODUCT_SUCCESS = "LOADING_PRODUCT_SUCCESS";
export const LOADING_PRODUCT_FAILED = "LOADING_PRODUCT_FAILED";

export const UPDATE_PRODUCT_PROGRESS = "UPDATE_PRODUCT_PROGRESS";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED";

export const ARCHIVE_PRODUCT_PROGRESS = "ARCHIVE_PRODUCT_PROGRESS";
export const ARCHIVE_PRODUCT_SUCCESS = "ARCHIVE_PRODUCT_SUCCESS";
export const ARCHIVE_PRODUCT_FAILED = "ARCHIVE_PRODUCT_FAILED";

export const UNARCHIVE_PRODUCT_PROGRESS = "UNARCHIVE_PRODUCT_PROGRESS";
export const UNARCHIVE_PRODUCT_SUCCESS = "UNARCHIVE_PRODUCT_SUCCESS";
export const UNARCHIVE_PRODUCT_FAILED = "UNARCHIVE_PRODUCT_FAILED";

export const LOADING_PRODUCT_DETAIL_PROGRESS = "LOADING_PRODUCT_DETAIL_PROGRESS";
export const LOADING_PRODUCT_DETAIL_SUCCESS = "LOADING_PRODUCT_DETAIL_SUCCESS";
export const LOADING_PRODUCT_DETAIL_FAILED = "LOADING_PRODUCT_DETAIL_FAILED";

export const LOADING_ALL_PRODUCT_GROUPS = "LOADING_ALL_PRODUCT_GROUPS"
export const LOADING_ALL_PRODUCT_GROUPS_SUCCESS = "LOADING_ALL_PRODUCT_GROUPS_SUCCESS"
export const LOADING_ALL_PRODUCT_GROUPS_FAILED = "LOADING_ALL_PRODUCT_GROUPS_FAILED"

export const LOADING_ALL_PRODUCTS = "LOADING_ALL_PRODUCTS"
export const LOADING_ALL_PRODUCTS_SUCCESS = "LOADING_ALL_PRODUCTS_SUCCESS"
export const LOADING_ALL_PRODUCTS_FAILED = "LOADING_ALL_PRODUCTS_FAILED"

export const UPDATING_ALL_PRODUCTS = "UPDATING_ALL_PRODUCTS"
export const UPDATING_ALL_PRODUCTS_SUCCESS = "UPDATING_ALL_PRODUCTS_SUCCESS"
export const UPDATING_ALL_PRODUCTS_FAILED = "UPDATING_ALL_PRODUCTS_FAILED"
