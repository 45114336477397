import { LOADING_PAYMENTS_SUCCESS, LOADING_PENDING_PAYMENTS_SUCCESS } from "../types";

export default function payments(state = {data:[], pendingPayments:[], page : 1, totalPages : 1}, action){
    const {type, payments, page, totalPages} = action
    if(type === LOADING_PAYMENTS_SUCCESS){
        return {...state, data:payments, page, totalPages}
    }
    if(type === LOADING_PENDING_PAYMENTS_SUCCESS){
        return {...state, pendingPayments:payments, page, totalPages}
    }
    return state
}