import React, { Component } from "react";
import { connect } from "react-redux";
import { getSuccessPageText } from "../../store/actions/vendors";
import ReactMarkdown from 'react-markdown'
class RegisterSuccessComponent extends Component {
  componentDidMount() {
    this.props.getSuccessPageText()
  }
  render() {
    return (
      <div style={{ textAlign: "center", marginHorizontal: 15 }} onLoad={window.scrollTo(10,10)}>
      <ReactMarkdown source={this.props.pageContent.content}></ReactMarkdown>
      </div>
    );
  }
}
const mapStateToProps = ({pageContent}) => ({pageContent:pageContent && pageContent.data || {content:''}})
export default connect(mapStateToProps, {getSuccessPageText})(RegisterSuccessComponent)