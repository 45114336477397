// eslint-disable-next-line
import React, { Component } from "react";

export default function Header() {
    return <footer className="footer">
    <div className="container">
      <div id="footer">
        {/* <p className="col-xs-12 copyRights">Copyright © {new Date().getFullYear()} WebileApps. All rights reserved.</p> */}
      </div>
    </div>
    </footer>
}