import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, InputControl, SubmitControl } from "../forms";
import { ForgotPassword } from "../../store/actions/vendors";
var divStyle = {
    width:"50%",
    border:"1px solid #A2A2A2",
    borderRadius:8,
    margin:"auto"
  },headingStyle={fontSize:20,fontWeight:400,marginTop:40,marginBottom:40,textAlign:"center"}
class ForgotPasswordComponent extends Component {
    constructor(){
        super()
        this.onSubmitted = this.onSubmitted.bind(this)
    }
    state = {
        email:''
    }
    onSubmitted() {
        this.props.ForgotPassword(this.state)
    }
    render(){
        const {email} = this.state
        return (
            <div style={divStyle}>
                <p style={headingStyle}>Forgot Password</p>
                <Form onSubmit={this.onSubmitted}>
                    <InputControl type="email" required={true} value={email}  placeholder="Enter your email" onChange={email => this.setState({email})} style={{marginTop:60}}/>
                    <SubmitControl title="Send Forgot Password Link" style={{backgroundColor:"#595D65",backgroundImage:"none",width:"86%",marginBottom:32,marginTop:15}}/>
                </Form>
            </div>
        )
    }
}

export default connect(null, {ForgotPassword})(ForgotPasswordComponent)