import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Form,
  SelectControl,
  InputControl,
  SubmitControl,
  TextEditor,
} from "../forms";
import { SubmitControll } from "../forms/submit1";
import {
  loadCountries,
  loadDeliveryModesAndPartners,
  getVendorProfile,
  updateVendorProfile,
} from "../../store/actions/vendors";
import { loadCategories } from "../../store/actions/product-groups";
import { listProductGroups } from "../../store/actions/product-groups";
import { listProducts } from "../../store/actions/product";
import { uploadImagetoS3 } from "../../store/actions/product-groups";
import MultiSelect from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "./profile.css";
import { BASE_URL } from "../../store/api.middleware";

var textAreaStyle = {
  resize: "none",
  border: "1px solid #c7d1db",
  boxShadow: "none",
  borderRadius: 0,
  outline: "none",
  fontSize: 14,
  padding: 0,
  fontWeight: 350,
  color: "#595D65",
  WebkitInputPlaceholder: "#595D65",
};
var multiSelect = {
  marginLeft: "6%",
  borderWidth: 0,
};

var multiSelect1 = {
  marginLeft: "6%",
  borderWidth: 0,
};

var multiSelect2 = {
  marginLeft: "6%",
  borderWidth: 0,
};

var customStyles = {
  borderWidth: 0,
};

class ProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    const {
      profile = {
        countries: [],
        company: "",
        address: "",
        business_contact: "",
        state: "",
        city: "",
        postal_code: "",
        area_of_business: "",
        website: "",
        access_token: "",
        api_key: "",
        shopify_storename: "",
        shopify_version: "",
        timepick1: "",
        timepick2: "",
        time_sync: "",
        time_sync_date: "",
        time_sync_status: "",
        timeshow: false,
        shoifyshow: false,
        shipment: "",
        deliveryPartner: "",
        gift_category: "",
        subcategory: [],
        productGroup: "",
        product: [],
        filterFields: false,
        shopifydatas: {
          categoryResult: [],
          productGroupResult: [],
          productResult: [],
          subcategoryResult: [],
        },
        user: {
          name: "",
          email: "",
          vendor_country: "",
          phone_number: "",
          profile_pic: "",
        },
      },
    } = props;
    console.log(props, "-=-=-=-profile=-profile=-=profile=-profile=-profile");
    this.state = {
      editorState: EditorState.createEmpty(),
      name: profile.user ? profile.user.name : "",
      email: profile.user ? profile.user.email : "",
      vendor_country:
        profile.vendor_country || profile.user
          ? profile.user.vendor_country
          : "",
      profile_pic: profile.user ? profile.user.profile_pic : "",
      company: profile.company,
      phone_number: profile.user ? profile.user.phone_number : "",
      business_contact: profile.business_contact,
      returnPeriod: profile.returnPeriod,
      state: profile.state,
      city: profile.city,
      postal_code: profile.postal_code,
      area_of_business: profile.area_of_business,
      address: profile.address,
      countries: profile.countries,
      website: profile.website,
      access_token: profile.access_token,
      api_key: profile.api_key,
      shopify_storename: profile.shopify_storename,
      shopify_version: profile.shopify_version,
      timepick1: profile.timepick1,
      timepick2: profile.timepick2,
      time_sync: profile.time_sync,
      time_sync_date: profile.updatedAt,
      time_sync_status: profile.time_sync_status,
      timeshow: profile.timepick2?false:true,
      shoifyshow: false,
      // is_sync : profile.is_sync,
      shipment: profile.shipment,
      deliveryPartner: profile.deliveryPartner || "",
      gift_category: profile.gift_category,
      subcategory: profile.subcategory,
      productGroup: profile.productGroup,
      product: profile.product,
      shopifydatas: {
        categoryResult: [],
        productGroupResult: [],
        productResult: [],
        subcategoryResult: [],
      },
      filterFields: profile.filterFields,
      applyForExistingAll:true,
      applyForExisting:false
    };
  }
  handleMultiSelect = (option) => {
    this.setState({ vendor_country: option.value });
  };
  handleMultiShipment = (option) => {
    this.setState({ shipment: option.value });
  };

  handleMultiPartner = (option) => {
    this.setState({ deliveryPartner: option.value });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };
  onNumberFieldChange(key, val) {
    if (Number(val) >= 0) {
      this.setState({ [key]: val });
    }
  }
  onDataChanged = (key, value) => {
    if ([`applyForExistingAll`, `applyForExisting`].includes(key)) {
      this.setState({
        [key]: value,
        [key === `applyForExisting`
          ? `applyForExistingAll`
          : `applyForExisting`]: false,
      });
    } else {
      this.setState({ [key]: value });
    }
  };

  shopifyUser = () => {
    this.setState({
      shoifyshow: !this.state.shoifyshow,
    });

    if (this.state.shoifyshow) {
      this.setState({
        api_key: "",
        access_token: "",
        shopify_storename: "",
        shopify_version: "",
      });
    }
  };

  timeuser = () => {
    this.setState({
      timeshow: !this.state.timeshow,
    });
    if (this.state.timeshow) {
      this.setState({ timepick2: "" });
    }
  };

  filtershopify() {
    axios
      .get(`${BASE_URL}/products/filterFields`, {
        params: {
          shopify_storename: this.state.shopify_storename,
          api_key: this.state.api_key,
          access_token: this.state.access_token,
          shopify_version: this.state.shopify_version,
        },
      })
      .then((res) => {
        this.setState({
          shopifydatas: {
            ...this.state.shopifydatas,
            categoryResult: res.data.data.categoryResult,
            subcategoryResult: res.data.data.subcategoryResult,
            productGroupResult: res.data.data.productGroupResult,
            productResult: res.data.data.productResult,
          },
        });
      })
      .catch((err) => console.log(err));
  }

  filterChange = () => {
    var resu = axios
      .get(
        `${BASE_URL}/vendors/validateapi?api_key=` +
          this.state.api_key +
          "&access_token=" +
          this.state.access_token +
          "&shopify_storename=" +
          this.state.shopify_storename +
          "&shopify_version=" +
          this.state.shopify_version
      )
      .then((rep) => {
        if (rep.data.Success) {
          this.setState({
            filterFields: true,
          });
          this.filtershopify();
        } else {
          alert("please check your Shopify Crendentails");
        }
      });
  };

  time1 = (e) => {
    this.setState({ timepick1: e.target.value });
  };

  time2 = (e) => {
    this.setState({ timepick2: e.target.value });
  };

  handleSubmit() {
    let website = this.state.website;
    if (website) {
      if (!/http/i.test(website)) {
        website = `http://${website}`;
      }
      var regexTest =
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
          website
        ) && !/@/.test(website);
      if (website && !regexTest) {
        alert("Please enter valid website");
        return;
      }

      if (!this.state.timeshow) {
        this.setState({ timepick2: "" });
      }
    }
    // if (!this.state.timeshow) {
    //   this.setState({ timepick2: "" }, () => {
    //     const {
    //       editorState,
    //       newOrders,
    //       admin_comments,
    //       team,
    //       contacts,
    //       celebrations,
    //       frequently_invited,
    //       authData,
    //       ...others
    //     } = this.state;
    //     this.props.updateVendorProfile({
    //       ...others,
    //       return_policy: JSON.stringify(
    //         convertToRaw(this.state.editorState.getCurrentContent())
    //       ),
    //     });
    //   });
    // } else {
    const {
      editorState,
      newOrders,
      admin_comments,
      team,
      contacts,
      celebrations,
      frequently_invited,
      authData,
      ...others
    } = this.state;
    this.props.updateVendorProfile({
      ...others,
      return_policy: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
    });
    // }
  }

  handleFile(e) {
    let x = Promise.all([
      this.props.uploadImagetoS3(
        e.target.files[0].name,
        e.target.files[0].type,
        e.target.files[0]
      ),
    ]);
    console.log(e);
    x.then((res) => {
      this.setState({ profile_pic: res[0] });
    }).catch((error) => {
      console.error(error);
    });
  }
  async componentDidMount() {
    this.props.loadCountries();
    this.props.loadDeliveryModesAndPartners();
    this.props.loadCategories();
    this.props.listProductGroups();
    this.props.listProducts();
    // this.filtershopify();
    const {
      body: { return_policy },
    } = await this.props.getVendorProfile();

    return_policy &&
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(return_policy))
        ),
      });
    if (
      this.state.access_token &&
      this.state.api_key &&
      this.state.shopify_storename &&
      this.state.shopify_version
    ) {
      this.setState({
        shoifyshow: true,
      });
      this.filterChange();
      this.setState({ filterFields: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    const { profile } = nextProps;
    this.setState({ ...profile, ...profile.user });
  }
  render() {
    const options = this.props.countries.map((country) => ({
      label: country,
      value: country.replace(/ /g, "_").toUpperCase(),
    })) || [{ label: "Loading...", value: "" }];
    const deliveryModes = this.props.deliveryModes.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const deliveryPartner = this.props.deliveryPartner.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];

    // const categoryOption = this.state.shopifydatas.categoryResult
    // // this.props.categories
    //   // .reduce((prev, category) => [...prev, category.categories], [])
    //   .map((doc) => ({
    //     label: doc.name,
    //     value: doc.id,
    //   }))

    // const categoryOption = this.state.shopifydatas.categoryResult.map((doc) => ({
    //   label: doc.title,
    //   value: doc.id,
    // })) || [{ label: "Loading...", value: "" }];

    // const subcatoryOptions = this.state.shopifydatas.subcategoryResult.map(
    //   (Subcategory) => ({
    //     label: Subcategory.title,
    //     value: Subcategory.id,
    //   })
    // ) || [{ label: "Loading...", value: "" }];

    // const productGroupOption = this.props.productGroups.map((productGroup) => ({
    //   label: productGroup.name,
    //   value: productGroup.name,
    // }));

    // const productOption = this.props.products.map((product) => ({
    //   label: product.name,
    //   value: product.id,
    // }));

    options.shift();
    // options.splice(0,0,{ label: "Please select a country", value: "" });
    const selectedCountry = options.find(
      (option) => option.value === this.state.vendor_country
    );
    const selectedDeliveryMode = deliveryModes.find(
      (option) => option.value === this.state.shipment
    );
    const selectedDeliveryPartner = deliveryPartner.find(
      (option) => option.value === this.state.deliveryPartner
    );

    // const selectedCategoryOption = categoryOption.find(
    //   (option) => option.value === this.state.categories
    // );

    // const selectedSubcatoryOptions = subcatoryOptions.find(
    //   (option) => option.value === this.state.subcategory
    // );

    // const selectedProductGroupOption = productGroupOption.find(
    //   (option) => option.value === this.state.productGroup
    // );

    // const selectedProductOption = productOption.find(
    //   (option) => option.value === this.state.product
    // );

    const {
      returnPeriod,
      name,
      company,
      email,
      countries,
      vendor_country,
      password,
      phone_number,
      business_contact,
      state,
      city,
      postal_code,
      area_of_business,
      address,
      website,
      access_token,
      api_key,
      shopify_storename,
      shopify_version,
      timepick1,
      timepick2,
      timeshow,
      shoifyshow,
      gift_category,
      subcategory,
      productGroup,
      product,
      filterFields,
    } = this.state;
    return (
      <div style={{ width: "60%", margin: "auto" }}>
        <p style={{ textAlign: "center", fontSize: 20, fontWeight: 400 }}>
          Profile
        </p>
        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 55 }}>
          <input
            type="file"
            id="img"
            style={{ display: "none" }}
            onChange={(event) => this.handleFile(event)}
          />
          <span style={{ position: "relative" }}>
            {this.state.profile_pic ? (
              <label
                style={{ display: "inline-block", cursor: "pointer" }}
                htmlFor="img"
              >
                <img
                  src={this.state.profile_pic}
                  style={{ width: 110, height: 110, borderRadius: "50%" }}
                />
              </label>
            ) : (
              <label
                htmlFor="img"
                style={{
                  display: "inline-block",
                  width: 110,
                  height: 110,
                  borderRadius: "50%",
                  backgroundColor: "#D8D8D8",
                  paddingTop: 45,
                  paddingLeft: 5,
                  cursor: "pointer",
                }}
              >
                Upload Image
              </label>
            )}
            <label htmlFor="img">
              <img
                src={require("../../images/camera.png")}
                style={{
                  width: 23,
                  height: 20,
                  position: "absolute",
                  top: 40,
                  right: 10,
                  cursor: "pointer",
                }}
              />
            </label>
          </span>
        </div>
        <Form
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          onSubmit={this.handleSubmit}
        >
          <div style={{ width: "100%" }}>
            <InputControl
              label="Business Name"
              value={company}
              onChange={(company) => this.setState({ company })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Your Full Name"
              value={name}
              onChange={(name) => this.setState({ name })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Enter Your Email"
              value={email}
              onChange={(email) => this.setState({ email })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Your Contact Number"
              value={phone_number}
              onChange={(phone_number) => this.setState({ phone_number })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <div className="form-group">
              <textarea
                rows={5}
                cols={40}
                style={textAreaStyle}
                value={address}
                placeholder="Mention Address"
                className="col-sm-6 col-sm-offset-3"
                onChange={(event) =>
                  this.setState({ address: event.target.value })
                }
              ></textarea>
            </div>
            <div className="form-group multi-dropdown" style={multiSelect}>
              <MultiSelect
                placeholder="Please Select Country"
                value={selectedCountry}
                options={options}
                isMulti={false}
                isSearchable={true}
                onChange={this.handleMultiSelect}
                className="col-sm-7 col-sm-offset-2"
                style={customStyles}
              />
            </div>
            {/* <SelectControl options={options} label='Country' value={vendor_country} onChange={vendor_country => this.setState({vendor_country:vendor_country.replace(/ /g, '_').toUpperCase()})} 
                    classNames={["form-control"]}
                    innerWrapperClassNames={["col-sm-offset-3","col-sm-6"]}
                    labelClassNames={[ "col-sm-offset-3", "col-sm-9"]}
                    /> */}
            <InputControl
              label="State"
              value={state}
              onChange={(state) => this.setState({ state })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="City"
              value={city}
              onChange={(city) => this.setState({ city })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Postal code/Zip code"
              value={postal_code}
              onChange={(postal_code) => this.setState({ postal_code })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Business Contact Number"
              value={business_contact}
              onChange={(business_contact) =>
                this.setState({ business_contact })
              }
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Area of Business"
              value={area_of_business}
              onChange={(area_of_business) =>
                this.setState({ area_of_business })
              }
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <InputControl
              label="Website"
              value={website}
              onChange={(website) => this.setState({ website })}
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <div className="shopify_options">
              <label
                style={{
                  // marginLeft: 215,
                  fontSize: 15,
                  fontWeight: 450,
                  opacity: 0.5,
                }}
                className="shopify_yon"
                id="shop_pro_lab"
              >
                Are you Shopify User{" "}
              </label>

              <button
                type="button"
                className="shopifycls"
                onClick={this.shopifyUser}
                id="shopifyUser_btn_pro"
              >
                {shoifyshow ? "No" : "Yes"}
              </button>
            </div>

            {shoifyshow && (
              <div>
                <InputControl
                  required={false}
                  value={api_key}
                  label="Shopify API Key"
                  onChange={(api_key) => this.setState({ api_key })}
                  id="profile_api_key"
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                />
                <InputControl
                  required={false}
                  value={access_token}
                  label="Shopify Access Token"
                  onChange={(access_token) => this.setState({ access_token })}
                  id="profile_access_token"
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                />
                <InputControl
                  required={false}
                  value={shopify_storename}
                  label="Shopify Store Name"
                  onChange={(shopify_storename) =>
                    this.setState({ shopify_storename })
                  }
                  id="profile_ashopify_storename"
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                />
                <InputControl
                  required={false}
                  value={shopify_version}
                  label="Shopify Webhook Versions"
                  onChange={(shopify_version) =>
                    this.setState({ shopify_version })
                  }
                  id="profile_ashopify_version"
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                />
                <div>
                  <div
                    className="form-group tim_div_input"
                    style={multiSelect1}
                  >
                    <label
                      style={{
                        textAlign: "left",
                        padding: 0,
                        fontSize: 10,
                        fontWeight: 400,
                      }}
                    >
                      Select Time - Please ensure it's in UTC Time
                    </label>
                    <div>
                      <input
                        label="Choose Time"
                        id="time1_pro"
                        type="time"
                        onChange={(e) => this.time1(e)}
                        value={timepick1}
                        className="tim2_cls"
                      />
                      <label
                        style={{
                          marginLeft: 12,
                          marginTop: 10,
                          fontSize: 12,
                          fontWeight: 350,
                        }}
                        className="lab2_cls"
                      >
                        <button
                          type="button"
                          id="timeuser_btn"
                          onClick={this.timeuser}
                        >
                          {timeshow ? "-" : "+"}
                        </button>
                      </label>

                      {timeshow || timepick2 ? (
                        <input
                          label="Choose Time"
                          id="time2_pro"
                          type="time"
                          onChange={(e) => this.time2(e)}
                          value={timepick2}
                          className="tim2_cls"
                        />
                      ):""}
                    </div>
                  </div>
                  <div className="sync_main">
                    <div className="sync_sub1">
                      <span>Last Sync Date & Time</span>
                      <span className="spa1">
                        {this.state.time_sync_date} & {this.state.time_sync}
                      </span>
                    </div>
                    <div className="sync_sub2">
                      <span>Product Count</span>
                      <span className="spa1">{this.props.products.filter((e)=>e.isActive).length}</span>
                    </div>
                    <div className="sync_sub2">
                      <span>Sync Status</span>
                      <span className="spa1">
                        {this.state.time_sync_status == "Waiting" ? (
                          <span>{this.state.time_sync_status}</span>
                        ) : this.state.time_sync_status == "Synced" ? (
                          <span id="success">Synced</span>
                        ) : (
                          <span id="fail">Not Synced</span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div>
                    <SubmitControll
                      style={{
                        backgroundColor: "#595D65",
                        backgroundImage: "none",
                        width: "80%",
                        marginBottom: 32,
                        marginTop: 15,
                        fontWeight: "regular",
                      }}
                      onClick={() => {
                        this.filterChange();
                        this.setState({ filterFields: true });
                      }}
                      id="validate_pro_btn"
                    />

                    {filterFields ? (
                      <div>
                        {/* <div className="form-group" style={multiSelect}>
                          <label className="catsubcat">
                            Category(ies) to Exclude (if needed)
                          </label>
                          <MultiSelect
                            placeholder="Please Select Category(ies)"
                            value={gift_category}
                            options={
                              this.state.shopifydatas.categoryResult.map(
                                (doc) => ({
                                  label: doc.title,
                                  value: doc.id,
                                })
                              ) || [{ label: "Loading...", value: "" }]
                            }
                            isMulti={true}
                            isSearchable={true}
                            onChange={(gift_category) =>
                              this.setState({ gift_category })
                            }
                            className="col-sm-7 col-sm-offset-2"
                            style={customStyles}
                            closeMenuOnSelect={false}
                            id="shop_drop_gift_category"
                          />
                        </div> */}

                        <div className="form-group" style={multiSelect2}>
                          <label className="catsubcat">
                            Collection(s) to Exclude (if needed)
                          </label>
                          <MultiSelect
                            placeholder="Please Select Collection(s)"
                            value={subcategory}
                            options={
                              this.state.shopifydatas.subcategoryResult.map(
                                (doc) => ({
                                  label: doc.title,
                                  value: doc.id,
                                })
                              ) || [{ label: "Loading...", value: "" }]
                            }
                            isMulti={true}
                            isSearchable={true}
                            onChange={(subcategory) =>
                              this.setState({ subcategory })
                            }
                            className="col-sm-7 col-sm-offset-2"
                            style={customStyles}
                            closeMenuOnSelect={false}
                            id="shop_drop_subcategory"
                          />
                        </div>

                        <div className="form-group" style={multiSelect1}>
                          <label className="catsubcat">
                            Product Group(s) to Exclude (if needed)
                          </label>
                          <MultiSelect
                            placeholder="Please Select Product Group(s)"
                            value={productGroup}
                            options={
                              this.state.shopifydatas.productGroupResult.map(
                                (doc) => ({
                                  label: doc.title,
                                  value: doc.id,
                                })
                              ) || [{ label: "Loading...", value: "" }]
                            }
                            isMulti={true}
                            isSearchable={true}
                            onChange={(productGroup) =>
                              this.setState({ productGroup })
                            }
                            className="col-sm-7 col-sm-offset-2"
                            style={customStyles}
                            closeMenuOnSelect={false}
                            id="shop_drop_productGroup"
                          />
                        </div>

                        <div className="form-group" style={multiSelect1}>
                          <label className="catsubcat">
                            Product(s) to Exclude (if needed)
                          </label>
                          <MultiSelect
                            placeholder="Please Select Product(s)"
                            value={product}
                            options={
                              this.state.shopifydatas.productResult.map(
                                (doc) => ({
                                  label: doc.title,
                                  value: doc.id,
                                })
                              ) || [{ label: "Loading...", value: "" }]
                            }
                            isMulti={true}
                            isSearchable={true}
                            onChange={(product) => this.setState({ product })}
                            className="col-sm-7 col-sm-offset-2"
                            style={customStyles}
                            closeMenuOnSelect={false}
                            id="shop_drop_product"
                          />
                        </div>
                        <SubmitControl title="Sync Products"
                          style={{
                            backgroundColor: "#595D65",
                            backgroundImage: "none",
                            width: "80%",
                            marginBottom: 32,
                            marginTop: 15,
                            fontWeight: "regular",
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}

            <div className="form-group" style={multiSelect}>
              <MultiSelect
                placeholder="Please Select Delivery Mode"
                value={selectedDeliveryMode}
                options={deliveryModes}
                isMulti={false}
                isSearchable={true}
                onChange={this.handleMultiShipment}
                className="col-sm-7 col-sm-offset-2"
                style={customStyles}
              />
            </div>
            <div className="form-group" style={multiSelect}>
              <MultiSelect
                isDisabled={
                  this.state.shipment && this.state.shipment !== "door_delivery"
                }
                placeholder="Please Select Delivery Partner"
                value={selectedDeliveryPartner}
                options={deliveryPartner}
                isMulti={false}
                isSearchable={true}
                onChange={this.handleMultiPartner}
                className="col-sm-7 col-sm-offset-2"
                style={customStyles}
              />
            </div>
            {this.state.deliveryPartner === "gifteffects_delivery" && (
              <div style={COMMON_STYLE} className="chkbox-container">
                <label
                  style={{
                    marginLeft: 12,
                    fontSize: 12,
                    fontWeight: 350,
                    opacity: 0.5,
                  }}
                >
                  <a
                    href="https://uat.gifteffects.com/admin/cms/banned"
                    target="__blank"
                  >
                    View GiftEffects Banned Items For Delivery
                  </a>
                </label>
              </div>
            )}

            <div className="chkbox-container" style={COMMON_STYLE}>
              <input
                type="radio"
                name="applyForExisting"
                checked={
                  this.state.applyForExistingAll ||
                  (!this.state.applyForExisting &&
                    !this.state.applyForExistingAll)
                }
                onChange={(event) =>
                  this.onDataChanged(
                    "applyForExistingAll",
                    event.target.checked
                  )
                }
                className="chkbox"
              />
              <label
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontWeight: 350,
                  opacity: 0.5,
                }}
              >
                Apply this change for all the existing products & product groups
              </label>
            </div>
            {this.state.shipment === "door_delivery" && (
            <div className="chkbox-container" style={COMMON_STYLE}>
              <input
                type="radio"
                name="applyForExisting"
                checked={this.state.applyForExisting}
                onChange={(event) =>
                  this.onDataChanged("applyForExisting", event.target.checked)
                }
              />
              {/* <label
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontWeight: 350,
                  opacity: 0.5,
                }}
              >
                {`Apply this change for the products & product groups which currently have default delivery set as ${
                  this.props.profile &&
                  this.props.profile.shipment &&
                  // deliveryModes.find(
                  //   (option) =>  option.value == this.props.profile.shipment
                  // )
                  selectedDeliveryMode.label
                }`}
              </label> */}
              <label
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontWeight: 350,
                  opacity: 0.5,
                }}
              >
                {`Apply this change for the products & product groups which currently have default delivery set as ${
                  (this.props.profile && this.props.profile.shipment && selectedDeliveryMode && selectedDeliveryMode.label)
                    ? selectedDeliveryMode.label
                    : ""
                }`}
              </label>; 
            </div>
            )}
            <InputControl
              label="Max days for return"
              required={true}
              value={returnPeriod}
              placeholder="Max days for return"
              onChange={(returnPeriod) =>
                this.onNumberFieldChange("returnPeriod", returnPeriod)
              }
              classNames={["form-control"]}
              innerWrapperClassNames={["col-sm-offset-3", "col-sm-6"]}
              labelClassNames={["col-sm-offset-3", "col-sm-9"]}
            />
            <div className="chkbox-container" style={COMMON_STYLE}>
              <input
                style={{ marginTop: 0 }}
                type="checkbox"
                name="applyForExistingMaxDays"
                checked={this.state.applyForExistingMaxDays || false}
                onChange={() =>
                  this.onDataChanged(
                    "applyForExistingMaxDays",
                    !this.state.applyForExistingMaxDays
                  )
                }
              />
              <label
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontWeight: 350,
                  opacity: 0.5,
                  marginBottom: 0,
                }}
              >
                Apply this changes for all the existing products and product
                groups
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "24%",
              marginTop: 20,
            }}
          >
            <label>Return Policy</label>
            <TextEditor
              editorState={this.state.editorState}
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
          {/* <input value={password} onChange={password => this.setState({password})} /> */}
          <SubmitControl
            style={{
              backgroundColor: "#595D65",
              backgroundImage: "none",
              width: "80%",
              marginBottom: 32,
              marginTop: 15,
              fontWeight: "regular",
            }}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({
  delivery,
  countries,
  categories,
  productGroups,
  products,
  vendors,
}) => ({
  deliveryPartner: delivery.deliveryPartner || [],
  deliveryModes: delivery.deliveryModes || [],
  countries: countries.data || [],
  productGroups: productGroups.data || [],
  products: products.data || [],
  categories: categories.data || [],
  profile: vendors.data,
});
export default connect(mapStateToProps, {
  loadCountries,
  loadDeliveryModesAndPartners,
  loadCategories,
  listProductGroups,
  listProducts,
  getVendorProfile,
  updateVendorProfile,
  uploadImagetoS3,
})(ProfileComponent);

const COMMON_STYLE = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: "24%",
};
