import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { push, replace } from "react-router-redux";
import { InputControl, SubmitControl, SelectControl, Form } from "../forms";
import { connect } from "react-redux";
import moment from 'moment';
import { vendorLogin } from "../../store/actions/vendors";

var divStyle = {
    width:"50%",
    border:"1px solid #A2A2A2",
    borderRadius:8,
    margin:"auto"
  };

class LoginComponent extends Component {

    constructor() {
        super()
        this.onSubmitted = this.onSubmitted.bind(this);
    }

    state = {
        email:'', password:'', terms: false
    }
    onDataChanged = (key, value) => {
        this.setState({ [key]: value })
    }
 
    onSubmitted() {
        // const {email, password, terms} = this.state
        // if(!email) {
        //     alert('Please enter email')
        // } else if(!password) {
        //     alert('Please enter password')
        // } else if(!terms) {
        //     alert('Please accept terms and conditions')
        // } else {
            this.props.vendorLogin(this.state)
        // }
    }
    render() {
        var inputStyle = {
            border : "none",
            // borderBottom:"1px solid #C7D1DB",
            boxShadow:"none",
            borderRadius:0,
            fontSize:14,
            padding:0,
            fontWeight:350,
            color:"#595D65"
          },labelStyle={fontSize:20,fontWeight:400,marginTop:40,marginBottom:40,alignSelf:"center"}
        const { email, password } = this.state
        return <div style={divStyle}> <Form onSubmit={this.onSubmitted}>
        <div style={{textAlign:"center"}}>
        <label style={labelStyle}>SIGN IN</label>
    </div>
            <InputControl name='' placeholder='Enter Your Email' value={email} onChange={email => this.setState({email})} style={inputStyle} />
            <InputControl type='password' name='' value={password} placeholder='Password' onChange={password => this.setState({password})} style={{marginTop:23,border : "none", boxShadow:"none", borderRadius:0, fontSize:14, padding:0, fontWeight:350, color:"#595D65"}} />
            <div className="chkbox-container">
                <label style={{ width: "24%" }} className="lbl-mobile"></label>
                <input type="checkbox" required={true} name="terms" checked={this.state.terms} onChange={event => this.onDataChanged('terms', event.target.checked)} className="chkbox" />
                <label style={{ marginLeft: 12, fontSize: 12, fontWeight: 350, opacity: 0.5 }}>I agree to the <a href="https://uat.gifteffects.com/admin/cms/vendor-terms-and-conditions" target="__blank">terms and conditions</a> of Gift Effects</label>
            </div>
            <div style={{textAlign:"center",width:"51%",margin:"auto"}}>
                <div style={{float:"right"}}>
                    <NavLink to="/forgot-password" activeStyle={{fontSize:12,fontWeight:350,opacity:0.5}}>Forgot Password?</NavLink>
                </div>
            </div>
            <div>
                {/* <label style={{width:"25%"}}></label> */}
                
            
            <SubmitControl  title="SIGN IN" style={{marginBottom:32,marginTop:60}} classNames={['submitStyle','btn']}/>
            </div>
        </Form>
        </div>
    }
}

export default connect(null, {vendorLogin })(LoginComponent)