import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listProductGroups,
  loadCategories,
  updateProductGroup,
  uploadImagetoS3,
  loadProductGroupReturnPolicy,
} from "../../../store/actions/product-groups";
import AddAttributeComponent from "./add-attribute";
import {
  InputControl,
  SubmitControl,
  SelectControl,
  Form,
  TextEditor,
} from "../../forms";
import Background from "../../../images/bg-image.png";
import Background1 from "../../../images/imagecurved.png";
import Carousel from "nuka-carousel";
import "./productGroup.css";
import MultiSelect from "react-select";
import "../common.css";
import {
  loadCountries,
  loadDeliveryModesAndPartners,
  getVendorReturnPolicy,
} from "../../../store/actions/vendors";
import currencyList from "../../../currency-codes.json";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import ActivityLog from "./activity-log";

var customStyles = {
  borderWidth: 0,
};

var buttonStyle = {
    border: "1px solid grey",
    backgroundColor: "transparent",
    borderWidth: 2,
    borderColor: "#979797",
    outline: "none",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 4,
    marginRight: 10,
  },
  selected = {
    borderColor: "#F5900C",
  },
  multiSelect = {
    borderWidth: 0,
    width: "100%",
  },
  hover = {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    p: { display: "none" },
    "&::hover": {
      "&+p": {
        display: "block",
      },
    },
  };
class EditProductGroupComponent extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddAttrClicked = this.onAddAttrClicked.bind(this);
    this.onNumberFieldChange = this.onNumberFieldChange.bind(this);
    const {
      group = {
        length: "",
        breadth: "",
        depth: "",
        dead_weight: "",
        id: "",
        returnPeriod: "",
        expected_delivery: "",
        name: "",
        description: "",
        cost: "",
        bg_images: [],
        images: [],
        category: "",
        other_attributes: [],
        countries: [],
        shipment: "",
        deliveryPartner: "",
      },
    } = props;
    this.state = {
      ...group,
      returnPeriod: group.returnPeriod || this.props.defaultReturnPeriod,
      editorState: EditorState.createEmpty(),
      bgImage: [],
      buttonStyle: { display: "none" },
      buttonStyles: {
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: "#979797",
        outline: "none",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 8,
        paddingBottom: 8,
        borderRadius: 4,
        marginRight: 10,
      },
      divStyle: {
        width: 148,
        height: 150,
        backgroundColor: "#000000",
        display: "inline-block",
        position: "relative",
      },
    };
  }
  async componentDidMount() {
    this.props.loadCountries();
    this.props.loadDeliveryModesAndPartners();
    this.props.listProductGroups();
    this.props.loadCategories();
    this.props.getVendorReturnPolicy();
    let {
      body: { return_policy },
    } = await this.props.loadProductGroupReturnPolicy(this.props.groupId);
    return_policy &&
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(return_policy))
        ),
      });
  }

  componentWillReceiveProps(nextProps) {
    const { group = {} } = nextProps;
    if (JSON.stringify(group) !== JSON.stringify(this.props.group)) {
      Object.assign(group, {
        length: (group.length || 0).toString(),
        breadth: (group.breadth || 0).toString(),
        dead_weight: (group.dead_weight || 0).toString(),
        depth: (group.depth || 0).toString(),
        returnPeriod:
          (group.returnPeriod || "").toString() ||
          this.props.defaultReturnPeriod,
      });
      this.setState(group);
    }
  }
  onDataChanged = (key, value) => {
    if ([`applyForExistingAll`, `applyForExisting`].includes(key)) {
      let nonKey =
        key == `applyForExisting` ? `applyForExistingAll` : `applyForExisting`;
      this.setState({ [key]: value, [nonKey]: false });
    } else {
      this.setState({ [key]: value });
    }
  };
  onNumberFieldChange(key, val) {
    if (Number(val) >= 0) {
      this.setState({
        [key]: [
          `length`,
          `breadth`,
          `dead_weight`,
          `depth`,
          `returnPeriod`,
        ].includes(key)
          ? val.toString()
          : val,
      });
    }
  }
  handleMultiSelect = (option) => {
    option = option.map((o) => o.value.toUpperCase().replace(/ /g, "_"));
    this.setState({ countries: option });
  };
  handleMultiShipment = (option) => {
    this.setState({
      shipment: option.value,
      deliveryPartner:
        option.value === "door_delivery"
          ? this.state.deliveryPartner || ""
          : this.state.deliveryPartner,
      banned_check:
        option.value === "door_delivery" ? false : this.state.banned_check,
    });
    // if (option.value === "door_delivery") {
    //     this.setState({
    //         deliveryPartner: this.state.deliveryPartner || "",
    //         banned_check: false
    //     })
    // }
  };

  handleMultiPartner = (option) => {
    this.setState({ deliveryPartner: option.value });
    if (option.value === "others") {
      this.setState({
        banned_check: false,
      });
    } else {
      this.setState({
        banned_check: true,
      });
    }
  };
  onImageRemoved(value) {
    this.setState({
      images: this.state.images.filter((image) => image != value),
    });
  }
  handleSubmit() {
    let { length, breadth, depth, dead_weight } = this.state;

    if (this.state.shipment == "digital") {
      length = "";
      breadth = "";
      depth = "";
      dead_weight = "";
    }
    if (!this.state.name || !this.state.name.trim().length) {
      alert(`Name is required`);
      return;
    }
    if (!this.state.cost) {
      alert("Please mention product cost");
      return;
    }
    if (!this.state.category) {
      alert("Please select product category");
      return;
    }
    if (
      (!(Number(this.state.length) >= 0) || this.state.length === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Length in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(this.state.breadth) >= 0) || this.state.breadth === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Breadth in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(this.state.depth) >= 0) || this.state.depth === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Depth in cms. If not applicable for product, then please enter value of 0 (zero)."
      );
      return;
    }
    if (
      (!(Number(this.state.dead_weight) >= 0) ||
        this.state.dead_weight === "") &&
      this.state.shipment != "digital"
    ) {
      alert(
        "Please enter a value for Weight in kgs. If not applicable for product, then please enter value of 0 (zero)"
      );
      return;
    }
    // if( !parseFloat(this.state.length) && this.state.shipment != "digital"){
    //     alert('Please mention product length')
    //     return
    // }
    // if( !parseFloat(this.state.breadth) &&this.state.shipment != "digital"){
    //     alert('Please mention product breadth')
    //     return
    // }
    // if(!parseFloat(this.state.depth)  && this.state.shipment != "digital"){
    //     alert('Please mention product depth')
    //     return
    // }
    // if(!parseFloat(this.state.dead_weight) && this.state.shipment != "digital"){
    //     alert('Please mention product dead_weight')
    //     return
    // }
    if (!this.state.shipment) {
      alert("Please select Delivery Mode");
      return;
    }
    if (!this.state.deliveryPartner) {
      alert("Please select Delivery Partner");
      return;
    }
    const bg_images = this.state.bgImage.length
      ? this.state.bgImage
      : this.state.bg_images;
    const {
      activityLog,
      editorState,
      buttonStyle,
      buttonStyles,
      divStyle,
      ...others
    } = this.state;
    const payload = {
      ...others,
      length,
      breadth,
      dead_weight,
      depth,
      bg_images,
      return_policy: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
    };
    this.props.updateProductGroup(this.props.groupId, { ...payload });
  }
  onAddAttrClicked() {
    this.setState({
      other_attributes: this.state.other_attributes.concat([""]),
    });
  }
  attributeEdited(index, newVal) {
    const vals = this.state.other_attributes;
    vals.splice(index, 1, newVal);
    this.setState({ other_attributes: vals });
  }
  attributeRemoved(index, newVal) {
    const updatedAttr = this.state.other_attributes;
    updatedAttr.splice(index, 1);
    this.setState({ other_attributes: updatedAttr });
  }
  onAttrEditFromComponent = (index, newVal) => {
    const vals = this.state.other_attributes;
    vals.splice(index, 1, newVal);
    this.setState({ other_attributes: vals });
    this.props.updateProductGroup(this.props.groupId, {
      other_attributes: vals,
    });
  };
  onAttrRemovedFromComponent = (index) => {
    const updatedAttr = this.state.other_attributes;
    updatedAttr.splice(index, 1);
    this.setState({ other_attributes: updatedAttr });
    this.props.updateProductGroup(this.props.groupId, {
      other_attributes: updatedAttr,
    });
  };
  handleBgImage(e) {
    if (e.target.files[0]) {
      let uploadedFiles = Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ]);
      console.log(e);
      uploadedFiles
        .then((res) => {
          this.setState({ bgImage: res });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };
  handleFile(e) {
    if (e.target.files[0]) {
      Promise.all([
        this.props.uploadImagetoS3(
          e.target.files[0].name,
          e.target.files[0].type,
          e.target.files[0]
        ),
      ])
        .then((res) => {
          this.setState({ images: [...this.state.images, ...res] });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  render() {
    const deliveryOptions = [];
    for (var i = 1; i <= 20; i++) {
      deliveryOptions.push({ label: i, value: i });
    }
    const options = [{ label: "Please Select", value: "" }].concat(
      this.props.categories.map((category) => ({
        label: category.name,
        value: category.id,
      }))
    );
    const deliveryModes = this.props.deliveryModes.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const deliveryPartner = this.props.deliveryPartner.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];
    const selectedDeliveryMode = deliveryModes.find((option) =>
      !!this.state.shipment
        ? option.value === this.state.shipment
        : option.value === this.props.defaultShipment
    );
    const selectedDeliveryPartner = deliveryPartner.find(
      (option) => option.value === this.state.deliveryPartner
    );
    let {
      name,
      description,
      cost,
      category,
      images,
      expected_delivery,
      returnPeriod,
      bg_images,
      length,
      breadth,
      depth,
      dead_weight,
    } = this.state;
    // bg_images = this.state.bgImage.length ? this.state.bgImage : bg_images
    const selectedcountries = this.props.countries
      .filter(
        (option) =>
          this.state.countries &&
          this.state.countries.includes(option.replace(/ /g, "_").toUpperCase())
      )
      .map((val) => ({ label: val, value: val.toUpperCase() }));
    const existingDeliveryMode =
      this.props.group &&
      deliveryModes.find((p) => p.value == this.props.group.shipment);

    return (
      <div style={{ width: "70%", margin: "auto" }}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", textAlign: "center" }}>
            <div
              style={{
                position: "relative",
                textAlign: "center",
                height: 500,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#979797",
                boxShadow: "0 0 10px #979797",
                position: "relative",
              }}
            >
              {/* <div style={{position:"absolute",width:"100%",height:215,backgroundImage: "url(" + (bg_images[0] || Background) + ")", backgroundRepeat: "no-repeat", backgroundSize: "100%", overflow: "hidden",paddingTop: 50}}>
                            <div style={bg_images.length ? hover :{display:'none'}} className='hover'>
                            <input type="file" id="bg-imgs" style={{display:'none'}} onChange={event => this.handleBgImage(event)} />
                                <label htmlFor="bg-imgs" style={{cursor:"pointer",display:"none"}}>Click here to change the image</label>
                            </div>
                            <p style={bg_images.length ? {display:'none'} : {display:'block'}}>Background Image</p>
                            <input type="file" id="bg-img" style={{display:'none'}} onChange={event => this.handleBgImage(event)} />
                            <label htmlFor='bg-img' style={bg_images.length ? {display:'none'} : { textDecoration: "underline", cursor: "pointer" }}>Click Here to Upload</label>
                                <p style={bg_images.length ? {display:'none'} : {display:'block'}}>(740 X 430 Pixels)</p>
                            </div> */}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: 390,
                  top: 85,
                  backgroundImage: "url(" + Background1 + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                {/* {images.length == 0 ? (
                  <div
                    style={{
                      position: "relative",
                      color: "#FFF",
                      fontSize: 12,
                      width: "150px",
                      bottom: 0,
                      paddingBottom: 20,
                      background: "#333",
                      margin: "auto",
                      paddingTop: "35px",
                    }}
                  >
                    <p>Product Image</p>
                    <input
                      type="file"
                      id="abc"
                      onChange={(event) => this.handleFile(event, "images")}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="abc"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Click Here to Upload
                    </label>
                    <p>(Max. 380 X 310 Pixels)</p>
                  </div> 
                ) : (*/}
                <div>
                  <Carousel dragging={true} style={{ top: -30 }}>
                    {images.map((item, index) => (
                      <div key={index}>
                        <span style={{ position: "relative" }}>
                          <img
                            src={item}
                            style={{
                              width: 150,
                              height: 150,
                              display: "inline-block",
                            }}
                          />
                          <img
                            src={require("../../../images/close.png")}
                            alt=""
                            onClick={(event) => this.onImageRemoved(item)}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: -70,
                              color: "white",
                              padding: 5,
                              width: 25,
                              height: 25,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div
                    style={{
                      position: "relative",
                      color: "#FFF",
                      fontSize: 12,
                      width: "150px",
                      bottom: 0,
                      paddingBottom: 20,
                      background: "#333",
                      margin: "auto",
                      paddingTop: "50px",
                      marginTop:"40px"
                    }}
                  >
                    <p>Product Image</p>
                    <input
                      type="file"
                      id="abc"
                      onChange={(event) => this.handleFile(event, "images")}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="abc"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Click Here to Upload
                    </label>
                    <p>(Max. 380 X 310 Pixels)</p>
                  </div>
                {/* // )} */}
                {/* {[...images].length < 2 ?
                                    <div>
                                        <div style={{ width: 148, height: 150, backgroundColor: "#000000", display: "inline-block", position: "relative", top:-30}}>
                                            {[...images].length ? 
                                                <span>
                                                    <img src={images[0]} style={{ width: 150, height: 150 }} />
                                                    <img src={require('../../../images/close.png')} onClick={event => this.onImageRemoved(images[0])} style={{ position: "absolute", right: 0, top: 0, color: "white", padding: 5, cursor: "pointer",width:25,height:25 }}/>
                                                </span> :
                                                <div style={{ position: "absolute", color: "#FFFFFF", fontSize: 12, width: "100%", bottom: 0, paddingBottom: 20 }}>
                                                    <p>Product Image</p>
                                                    <input type="file" id='abc' onChange={event => this.handleFile(event, 'images')} style={{ display: 'none' }} />
                                                    <label htmlFor='abc' style={{ textDecoration: "underline", cursor: "pointer" }}>Click Here to Upload</label>
                                                    <p>(Max. 380 X 310 Pixels)</p>
                                                </div>}
                                        </div>
                                        {[...images].length ?
                                            <div style={{ marginTop: 20 }}>
                                                <div style={this.state.divStyle}>
                                                    <div style={{ position: "absolute", color: "#FFFFFF", fontSize: 12, width: "100%", bottom: 0, paddingBottom: 20 }}>
                                                        <p>Product Image</p>
                                                        <input type="file" id='abc' onChange={event => this.handleFile(event, 'images')} style={{ display: 'none' }} />
                                                        <label htmlFor='abc' style={{ textDecoration: "underline", cursor: "pointer" }}>Click Here to Upload</label>
                                                        <p>(Max. 380 X 310 Pixels)</p>
                                                    </div>
                                                </div>
                                            </div> : <span></span>}
                                    </div> :
                                    <div>
                                        <Carousel dragging={true} style={{top:-30}}>
                                            {[...images].map(image =>
                                                <span style={{ position: "relative" }}><img src={image} style={{ width: 150, height: 150, display: "inline-block" }} />
                                                    <img src={require('../../../images/close.png')} onClick={event => this.onImageRemoved(image)} style={{ position: "absolute", right: 0, top: -70,  color: "white", padding: 5, width:25, height:25, cursor: "pointer" }}/>
                                                </span>
                                            )}
                                        </Carousel>
                                        <div style={this.state.divStyle}>
                                            <div style={{ position: "absolute", color: "#FFFFFF", fontSize: 12, width: "100%", bottom: 0, paddingBottom: 20,marginTop:30 }}>
                                                <p>Product Image</p>
                                                <input type="file" id='abc' onChange={event => this.handleFile(event)} style={{ display: 'none' }} />
                                                <label htmlFor='abc' style={{ textDecoration: "underline", cursor: "pointer" }}>Click Here to Upload</label>
                                                <p>(Max. 380 X 310 Pixels)</p>
                                            </div>
                                        </div>
                                    </div>
                                } */}
              </div>
            </div>
          </div>
          <div style={{ width: "55%" }}>
            <Form onSubmit={this.handleSubmit}>
              <InputControl
                label="Product Group Name"
                required
                name=""
                value={name}
                onChange={(name) => this.setState({ name })}
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <SelectControl
                label="SubCategory"
                value={category}
                options={options}
                onChange={(category) => this.setState({ category })}
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div className="form-group">
                <label
                  className="col-sm-offset-3 col-sm-9"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Description
                </label>
                <div className="col-sm-offset-3">
                  <textarea
                    rows="4"
                    cols="48"
                    placeholder="description"
                    style={{
                      resize: "none",
                      marginBottom: "12px",
                      outline: "0",
                      borderWidth: "0 0 0.2px",
                    }}
                    required
                    value={description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    className={["form-control"]}
                    innerwrapperclassname={["col-sm-offset-3"]}
                    labelclassname={["col-sm-offset-3", "col-sm-9"]}
                  />
                </div>
              </div>
              {this.state.shipment != "digital" && (
                <div>
                  <div className="Length">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Length/Height  (cms)"
                      placeholder="Length/Height in cms"
                      value={length}
                      onChange={(length) =>
                        this.onNumberFieldChange("length", length)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Breadth">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Breadth/Width (cms)"
                      placeholder="Breadth/Width in cms"
                      value={breadth}
                      onChange={(breadth) =>
                        this.onNumberFieldChange("breadth", breadth)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Depth">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Depth (cms)"
                      placeholder="Depth in cms"
                      value={depth}
                      onChange={(depth) =>
                        this.onNumberFieldChange("depth", depth)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                  <div className="Dead Weight">
                    {/* <span className="currency">{currencyList[this.props.currencyISO]}</span> */}
                    <InputControl
                      label="Weight (kgs)"
                      placeholder="Weight in kgs"
                      value={dead_weight}
                      onChange={(dead_weight) =>
                        this.onNumberFieldChange("dead_weight", dead_weight)
                      }
                      classNames={["form-control"]}
                      innerWrapperClassNames={["col-sm-offset-3"]}
                      labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                    />
                  </div>
                </div>
              )}
              <div className="selling-price">
                <span className="currency">
                  {currencyList[this.props.currencyISO]}
                </span>
                <InputControl
                  label="Selling Price"
                  required
                  value={cost}
                  onChange={(cost) => this.onNumberFieldChange("cost", cost)}
                  classNames={["form-control"]}
                  innerWrapperClassNames={["col-sm-offset-3"]}
                  labelClassNames={["col-sm-offset-3", "col-sm-9"]}
                  selectedcountries="true"
                />
              </div>
              <SelectControl
                label="Expected Delivery in Days"
                required
                value={expected_delivery}
                options={deliveryOptions}
                onChange={(expected_delivery) =>
                  this.setState({ expected_delivery })
                }
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <InputControl
                label="Max days for return"
                required={true}
                value={this.state.returnPeriod}
                placeholder="Max days for return"
                onChange={(returnPeriod) =>
                  this.onNumberFieldChange("returnPeriod", returnPeriod)
                }
                classNames={["form-control"]}
                innerWrapperClassNames={["col-sm-offset-3"]}
                labelClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
              <div style={{ marginLeft: "118px" }}>
                <label>Return Policy</label>
                <TextEditor
                  editorState={this.state.editorState}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
              <div className="form-group multi-dropdown" style={multiSelect}>
                <MultiSelect
                  placeholder="Please Select Deliverable Countries"
                  value={selectedcountries}
                  options={this.props.countries.map((country) => ({
                    label: country,
                    value: country.toUpperCase(),
                    isDisabled: selectedcountries.some((c) =>
                      ["WORLD_WIDE", "WORLD WIDE"].includes(c.value)
                    ),
                  }))}
                  isMulti={true}
                  isSearchable={true}
                  onChange={this.handleMultiSelect}
                  className="col-sm-10 col-sm-offset-3"
                  style={{ borderWidth: 0 }}
                />
              </div>
              {this.state.other_attributes &&
              this.state.other_attributes.length ? (
                <div className="form-group">
                  <label className="col-sm-3"></label>
                  <label className="col-sm-9" style={{ padding: 0 }}>
                    Other Attributes
                  </label>
                </div>
              ) : (
                ``
              )}
              <div style={{ display: "flex", marginBottom: 30 }}>
                <div style={{ width: "23%" }}></div>
                <div style={{ cursor: "pointer" }}>
                  <p onClick={this.onAddAttrClicked} style={buttonStyle}>
                    + Add More Attributes
                  </p>
                </div>
              </div>
              {this.state.other_attributes.map((otherAttribute, i) => (
                <AddAttributeComponent
                  allAttrs={this.state.other_attributes}
                  key={i}
                  value={otherAttribute}
                  onEdit={this.onAttrEditFromComponent.bind(this, i)}
                  onRemove={this.onAttrRemovedFromComponent.bind(this, i)}
                  isReadonly={
                    this.props.group &&
                    this.props.group.other_attributes.includes(otherAttribute)
                  }
                  shouldShowClose={true}
                />
              ))}
              <div className="form-group" style={multiSelect}>
                <label
                  className="col-sm-9 col-sm-offset-3"
                  style={{
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Delivery Mode
                </label>
                <MultiSelect
                  placeholder="Please Select Delivery Mode"
                  value={selectedDeliveryMode}
                  options={deliveryModes}
                  isMulti={false}
                  isSearchable={true}
                  onChange={this.handleMultiShipment}
                  className="col-sm-10 col-sm-offset-3"
                  style={customStyles}
                />
              </div>
              {this.state.shipment === "door_delivery" && (
                <div className="form-group" style={multiSelect}>
                  <label
                    className="col-sm-9 col-sm-offset-3"
                    style={{
                      textAlign: "left",
                      padding: 0,
                      fontSize: 10,
                      fontWeight: 400,
                    }}
                  >
                    Delivery Partner
                  </label>
                  <MultiSelect
                    isDisabled={this.state.shipment != "door_delivery"}
                    placeholder="Please Select Delivery Partner"
                    value={selectedDeliveryPartner}
                    options={deliveryPartner}
                    isMulti={false}
                    isSearchable={true}
                    onChange={this.handleMultiPartner}
                    className="col-sm-10 col-sm-offset-3"
                    style={customStyles}
                  />
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginLeft: 110,
                    textAlign: "left",
                    padding: 0,
                    fontSize: 10,
                    fontWeight: 400,
                  }}
                >
                  Options below apply only for Delivery Mode
                </label>
                <div className="chkbox-container" style={{ display: "flex" }}>
                  <label
                    style={{ width: "24%" }}
                    className="lbl-mobile"
                  ></label>
                  <input
                    style={{ marginLeft: 7 }}
                    type="radio"
                    name="applyForExisting"
                    checked={
                      this.state.applyForExistingAll ||
                      (!this.state.applyForExisting &&
                        !this.state.applyForExistingAll)
                    }
                    onChange={(event) =>
                      this.onDataChanged(
                        "applyForExistingAll",
                        event.target.checked
                      )
                    }
                    className="chkbox"
                  />
                  <label
                    style={{
                      marginLeft: 5,
                      fontSize: 12,
                      fontWeight: 350,
                      opacity: 0.5,
                    }}
                  >
                    Apply this change for all the existing products of this
                    product group
                  </label>
                </div>
                <div className="chkbox-container" style={{ display: "flex" }}>
                  <label
                    style={{ width: "31%" }}
                    className="lbl-mobile"
                  ></label>
                  <input
                    style={{ marginLeft: 34 }}
                    type="radio"
                    name="applyForExisting"
                    checked={this.state.applyForExisting}
                    onChange={(event) =>
                      this.onDataChanged(
                        "applyForExisting",
                        event.target.checked
                      )
                    }
                  />
                  <label
                    style={{
                      marginLeft: 5,
                      fontSize: 12,
                      fontWeight: 350,
                      opacity: 0.5,
                    }}
                  >
                    {`Apply this change for the products which currently have default delivery set as ${
                      existingDeliveryMode && existingDeliveryMode.label
                    } of this product group`}
                  </label>
                </div>
              </div>
              {/* <div className="chkbox-container" style={{ display: "flex" }}>
                        <label style={{ width: "24%" }} className="lbl-mobile"></label>
                        <input type="checkbox" name="applyForExistingMaxDays" checked={this.state.applyForExistingMaxDays} onChange={event => this.onDataChanged('applyForExistingMaxDays', event.target.checked)} />
                        <label style={{ marginLeft: 12, fontSize: 12, fontWeight: 350, opacity: 0.5 }} >Apply this changes for all the existing products and product groups</label>
                    </div> */}
              {this.state.deliveryPartner == "gifteffects_delivery" &&
                this.state.shipment === "door_delivery" && (
                  <div className="chkbox-container">
                    <label
                      style={{ width: "24%" }}
                      className="lbl-mobile"
                    ></label>
                    <label
                      style={{
                        marginLeft: 12,
                        fontSize: 12,
                        fontWeight: 350,
                        opacity: 0.5,
                      }}
                    >
                      <a
                        href="https://uat.gifteffects.com/admin/cms/banned"
                        target="__blank"
                      >
                        View GiftEffects Banned Items For Delivery
                      </a>
                    </label>
                  </div>
                )}
              {this.state.shipment === "door_delivery" &&
                this.state.deliveryPartner == "gifteffects_delivery" && (
                  <div className="chkbox-container" style={{ display: "flex" }}>
                    <label
                      style={{ width: "24%" }}
                      className="lbl-mobile"
                    ></label>
                    <input
                      type="checkbox"
                      name="banned_check"
                      checked={this.state.banned_check}
                      onChange={(event) =>
                        this.onDataChanged("banned_check", event.target.checked)
                      }
                      className="chkbox"
                    />
                    <label
                      style={{
                        marginLeft: 12,
                        fontSize: 12,
                        fontWeight: 350,
                        opacity: 0.5,
                      }}
                    >
                      This product group doesn't belong to any of the
                      GiftEffects's banned-items{" "}
                      <a
                        href="https://uat.gifteffects.com/admin/cms/banned"
                        target="__blank"
                      >
                        More Info
                      </a>{" "}
                    </label>
                  </div>
                )}
              <SubmitControl
                style={{
                  backgroundColor: "#595D65",
                  backgroundImage: "none",
                  width: "80%",
                  marginBottom: 32,
                  marginLeft: 32,
                  marginTop: 15,
                  fontWeight: "regular",
                }}
                innerWrapperClassNames={["col-sm-offset-3", "col-sm-9"]}
              />
            </Form>
          </div>
        </div>
        <ActivityLog logs={this.props.group && this.props.group.activityLog} />
      </div>
    );
  }
}

export default connect(
  (
    {
      delivery,
      productGroups,
      categories,
      countries,
      loadReturnPolicy,
      counter,
      shipmentReducer,
    },
    { match }
  ) => ({
    groupId: match.params.groupId,
    categories: categories.data || [],
    countries: countries.data,
    defaultShipment: shipmentReducer.shipment,
    defaultReturnPeriod: shipmentReducer.returnPeriod,
    deliveryPartner: delivery.deliveryPartner || [],
    deliveryModes: delivery.deliveryModes || [],
    currencyISO: counter.currencyISO,
    group: productGroups.data.find(
      (productGroup) => productGroup.id === match.params.groupId
    ),
    return_policy: loadReturnPolicy.editorState,
    editorState: loadReturnPolicy.editorState,
  }),
  {
    listProductGroups,
    loadCategories,
    loadCountries,
    loadProductGroupReturnPolicy,
    getVendorReturnPolicy,
    loadDeliveryModesAndPartners,
    updateProductGroup,
    uploadImagetoS3,
  }
)(EditProductGroupComponent);
