import { LOADING_ORDERS_SUCCESS, LOADING_ORDER_DETAIL_SUCCESS, UPDATE_ORDER_SUCCESS } from "../types/orders";

export default function orders(state = {data:[], detail:{}, page:1, totalPages:1}, action) {
    const {type, orders, page, totalPages} = action
    if(type == LOADING_ORDERS_SUCCESS){
        return {...state, data:orders, page, totalPages}
    }
    if(type == LOADING_ORDER_DETAIL_SUCCESS){
        return {...state, detail:action}
    }
    if(type == UPDATE_ORDER_SUCCESS){
        return { ...state, 
            data: state.data.map(order => order.id == action.id ? { ...action } : order),
            detail: action 
        }
    }
    return state
}