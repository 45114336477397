import React, { Component } from "react";
import { connect } from "react-redux";
import { getVendorHomePage, acceptOrRejectOrder } from "../../store/actions/vendors";
import { NavLink } from "react-router-dom";
class HomeComponent extends Component {
    constructor(props) {
        super(props)
        this.onAccept = this.onAccept.bind(this)
        this.onReject = this.onReject.bind(this)
        this.togglePopup = this.togglePopup.bind(this)
    }
    state = { status: this.props.orderStatus, showPopup: false, reason:``, orderId:null }
    componentDidMount() {
        this.props.getVendorHomePage()
    }
    onAccept(orderId){
        this.props.acceptOrRejectOrder(orderId, {status:'ACCEPTED'})
    }
    onReject() {
        this.setState({ status: `REJECTED`, showPopup: !this.state.showPopup, orderId:null })
        this.props.acceptOrRejectOrder(this.state.orderId, { status: `REJECTED`, reject_reason:this.state.reason })
    }
    onOrderNameClick = (id) => {
        window.location = `/orders/${id}/detail`
    }
    togglePopup(orderId) {
        this.setState({ showPopup: !this.state.showPopup, orderId });
    }
    render() {
        const { total, accepted, shipped, lowStock, outOfStock, newOrders } = this.props.vendors
        return (
            <div style={{ width: "70%", margin: "auto" }}>
                {newOrders && newOrders.length ?
                    <div>
                        <div className="new-orders">
                            <b>New Orders</b>
                            <b className="view-all"><NavLink to='/orders'>View All</NavLink></b>
                        </div>
                        <div className="orders-list">
                            {newOrders && newOrders.filter(o => o.status === 'ORDER_RECEIVED').map((order, index) =>
                                index < 4 && <div className='content' key={index}>
                                    <img src={order.product.images[0] || `https://appx-dev-ge.s3.ap-south-1.amazonaws.com/Gift_Effects_Logo.png`} height={88} width={88} />
                                    <div className='item-details'>
                                        <div style={{cursor:'pointer'}} onClick={e => this.onOrderNameClick(order.id)}>
                                            <p className="item-name">{order.product.name}</p>
                                            <div className="order-num">
                                                <span>Order No. {order.order_short_id}</span> | <span>{new Date(order.createdAt).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</span>
                                            </div>
                                        </div>
                                        <div className="availablility">
                                            <span style={{border:'none'}} className='btn btn-success' onClick={e => this.onAccept(order.id)}>ACCEPT</span> 
                                            <span style={{border:'none'}} className='btn btn-warning' onClick={e => this.togglePopup(order.id)}>REJECT</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.showPopup ?
                                <div className='popup'>
                                    <div style={{ textAlign: 'center' }} className='popup_inner'>
                                        <span onClick={this.togglePopup} style={{ cursor: 'pointer', float: 'right', paddingRight: '10px' }}>x</span>
                                        <p style={{ marginTop: '40px' }}><b>Menton reasons for reject/cancellation</b></p>
                                        <div style={{ marginTop: '65px' }}>
                                            <input style={{ width: '300px' }} type='text' value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })} />
                                        </div>
                                        <button style={{ marginTop: '15px' }} disabled={!this.state.reason.trim().length} className='btn btn-success' onClick={this.onReject}>Reject Order</button>
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div> : <div style={{ textAlign: 'center' }}>No Orders Found</div>
                }
                <div className="overview">
                    <b>Products Overview</b>
                    <div style={{cursor:'pointer'}} className="stats">
                        <div onClick={e => this.props.history.push(`/orders`)}>
                            <p className="number">{total}</p>
                            <p className="name">ORDERS RECEIVED</p>
                        </div>
                        <div onClick={e => this.props.history.push(`/orders?status=ACCEPTED`)}>
                            <p className="number">{accepted}</p>
                            <p className="name">ACCEPTED</p>
                        </div>
                        <div onClick={e => this.props.history.push(`/orders?status=SHIPPED`)}>
                            <p className="number">{shipped}</p>
                            <p className="name">SHIPPED</p>
                        </div>
                        <div onClick={e => this.props.history.push(`/products?status=lowStock`)}>
                            <p className="number">{lowStock}</p>
                            <p className="name">LOW IN STOCK</p>
                        </div>
                        <div onClick={e => this.props.history.push(`/products?status=noStock`)}>
                            <p className="number">{outOfStock}</p>
                            <p className="name">OUT OF STOCK</p>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
const mapStateToProps = ({ vendors }) => ({ vendors: vendors.data })
export default connect(mapStateToProps, { getVendorHomePage, acceptOrRejectOrder })(HomeComponent)