import React, { Component, Fragment } from "react";

export default class SuccessComponent extends Component {
    render(){
        return (<div
            style={{
              textAlign: "center",
              marginTop:50,
              marginBottom:50
            }}
          >
            <img src={require('../../images/onboarding_completed.png')} style={{marginBottom:3,marginRight:8}}/>
            <div>
            <label style={{ fontSize: 18, marginTop: 15 }}>
              Welcome aboard Gift Effects...
            </label>
            </div>
          </div>);
    }
}