import React, { Component } from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";

class ProtectedRoute extends React.Component {
    render () {
        const { component: Component, isLoggedIn, accountRole, location, ...rest } = this.props;
        return (
            <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    accountRole === `VENDOR_DATA_ENTRY` && (["/orders", "/", "/home"].includes(location.pathname))  ? <Redirect to={{pathname:'/product-groups'}} /> :
                <Component {...props} />
                ) : (
                <Redirect
                    to={{
                    pathname: "/register",
                    state: { from: props.location }
                    }}
                />
                )
            }
            />
        );
    }
}

export default connect(({auth}) => ({isLoggedIn : !!auth.token, accountRole: auth.account_role}))(ProtectedRoute);
  
