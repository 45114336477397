import React, { Component } from "react";
import ReactMarkdown from 'react-markdown'
import { connect } from "react-redux";
import { resetPasswordSuccess } from "../../store/actions/vendors";
class ForgotPasswordSuccessComponent extends Component {
  componentDidMount() {
    this.props.resetPasswordSuccess()
  }
  render() {
    return (
      <div style={{ textAlign: "center", marginHorizontal: 15 }}>
      <ReactMarkdown source={this.props.pageContent.content}></ReactMarkdown>
      </div>
    );
  }
}
const mapStateToProps = ({pageContent}) => ({pageContent:pageContent && pageContent.data || {content:''}})
export default connect(mapStateToProps, {resetPasswordSuccess})(ForgotPasswordSuccessComponent)