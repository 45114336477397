import React, { Component, Fragment } from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from 'react-router-redux'
import { history } from "../store/index";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import VendorsRouter from './vendors/router'
import "./snackBar.css";
import "../components/vendors/product-groups/productGroup.css";
class RootComponent extends Component {
  constructor(props) {
    super(props)
  }
  render(){
    return <Fragment>
      <ConnectedRouter history={history}>
        <div>
          {this.props.count > 0 &&
            <Loading />
          }
          <Header notificationCount={this.props.notificationCount || 0} />
          <div className="container-fluid main-container" style={{ marginTop: 150, marginBottom: 50 }}>
            <Route path="/" component={VendorsRouter} />
          </div>
          <Footer />
          {this.props.toastIncrement > 0 && <Toast message={this.props.message} show={true} />}
        </div>
      </ConnectedRouter>
    </Fragment>
  }
}

const Loading = () => {
  return <div className="loader">
     <h2 style={{ marginTop:"200px"}}> Loading please wait .... </h2>
    <img src={require(`../images/loading.svg`)} style={{ marginTop: "5%", width: 100, height: 100 }} />
  </div>
}

const Toast = ({ show, message }) => {
  return <div className={ show ? "show" : "" } id="snackbar">{message}</div>
}

export default connect(({ counter }) => {
  const { count, toastIncrement, message, notificationCount } = counter;
  return { count, toastIncrement, message, notificationCount };
})
  (RootComponent)