import React, { Component } from "react";
import { connect } from "react-redux";
import { getProductGroupDetail } from "../../../store/actions/product-groups";
import { listProducts } from "../../../store/actions/product";
import { NavLink } from "react-router-dom";
import ProductListTableRowComponent from "./product-list-table-row";
import { loadStock } from "../../../store/actions/vendors";
import PaginationLinks from "../../pagination";
const customStyle = {
    headerRadio: {
        marginLeft: '20px'
    },
    searchDiv: {
        borderBottom: '1px solid #979797', width: '300px',paddingBottom:10
    },
    searchBox: {
        border: 'none', width: '85%', marginLeft: 5, outline: 'none'
    },
    firstheaderTable:{
        fontSize:13,fontWeight:500,paddingLeft:32,width:400
    },
    headerTable:{
        fontSize:13,fontWeight:500,width:200,textAlign:"center"
    },
    txtcenter:{
        textAlign:"center"
    },
    buttonStyle : {
        backgroundColor:"transparent",
        borderWidth:2,
        borderColor:"#979797",
        outline:"none",
        paddingLeft:30,
        paddingRight:30,
        paddingTop:8,
        paddingBottom:8,
        borderRadius:4,
        marginRight:10,
        color:"#595D65"
    }
}

class ProductListComponent extends Component {
    constructor(props){
        super(props)
        this.onNavigateToPage = this.onNavigateToPage.bind(this)
        this.state ={
            checkedRadio: 'active', productSearch: '', windowLocation: window.location.pathname,
            page: 1, products:[]
        }
    }
    onCheckedOrUnchecked = (key, value) => {
        this.setState({ [key]: value })
    }
    onSearched = (key, value) => {
        this.setState({ [key]: value })
    }

    get query() {
        let queryObj = {archived: false}
        switch (this.state.checkedRadio) {
            case 'lowStock':
                queryObj = { stock: { $gt:0, $lt: this.props && this.props.stock } }
                break;
            case 'noStock':
                queryObj = { stock: 0 }
                break;
            case 'archived':
                queryObj = { archived: true }
                break;
            case 'all':
                queryObj = {}
                break;
            default:
                // { queryObj }
                break;
        }
        return queryObj;
    }
    filterProductsBySelection(product) {
        let applyFilter = true
        switch (this.state.checkedRadio) {
            case 'active':
                applyFilter = product.archived ? false : true
                break;
            case 'lowStock':
                applyFilter = product.stock < 5 || false
                break;
            case 'noStock':
                applyFilter = product.stock === 0 || false
                break;
            case 'archived':
                applyFilter = product.archived || false
                break;
            case 'all':
                applyFilter = true
                break;
            default:
                break;
        }
        return applyFilter
    }
    componentDidMount() {
        this.props.loadStock()
        this.props.getProductGroupDetail(this.props.groupId)
        this.props.listProducts(this.props.groupId, {name:this.state.productSearch}, this.query)
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.checkedRadio !== this.state.checkedRadio){
            this.props.listProducts(this.props.groupId, {name:this.state.productSearch}, this.query)
        }
    }
    onSearchClicked = () => {
        this.props.listProducts(this.props.groupId, {name:this.state.productSearch}, this.query)
    }
    componentWillReceiveProps(nextProps){
        const {products} = nextProps
        this.setState({products:products})
    }
    onNavigateToPage(page){
        this.props.listProducts(this.props.groupId, {name:this.state.productSearch}, this.query, page)
    }
    render() {
        const { products } = this.state
        return (
            <div>
                <p style={{textAlign:'center'}}>Products of <b>{this.props.group && this.props.group.name}</b></p>
                <div>
                    {/* <div style={{position:"absolute",top:94,left:0,textAlign:"center",backgroundColor:"#B6B6B6",width:"100%"}}>
                        <p style={{paddingTop:6,paddingBottom:6,margin:0,color:"#FFFFFF",fontWeight:400}}>Three things need your attention!!!</p> */}
                    </div>
                    <div style={{width:"70%",margin:"auto"}}>                    
                        <div>
                            <div style={{display:"flex"}}>
                                <div style={customStyle.searchDiv}>
                                    <img src={require('../../../images/search.png')} />
                                    <input style={customStyle.searchBox} value={this.state.productSearch} type="text" name="productSearch" onChange={event => this.onSearched('productSearch', event.target.value)} placeholder="Search Products Here" />
                                </div>
                                <input className="btn btn-success" type="button" style={{margin:'0px 25px'}} value="Search" onClick={this.onSearchClicked} />
                                <NavLink to={{pathname:`/product-groups/${this.props.groupId}/add-products`}}><button onClick={this.onAddAttrClicked} style={customStyle.buttonStyle}>+ Add Product</button></NavLink>
                            </div>
                            <div style={{ marginBottom: '40px', background: '#F8F8F8',paddingLeft:35,paddingTop:14,paddingBottom:14,marginTop:25 }}>
                                <span>Filter :  </span>
                                <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'all'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'all')} /> All
                                <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'active'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'active')} /> Active Products
                                <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'archived'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'archived')} /> Archived Products
                                <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'lowStock'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'lowStock')} /> Low in Stock
                                <input style={customStyle.headerRadio} type="checkbox" checked={this.state.checkedRadio === 'noStock'} name="all" onChange={event => this.onCheckedOrUnchecked('checkedRadio', 'noStock')} /> Out of Stock
                            </div>
                            {products && products.length && products.filter(product => this.filterProductsBySelection(product)).length ?
                                <div>
                                <table className="table table-striped">
                                    <thead style={{ background: '#F0F0F0'}}>
                                        <tr>
                                            <th style={customStyle.firstheaderTable}>Product Details</th>
                                            <th style={customStyle.firstheaderTable}>Product ID</th>
                                            <th style={customStyle.headerTable}>Units in Stock</th>
                                            <th style={customStyle.headerTable}>Selling Price</th>
                                            <th style={customStyle.headerTable}></th>
                                        </tr>
                                    </thead>
                                    <tbody>{products.filter(product => this.filterProductsBySelection(product)).map(product => <ProductListTableRowComponent product={product} accountRole={this.props.accountRole} key={product.id} groupId={this.props.groupId} />)}
                                    </tbody>
                                    {/* TODO : Replace Magnifier image with no Products available Image */}
                                </table>
                                <PaginationLinks apiUrl={this.state.windowLocation} totalPages={this.props.totalPages} currentPage={this.props.page} onNavigateToPage={this.onNavigateToPage} />
                                </div>
                            : <div style={{textAlign:'center'}}><p style={{fontWeight:"bold"}}>No Products Available</p></div>}
                        </div> 
                    </div>
                </div>
        )
    }
}

export default connect(({ productGroups, products, stock, auth }, { match }) => ({
    groupId: match.params.groupId,
    products: products.data,
    page:products.page,
    totalPages:products.totalPages,
    stock: stock.data.min_stock,
    accountRole: auth.account_role,
    group: productGroups.data.find(productGroup => productGroup.id === match.params.groupId)
}), { getProductGroupDetail, listProducts, loadStock })(ProductListComponent)