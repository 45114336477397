import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export class TextEditor extends Component {
  render() {
    return (
      <Editor
        toolbar={{
          options: [
              "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            // "image",
            "list",
            "textAlign",
            "remove",
            "history"
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
          },
          // image: {
          //   uploadCallback: this.props.uploadImageCallBack,
          //   alt: { present: true, mandatory: true },
          //   previewImage: true,
          //   defaultSize: {
          //     width: "100%"
          //   }
          // }
        }}
        toolbarStyle={{borderBottom:"2px solid lightgrey"}}
        editorStyle={{minHeight: "200px",paddingLeft:"20px"}}
        wrapperStyle={{border:"3px solid lightgrey"}}
        editorState={this.props.editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.props.onEditorStateChange}
      />
    );
  }
}
