import React, { Component } from "react";
import {
  loadCountries,
  loadDeliveryModesAndPartners,
} from "../../store/actions/vendors";
import { loadCategories } from "../../store/actions/product-groups";
import { listProductGroups } from "../../store/actions/product-groups";
import { listProducts } from "../../store/actions/product";
import { InputControl, SubmitControl, SelectControl, Form } from "../forms";
import { SubmitControll } from "../forms";
import { connect } from "react-redux";
import { register } from "../../store/actions/vendors";
import MultiSelect from "react-select";
import "././common.css";
import { BlockMapBuilder } from "draft-js";
import axios from "axios";
import { BASE_URL } from "../../store/api.middleware";

var divStyle = {
  width: "50%",
  border: "1px solid #A2A2A2",
  borderRadius: 8,
  margin: "auto",
};
var textAreaStyle = {
  resize: "none",
  border: "1px solid #c7d1db",
  boxShadow: "none",
  borderRadius: 0,
  outline: "none",
  fontSize: 14,
  padding: 0,
  fontWeight: 350,
  color: "#595D65",
  WebkitInputPlaceholder: "#595D65",
};
var inputStyle = {
  border: "none",
  boxShadow: "none",
  borderRadius: 0,
  fontSize: 14,
  padding: 0,
  fontWeight: 350,
  color: "#595D65",
  WebkitInputPlaceholder: "#595D65",
};

var multiSelect = {
  marginLeft: "6%",
  borderWidth: 0,
};

var multiSelect1 = {
  marginLeft: "6%",
  borderWidth: 0,
};

var multiSelect2 = {
  marginLeft: "6%",
  borderWidth: 0,
};

var dateSelect = {
  marginLeft: "0%",
  borderWidth: 0,
};
var customStyles = {
  borderWidth: 0,
};

var customStyles = {
  borderWidth: 0,
};

var inputStyle = {
  border: "none",
  boxShadow: "none",
  borderWidth: 0,
  borderRadius: 0,
  fontSize: 14,
  padding: 0,
  fontWeight: 350,
  color: "#595D65",
  WebkitInputPlaceholder: "#595D65",
};

class RegisterComponent extends Component {
  state = {
    returnPeriod: "",
    countries: [],
    address: "",
    business_contact: "",
    city: "",
    postal_code: "",
    area_of_business: "",
    website: "",
    phone_number: "",
    access_token: "",
    api_key: "",
    shopify_storename: "",
    shopify_version: "",
    timepick1: "",
    timepick2: "",
    time_sync: "",
    time_sync_date: "",
    timeshow: false,
    shoifyshow: false,
    gift_category: "",
    // categorydata: [],
    subcategory: [],
    productGroup: "",
    product: [],
    filterFields: false,
    shopifydatas: {
      categoryResult: [],
      productGroupResult: [],
      productResult: [],
      subcategoryResult: [],
    },
    name: "",
    email: (this.props && this.props.email) || "",
    company: "",
    vendor_country: "",
    terms: false,
    emailReadOnly: this.props && this.props.email ? true : false,
    zoneoptions:"",
    parseTimezone:""
  };

  componentDidMount() {
    this.props.loadCountries();
    this.props.loadDeliveryModesAndPartners();
  }

  onDataChanged = (key, value) => {
    this.setState({ [key]: value });
  };
  handleMultiSelect = (option) => {
    this.setState({ vendor_country: option.value });
  };
  handleMultiShipment = (option) => {
    this.setState({ shipment: option.value });
  };

  handleMultiPartner = (option) => {
    this.setState({ deliveryPartner: option.value });
  };

  onNumberFieldChange(key, val) {
    if (Number(val) >= 0) {
      this.setState({ [key]: val });
    }
  }

  shopifyUser = () => {
    this.setState({
      shoifyshow: !this.state.shoifyshow,
    });

    if (this.state.shoifyshow) {
      this.setState({
        api_key: "",
        access_token: "",
        shopify_storename: "",
        shopify_version: "",
      });
    }
  };

  timeuser = () => {
    this.setState({
      timeshow: !this.state.timeshow,
    });
    if (this.state.timeshow) {
      this.setState({ timepick2: "" });
    }
  };

  filtershopify() {
    axios
      .get(`${BASE_URL}/products/filterFields`, {
        params: {
          shopify_storename: this.state.shopify_storename,
          api_key: this.state.api_key,
          access_token: this.state.access_token,
          shopify_version: this.state.shopify_version,
        },
      })
      .then((res) =>
        this.setState({
          shopifydatas: {
            ...this.state.shopifydatas,
            categoryResult: res.data.data.categoryResult,
            subcategoryResult: res.data.data.subcategoryResult,
            productGroupResult: res.data.data.productGroupResult,
            productResult: res.data.data.productResult,
          },
        })
      )
      .catch((err) => console.log(err));
  }

  filterChange = async () => {
    await axios
      .get(
        `${BASE_URL}/vendors/validateapi?api_key=` +
          this.state.api_key +
          "&access_token=" +
          this.state.access_token +
          "&shopify_storename=" +
          this.state.shopify_storename +
          "&shopify_version=" +
          this.state.shopify_version
      )
      .then((rep) => {
        if (rep.data.Success) {
          this.setState((prevState) => ({
            filterFields: !prevState.filterFields,
          }));
          this.filtershopify();
        } else {
          alert("please check your Shopify Crendentails");
        }
      });
  };

  onSubmitted = () => {
    if (!this.state.company) {
      alert("Please fill business name");
      return;
    }

    if (!this.state.address) {
      alert("Please fill business address");
      return;
    }

    if (!this.state.vendor_country) {
      alert("Please select country");
      return;
    }

    if (!this.state.state) {
      alert("Please enter  state");
      return;
    }

    if (!this.state.city) {
      alert("Please enter  city");
      return;
    }

    if (!this.state.postal_code) {
      alert("Please fill  postal code");
      return;
    }

    if (!this.state.business_contact) {
      alert("Please fill  business conatct");
      return;
    }

    if (!this.state.area_of_business) {
      alert("Please fill  business area");
      return;
    }

    let website = this.state.website;
    var regexTest =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        website
      );
    if (!website && !regexTest) {
      alert("Please enter valid website");
      return;
    }

    if (!this.state.name || !this.state.name.trim().length) {
      alert(`Name is required`);
      return;
    }

    let email = this.state.email;
    var regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    if (!email && !regexTest) {
      alert("Please enter valid email");
      return;
    }

    if (!this.state.phone_number) {
      alert(`phone number is required`);
      return;
    }

    if (!this.state.shipment) {
      alert("Please select Delivery Mode");
      return;
    }
    if (
      !this.state.shipment ||
      (this.state.shipment == "door_delivery" && !this.state.deliveryPartner)
    ) {
      alert("Please select Delivery Partner");
      return;
    }

    if (!this.state.returnPeriod) {
      alert(`return days is required `);
      return;
    }

    if (this.state.shoifyshow) {
      if (!this.state.api_key) {
        alert(`api key is required `);
        return;
      }

      if (!this.state.access_token) {
        alert(`access token is required `);
        return;
      }

      if (!this.state.shopify_storename) {
        alert(`store name is required `);
        return;
      }

      if (!this.state.shopify_version) {
        alert(`version is required `);
        return;
      }
    }

    if (!this.state.timeshow) {
      this.setState({ timepick2: "" });
    }

    if (!this.state.terms) {
      alert("Please accept terms and conditions");
      return;
    }

    this.props.register(this.state);
    // }
  };

  time1 = (e) => {
    // Setup the Shopify Sync Time & Date
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${day}-${month}-${year}`;
    this.setState({
      timepick1: e.target.value,
      time_sync: e.target.value,
      time_sync_date: currentDate,
    });
  };

  time2 = (e) => {
    this.setState({ timepick2: e.target.value });
  };

  render() {
    const options = this.props.countries.map((country) => ({
      label: country,
      value: country.toUpperCase(),
    })) || [{ label: "Loading...", value: "" }];

    const deliveryModes = this.props.deliveryModes.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];

    const deliveryPartner = this.props.deliveryPartner.map((doc) => ({
      label: doc.label,
      value: doc.value,
    })) || [{ label: "Loading...", value: "" }];

    const categorymode = this.state.shopifydatas.categoryResult.map((doc) => ({
      label: doc.title,
      value: doc.id,
    })) || [{ label: "Loading...", value: "" }];

    const subcatoryOptions = this.state.shopifydatas.subcategoryResult.map(
      (Subcategory) => ({
        label: Subcategory.title,
        value: Subcategory.id,
      })
    ) || [{ label: "Loading...", value: "" }];

    const ProductGroupOption = this.state.shopifydatas.productGroupResult.map(
      (productGroup) => ({
        label: productGroup.title,
        value: productGroup.id,
      })
    ) || [{ label: "Loading...", value: "" }];

    const ProductOption = this.state.shopifydatas.productResult.map(
      (product) => ({
        label: product.title,
        value: product.id,
      })
    );

    options.shift();
    // options.splice(0,0,{ label: "Please select a country", value: "" });
    const {
      returnPeriod,
      name,
      email,
      company,
      vendor_country,
      phone_number,
      address,
      state,
      city,
      postal_code,
      business_contact,
      area_of_business,
      website,
      access_token,
      api_key,
      shopify_storename,
      shopify_version,
      timepick1,
      timepick2,
      timeshow,
      shoifyshow,
      subcategory,
      gift_category,
      productGroup,
      product,
      filterFields,
      shopifydatas,
    } = this.state;

    return (
      <div style={divStyle} className="register-main">
        <Form>
          <div style={{ textAlign: "center" }}>
            <label
              style={{
                fontSize: 20,
                fontWeight: 400,
                marginTop: 40,
                marginBottom: 40,
                alignSelf: "center",
              }}
              className="register-heading"
            >
              REGISTER YOUR INTEREST WITH US
            </label>
          </div>

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Business Name
          </label>
          <InputControl
            required={true}
            value={company}
            onChange={(company) => this.setState({ company })}
            name=""
            // placeholder="Business Name"
            style={inputStyle}
          />

          <div className="form-group">
            <label
              className="labclsreg"
              style={{
                marginLeft: "25%",
                fontSize: "10px",
                fontWeight: "450px",
                opacity: "0.5",
              }}
            >
              Business Address
            </label>

            <textarea
              required={true}
              rows={5}
              style={textAreaStyle}
              value={address}
              // placeholder="Business Address"
              className="col-sm-6 col-sm-offset-3"
              onChange={(event) =>
                this.setState({ address: event.target.value })
              }
            ></textarea>
          </div>
          <div className="form-group" style={multiSelect}>
            <label
              className="labclsreg"
              style={{
                marginLeft: "19%",
                fontSize: "10px",
                fontWeight: "450px",
                opacity: "0.5",
              }}
            >
              Please Select Business Country
            </label>

            <MultiSelect
              // placeholder="Please Select Business Country"
              options={options}
              isMulti={false}
              isSearchable={true}
              onChange={this.handleMultiSelect}
              className="countrycls col-sm-7 col-sm-offset-2"
              style={customStyles}
              required={true}
            />
          </div>

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            State
          </label>
          <InputControl
            required={true}
            style={inputStyle}
            value={state}
            // placeholder="State"
            onChange={(state) => this.setState({ state })}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            City
          </label>
          <InputControl
            required={true}
            style={inputStyle}
            value={city}
            // placeholder="City"
            onChange={(city) => this.setState({ city })}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Postcode/Zipcode
          </label>
          <InputControl
            required={true}
            style={inputStyle}
            value={postal_code}
            // placeholder="Postcode/Zipcode"
            onChange={(postal_code) => this.setState({ postal_code })}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Business Contact Number with ISD
          </label>
          <InputControl
            required={true}
            style={inputStyle}
            value={business_contact}
            // placeholder="Business Contact Number with ISD"
            onChange={(business_contact) => this.setState({ business_contact })}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Area of Business
          </label>
          <InputControl
            required={true}
            style={inputStyle}
            value={area_of_business}
            // placeholder="Area of Business"
            onChange={(area_of_business) => this.setState({ area_of_business })}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Website
          </label>
          <InputControl
            style={inputStyle}
            value={website}
            // placeholder="Website(If you are ShopifyUser, Website should be Store Name)"
            onChange={(website) => this.setState({ website })}
            required={true}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Your Full Name
          </label>
          <InputControl
            required={true}
            // placeholder="Your Full Name"
            value={name}
            onChange={(name) => this.setState({ name })}
            style={inputStyle}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Your Email
          </label>
          <InputControl
            required={true}
            readOnly={this.state.emailReadOnly}
            // placeholder="Your Email"
            value={email}
            onChange={(email) => this.setState({ email })}
            style={inputStyle}
          />

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Your Contact Number with ISD
          </label>
          <InputControl
            required={true}
            value={phone_number}
            // placeholder="Your Contact Number with ISD"
            onChange={(phone_number) => this.setState({ phone_number })}
          />

          <div className="shopify_options">
            <label
              style={{
                // marginLeft: 146,
                fontSize: 15,
                fontWeight: 450,
                opacity: 0.5,
              }}
              className="shopify_yon"
              id="shop_lab"
            >
              Are you Shopify User ?{" "}
            </label>

            <button
              type="button"
              className="shopifycls"
              onClick={this.shopifyUser}
              id="btnshopifyuser"
            >
              {shoifyshow ? "No" : "Yes"}
            </button>
          </div>

          {shoifyshow && (
            <div>
              <label
                className="labclsreg"
                style={{
                  marginLeft: "24%",
                  fontSize: "10px",
                  fontWeight: "450px",
                  opacity: "0.5",
                }}
              >
                Api Key
              </label>
              <InputControl
                required={false}
                style={inputStyle}
                value={api_key}
                // placeholder="Api Key"
                onChange={(api_key) => this.setState({ api_key })}
                id="shop_api_key"
              />

              <label
                className="labclsreg"
                style={{
                  marginLeft: "24%",
                  fontSize: "10px",
                  fontWeight: "450px",
                  opacity: "0.5",
                }}
              >
                Access Token
              </label>
              <InputControl
                required={false}
                style={inputStyle}
                value={access_token}
                // placeholder="Access Token"
                onChange={(access_token) => this.setState({ access_token })}
                id="shop_access_token"
              />

              <label
                className="labclsreg"
                style={{
                  marginLeft: "24%",
                  fontSize: "10px",
                  fontWeight: "450px",
                  opacity: "0.5",
                }}
              >
                Shopify Store Name (Ex: *.myshopify.com)
              </label>
              <InputControl
                required={false}
                style={inputStyle}
                value={shopify_storename}
                // placeholder="Shopify Store Name"
                onChange={(shopify_storename) =>
                  this.setState({ shopify_storename })
                }
                id="shop_shopify_storename"
              />

              <label
                className="labclsreg"
                style={{
                  marginLeft: "24%",
                  fontSize: "10px",
                  fontWeight: "450px",
                  opacity: "0.5",
                }}
              >
                Shopify Webhook Versions
              </label>
              <InputControl
                required={false}
                style={inputStyle}
                value={shopify_version}
                // placeholder="Shopify Webhook Versions"
                onChange={(shopify_version) =>
                  this.setState({ shopify_version })
                }
                id="shop_shopify_version"
              />

              <label
                className="labclsreg"
                style={{
                  marginLeft: "24%",
                  fontSize: "10px",
                  fontWeight: "450px",
                  opacity: "0.5",
                  marginBottom: "4%",
                }}
              >
                Choose Time, Please ensure it's in UTC Time
              </label>
              <div className="chkbox-container timepick-contaioner">
                {/* <label style={{ width: "22%" }} className="lbl-mobile"></label> */}
                <input
                  // label="Choose Time"
                  id="time1"
                  type="time"
                  onChange={(e) => this.time1(e)}
                  value={timepick1}
                  className="tim1_cls"
                />
                <label
                  style={{
                    marginLeft: 14,
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  className="lab2_cls"
                >
                  <button
                    type="button"
                    onClick={this.timeuser}
                    id="cron_tim_btn1"
                  >
                    {timeshow ? "-" : "+"}
                  </button>
                </label>
              </div>
              {timeshow && (
                <div className="chkbox-container  timepick-contaioner1">
                  <label
                    style={{ width: "22%" }}
                    className="lbl-mobile"
                  ></label>
                  <input
                    id="time2"
                    type="time"
                    onChange={(e) => this.time2(e)}
                    className="tim1_cls"
                    value={timepick2}
                    style={{ marginBottom: "15px" }}
                  />
                  <label
                    style={{
                      marginLeft: 14,
                      fontSize: 14,
                      fontWeight: 400,
                      opacity: 0.8,
                    }}
                    className="lbl_cls_tim2"
                  ></label>
                </div>
              )}
              <div>
                <SubmitControll
                  title="Verify Shopify Connection"
                  style={{ marginBottom: 32 }}
                  classNames={["submitStyle", "btn"]}
                  onClick={() => {
                    this.filterChange();
                  }}
                  id="shop_validate_btn"
                />
              </div>
              {filterFields === true ? (
                <div>
                  {/* <div className="form-group" style={multiSelect1}>
                    <label className="catsubcat">
                      Category(ies) to Exclude (if needed)
                    </label> */}
                    {/* <label
                     style={{
                     marginLeft: 146,
                     fontSize: 15,
                     fontWeight: 450,
                     opacity: 0.5,
                  }}              
            > */}

                    {/* </label> */}
                    {/* <span className="heading2">Category(ies) to Exclude (if needed) </span> */}
                    {/* <MultiSelect
                      label="categories"
                      value={gift_category}
                      // placeholder="Please Select Category(ies)"
                      options={categorymode}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(gift_category) =>
                        this.setState({ gift_category })
                      }
                      className="col-sm-7 col-sm-offset-2"
                      style={customStyles}
                      closeMenuOnSelect={false}
                      id="gift_category_drop"
                    />
                  </div> */}

                  <div className="form-group" style={multiSelect2}>
                    <label className="catsubcat">
                      Collection(s) to Exclude (if needed)
                    </label>
                    {/* <label
                     style={{
                     marginLeft: 146,
                     fontSize: 15,
                     fontWeight: 450,
                     opacity: 0.5,
                     }}              
                  >
             
            </label> */}
                    <MultiSelect
                      value={subcategory}
                      // placeholder="Please Select Sub Category(ies)"
                      options={subcatoryOptions}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(subcategory) => this.setState({ subcategory })}
                      className="col-sm-7 col-sm-offset-2"
                      style={customStyles}
                      closeMenuOnSelect={false}
                      id="subcategory_drop"
                    />
                  </div>

                  <div className="form-group" style={multiSelect1}>
                    <label className="catsubcat">
                      Product Group(s) to Exclude (if needed)
                    </label>
                    {/* <label
                     style={{
                     marginLeft: 146,
                     fontSize: 15,
                     fontWeight: 450,
                     opacity: 0.5,
                    }}              
                  >
              Product Group(s) to Exclude (if needed)
            </label> */}
                    <MultiSelect
                      value={productGroup}
                      // placeholder="Please Select Product Group(s)"
                      options={ProductGroupOption}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(productGroup) =>
                        this.setState({ productGroup })
                      }
                      className="col-sm-7 col-sm-offset-2"
                      style={customStyles}
                      closeMenuOnSelect={false}
                      id="productGroup_drop"
                    />
                  </div>

                  <div className="form-group" style={multiSelect1}>
                    <label className="catsubcat">
                      Product(s) to Exclude (if needed)
                    </label>
                    {/* <label
                     style={{
                     marginLeft: 146,
                     fontSize: 15,
                     fontWeight: 450,
                     opacity: 0.5,
                      }}              
                    >
              Product(s) to Exclude (if needed)
            </label> */}
                    <MultiSelect
                      value={product}
                      // placeholder="Please Select Product(s)"
                      options={ProductOption}
                      isMulti={true}
                      isSearchable={true}
                      onChange={(product) => this.setState({ product })}
                      className="col-sm-7 col-sm-offset-2"
                      style={customStyles}
                      closeMenuOnSelect={false}
                      id="product_drop"
                    />
                  </div>
                </div>
              ) : null}

              <div></div>
            </div>
          )}

          <div className="form-group" style={multiSelect}>
            <label
              className="labclsreg"
              style={{
                marginLeft: "19%",
                fontSize: "10px",
                fontWeight: "450px",
                opacity: "0.5",
              }}
            >
              Please Select Delivery Mode
            </label>
            <MultiSelect
              // placeholder="Please Select Delivery Mode"
              options={deliveryModes}
              isMulti={false}
              isSearchable={true}
              onChange={this.handleMultiShipment}
              className="countrycls col-sm-7 col-sm-offset-2"
              style={customStyles}
            />
          </div>
          {this.state.shipment && this.state.shipment == "door_delivery" && (
            <div className="form-group" style={multiSelect}>
              <MultiSelect
                isDisabled={
                  this.state.shipment && this.state.shipment != "door_delivery"
                }
                placeholder="Please Select Delivery Partner"
                options={deliveryPartner}
                isMulti={false}
                isSearchable={true}
                onChange={this.handleMultiPartner}
                className="col-sm-7 col-sm-offset-2"
                style={customStyles}
              />
            </div>
          )}
          {this.state.deliveryPartner == "gifteffects_delivery" && (
            <div className="chkbox-container">
              <label style={{ width: "24%" }} className="lbl-mobile"></label>
              <label
                style={{
                  marginLeft: 12,
                  fontSize: 12,
                  fontWeight: 350,
                  opacity: 0.5,
                }}
              >
                <a
                  href="https://uat.gifteffects.com/admin/cms/banned"
                  target="__blank"
                >
                  View GiftEffects Banned Items For Delivery
                </a>
              </label>
            </div>
          )}

          <label
            className="labclsreg"
            style={{
              marginLeft: "24%",
              fontSize: "10px",
              fontWeight: "450px",
              opacity: "0.5",
            }}
          >
            Max days for return
          </label>
          <InputControl
            required={true}
            value={returnPeriod}
            // placeholder="Max days for return"
            onChange={(returnPeriod) =>
              this.onNumberFieldChange("returnPeriod", returnPeriod)
            }
            style={inputStyle}
          />
          <div className="chkbox-container">
            <label style={{ width: "24%" }} className="lbl-mobile"></label>
            <input
              type="checkbox"
              required={true}
              name="terms"
              checked={this.state.terms}
              onChange={(event) =>
                this.onDataChanged("terms", event.target.checked)
              }
              className="chkbox"
            />
            <label
              style={{
                marginLeft: 14,
                fontSize: 12,
                fontWeight: 350,
                opacity: 0.5,
              }}
            >
              I agree to the{" "}
              <a
                href="https://uat.gifteffects.com/admin/cms/vendor-terms-and-conditions"
                target="__blank"
              >
                terms and conditions
              </a>{" "}
              of Gift Effects
            </label>
          </div>
          <div>
            <label style={{ width: "24%" }} className="lbl-mobile"></label>
            <label
              style={{
                width: "53%",
                marginTop: 40,
                marginBottom: 40,
                fontWeight: 350,
                fontSize: 12,
                opacity: 0.5,
              }}
              className="verify"
            >
              Please click on the verification mail sent to your email to
              confirm registration
            </label>
          </div>
          <SubmitControl
            title="REGISTER"
            style={{ marginBottom: 32 }}
            classNames={["submitStyle", "btn"]}
            onClick={this.onSubmitted}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = ({ delivery, countries, auth }) => ({
  deliveryPartner: delivery.deliveryPartner || [],
  deliveryModes: delivery.deliveryModes || [],
  countries: countries.data || [],
  email: auth.email,
});
export default connect(mapStateToProps, {
  register,
  loadCountries,
  loadDeliveryModesAndPartners,
})(RegisterComponent);
