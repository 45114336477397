import React, { Component } from "react";
import { InputControl } from "../../forms";

var inputStyle = {
    border : "none",
    borderBottom:"1px solid #C7D1DB",
    boxShadow:"none",
    borderRadius:0,
    fontSize:14,
    padding:0,
    fontWeight:350,
    color:"#595D65",
    outline:"none",
    width:"83%"
  };
export default class AddAttributeComponent extends Component {
    constructor(props){
        super(props)
        this.onAttrRemoved = this.onAttrRemoved.bind(this)
        this.onDataChanged = this.onDataChanged.bind(this)
    }
    state = {showPopup: false, popupType:``, value: this.props.value}
    onDataChanged(){
        const stateVal = (this.props.allAttrs || []).filter(val => val === this.state.value)
        if(stateVal.length > 1){
            alert(`Duplicate attributes found`)
            return
        } else {
            this.props.onEdit(this.state.value)
            this.setState({showPopup: false, popupType:``})
        }
    }
    onAttrRemoved(){
        this.props.onRemove(this.props.attrIndex)
        this.setState({ showPopup: false, popupType: `` })
    }
    popup = (props) => {
        return (
            <div className='popup'>
                <div style={{ textAlign: 'center' }} className='popup_inner'>
                    <span onClick={e => this.setState({ showPopup: false })} style={{ cursor: 'pointer', float: 'right', paddingRight: '10px' }}>x</span>
                    {props.content}
                </div>
            </div>
        )
    }
    onDecline = () => {
        this.setState({showPopup: false, value: this.props.value})
    }
    render(){
        return (
            <div>
                <div className="form-group" style={{display:"flex", flexDirection:'row'}}>
                    <label className="col-sm-4" style={{padding: 0}}></label>
                    <input required 
                        className="col-sm-8" value={this.state.value} 
                        // onChange={event => this.onDataChanged(event.target.value)}
                        onChange={e => this.setState({value:e.target.value})} 
                        style={inputStyle}/>
                        {
                            this.props.value ? 
                        <span onClick={event => this.setState({ showPopup: true, popupType: `EDIT` })} style={{cursor:'pointer', marginLeft:'10px'}}>
                            <img src={require('../../../images/edit.png')} />
                        </span> :
                            <span onClick={this.onDataChanged}>
                                <img style={{height:"10px", cursor:"pointer"}} src={require('../../../images/tick.png')} />                            
                            </span>
                        }
                        <span onClick={event => this.setState({showPopup: true, popupType:`DELETE`})} 
                        style={{ cursor: "pointer", marginLeft: '10px', display:this.props.shouldShowClose ? 'block': 'none'}
                            }>×
                        </span>
                </div>
                {this.state.showPopup ? this.popup({...{
                    content:( this.state.popupType === `DELETE` ? 
                        (<div>
                            <p style={{ marginTop: '40px' }}><b>Are you sure you want to remove this ?</b></p>
                            <div style={{ 
                                marginTop: '65px', display:"flex", flexDirection:"row",
                                paddingLeft: "10%",
                                paddingRight:"10%",
                                justifyContent:"space-between"
                            }}>
                                <button style={{ marginTop: '15px', width:"45%" }} onClick={this.onAttrRemoved} className='btn btn-success'>YES</button>
                                <button style={{ marginTop: '15px', width: "45%" }} onClick={this.onDecline} className='btn btn-warning'>CANCEL</button>
                            </div>
                        </div>) : (
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <p style={{ marginTop: '40px' }}><b>Edit attribute</b></p>
                                <input style={{marginLeft:'10px', marginTop:'15px'}} className="form-control" onChange={e => this.setState({value: e.target.value})} type="text" value={this.state.value} />
                                <div style={{
                                    marginTop: '40px', display: "flex", flexDirection: "row",
                                    paddingLeft: "15%",
                                    paddingRight: "10%",
                                    justifyContent: "space-between"
                                }}>
                                    <button disabled={!this.state.value || this.props.allAttrs.includes(this.state.value)} style={{ marginTop: '15px', width: "45%" }} onClick={this.onDataChanged} className='btn btn-success'>UPDATE</button>
                                    <button style={{ marginTop: '15px', width: "45%" }} onClick={this.onDecline} className='btn btn-warning'>CANCEL</button>
                                </div>
                            </div>
                        )
                    )
                }}) : ``}
            </div>
        )   
    }   
}
