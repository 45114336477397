import { CALL_API } from "../api.middleware";
import { LOADING_ORDER_DETAIL_PROGRESS, LOADING_ORDERS_SUCCESS, LOADING_ORDERS_FAILED, LOADING_ORDER_DETAIL_SUCCESS, 
    LOADING_ORDER_DETAIL_FAILED, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILED, LOADING_ORDERS_PROGRESS, 
    DOWNLOADING_ORDERS_PROGRESS, DOWNLOADING_ORDERS_SUCCESS, DOWNLOADING_ORDERS_FAILED,
    GET_SUPPORT_NUMBER_PROGRESS, GET_SUPPORT_NUMBER_SUCCESS, GET_SUPPORT_NUMBER_FAILED 
} from "../types/orders";
import { UPDATE_PRODUCT_PROGRESS } from "../types/products";
import { BASE_URL } from "../api.middleware";

export const loadOrders = (filterBy, searchBy, page) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/orders?status=${filterBy}&page=${page}&searchBy=${searchBy}`,
            types:[LOADING_ORDER_DETAIL_PROGRESS, LOADING_ORDERS_SUCCESS, LOADING_ORDERS_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const getOrderDetail = (orderId) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/orders/${orderId}/detail`,
            types:[LOADING_ORDER_DETAIL_PROGRESS, LOADING_ORDER_DETAIL_SUCCESS, LOADING_ORDER_DETAIL_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const editOrder = (orderId, payload, getDetail = false) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/orders/${orderId}/edit?getDetail=${getDetail}`,
            types:[UPDATE_PRODUCT_PROGRESS, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILED],
            body:payload,
            showMessage:`Order updated Successfully`,
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const filterOrders = (queryBody, filterBy, searchBy, page) => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url:`/vendors/filter_orders?status=${filterBy}&searchBy=${searchBy}&page=${page}`,
            body:queryBody,
            types:[LOADING_ORDERS_PROGRESS, LOADING_ORDERS_SUCCESS, LOADING_ORDERS_FAILED],
            showLoading:true
        }})
    } catch (error) {
        alert(error.message)
    }
}

export const downloadOrders = (queryBody, filterBy, searchBy, page, token, vendorId) => async dispatch => {
    try {
        const response = await fetch(`${BASE_URL}/vendors/download_orders?access_token=${token}&status=${filterBy}&searchBy=${searchBy}&page=${page}&vendorId=${vendorId}`, {
            method:'POST', 
            body:queryBody
        })
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Orders_${new Date().getTime()}.csv`;
        document.body.appendChild(a);
        a.click();    
        a.remove();
    } catch (error) {
        alert(error.message)
    }
}

export const getSupportNumber = () => async dispatch => {
    try {
        await dispatch({[CALL_API]: {
            url: `/get-support-number`,
            types:[
                GET_SUPPORT_NUMBER_PROGRESS, 
                GET_SUPPORT_NUMBER_SUCCESS,
                GET_SUPPORT_NUMBER_FAILED
            ]
        }})
    } catch (error) {
        alert(error.message)
    }
}