import React, { Component } from "react";
import { connect } from "react-redux";
import { getVendorPayments, getPendingPayments, downloadPayments } from "../../../store/actions/payments";
import PaginationLinks from "../../pagination";
import 'react-dates/initialize';
import '../orders/_datepicker.css'
import { DateRangePicker } from "react-dates";
import moment from "moment";
import { formatDate } from "../../../utils";

class PaymentsListingComponent extends Component {
    constructor(props) {
        super(props)
        this.onNavigateToPage = this.onNavigateToPage.bind(this)
    }
    state = {startDate:null, endDate:null, checkedInput:'paid'}
    componentDidMount() {
        this.props.getVendorPayments()
    }
    onNavigateToPage(page) {
        if(this.state.checkedInput === `paid`){
            this.props.getVendorPayments(this.state.startDate, this.state.endDate, page)
        } else {
            this.props.getPendingPayments(this.state.startDate, this.state.endDate, page)            
        }

    }
    onFilterClicked = () => {
        if(this.state.checkedInput === `paid`){
            this.props.getVendorPayments(this.state.startDate, this.state.endDate, this.props.page)
        } else {
            this.props.getPendingPayments(this.state.startDate, this.state.endDate, 1)            
        }
    }
    onDownloadClicked = () => {
        this.props.downloadPayments(this.props.token, this.props.vendorId, this.state.startDate, this.state.endDate, this.state.checkedInput)
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.checkedInput !== this.state.checkedInput){
            this.props.getVendorPayments(this.state.startDate, this.state.endDate, this.props.page)
            if(this.state.checkedInput === `paid`){
            } else {
                this.props.getPendingPayments(this.state.startDate, this.state.endDate, this.props.page)
            }
        } 
    }
    render() {
        return (this.props.payments && this.props.payments.length ? <div>
            <div>
                <input style={{marginLeft:`10px`}} checked={this.state.checkedInput === `paid`} type="radio" name='r' onChange={e => this.setState({checkedInput:`paid`})} /> Received Payments
                <input style={{marginLeft:`5px`}} checked={this.state.checkedInput === `pending`} type="radio" name='r' onChange={e => this.setState({checkedInput:`pending`})} /> Pending Payments
                <span style={{marginRight:`15px`}}></span>
                <DateRangePicker startDate={this.state.startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={this.state.endDate}
                    endDateId="your_unique_end_date_id"
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    showClearDates={true}
                    reopenPickerOnClearDates={true}
                    isOutsideRange={day => (moment().diff(day) <= 0)}
                    showDefaultInputIcon={true}
                    minimumNights={0}
                    small={true}
                />
                <button style={{ marginLeft: '19px', width:'100px' }} onClick={this.onFilterClicked} className='btn btn-success'>Filter</button>
                <button style={{ marginLeft: '19px', width:'100px' }} onClick={this.onDownloadClicked} className='btn btn-success'>Download</button>
            </div>
            {this.state.checkedInput === `paid` &&
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>Payment Date</th>
                            <th>Orders IDs</th>
                            <th>Orders Total</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.payments.map((payment, index) => <tr key={index}>
                            <td>{formatDate(payment.createdAt)}</td>
                            <td>{payment.orders.map(order => order.order_short_id).join(',')}</td>
                            <td>{payment.orders.reduce((prev, order) => prev + parseFloat(order.product_cost), 0)}</td>
                            <td>{payment.remarks || `Not Speficied`}</td>
                        </tr>)}
                    </tbody>
                </table>
            }
            {
                this.state.checkedInput === `pending` && 
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th>Customer Order Date</th>
                        <th>Orders ID</th>
                        <th>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.props.pendingPayments.map((order, index) =>
                            <tr key={index}>
                                <td>{order.createdAt}</td>
                                <td>{order.order_short_id}</td>
                                <td>{order.product_cost}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            }
            <PaginationLinks apiUrl={window.location.pathname} currentPage={this.props.page} totalPages={this.props.totalPages} onNavigateToPage={this.onNavigateToPage} />
        </div>: <p style={{textAlign:'center'}}><b>You haven't received any payments from GiftEffects</b></p>)
    }
}

const mapStateToProps = ({ payments, auth }) => ({ vendorId:auth.currentVendor.id, token:auth.token, payments: payments.data, pendingPayments: payments.pendingPayments, page: payments.page, totalPages: payments.totalPages })
export default connect(mapStateToProps, { getVendorPayments, getPendingPayments, downloadPayments })(PaymentsListingComponent)